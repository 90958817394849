import { formatISO } from 'date-fns'
import produce from 'immer'

import { createChatRoomId } from '../../helpers/createId'
import { ReactionIndex, User } from '../../types'

export const addToReactionIndex = (
	reactionIndex: ReactionIndex,
	emoji: string,
	userId: string
) =>
	produce(reactionIndex || {}, (draft) => {
		draft[emoji] = draft[emoji] || []
		const existingReaction = draft[emoji].find((r) => r.userId === userId)
		if (!existingReaction) {
			draft[emoji].push({ userId, dateCreated: formatISO(new Date()) })
		}
	})

export const createChatRoom = (
	player: Pick<User, 'id' | 'organisationId'>,
	name: string,
	userIds: string[]
) => {
	if (!player || !player.id || !player.organisationId) {
		throw new Error(
			'createChatRoom: player must be provided with id and organisationId'
		)
	}

	if (!name) {
		throw new Error('createChatRoom: a room name must be provided')
	}
	if (!userIds && Array.isArray(userIds)) {
		throw new Error('createChatRoom: userIds must be an array')
	}

	const filteredUserIds = userIds.filter((id) => id !== player.id)
	if (filteredUserIds.length === 0) {
		throw new Error(
			'createChatRoom: userIds must contain at least one id that is not the player id'
		)
	}

	const isGroup = filteredUserIds.length > 1

	const roomId = isGroup
		? createChatRoomId()
		: createChatRoomId(player.id, userIds[0])
	const roomType: 'group' | 'user' = isGroup ? 'group' : 'user'

	return {
		id: roomId,
		dateCreated: formatISO(new Date()),
		name,
		organisationId: player.organisationId,
		roomType,
		users: [player.id, ...filteredUserIds],
	}
}

export const removeFromReactionIndex = (
	reactionIndex: ReactionIndex,
	emoji: string,
	userId: string
) => {
	const result = produce(reactionIndex || {}, (draft) => {
		if (!Array.isArray(draft[emoji])) {
			return
		}
		const index = draft[emoji].findIndex((r) => r.userId === userId)
		draft[emoji].splice(index, 1)
		if (draft[emoji].length === 0) {
			delete draft[emoji]
		}
	})

	if (Object.keys(result).length === 0) {
		return undefined
	} else {
		return result
	}
}
