/*
 * Follower factory functions for mutating state
 */

import { QueryClient } from 'react-query'
import { identity } from 'remeda'

import { Task, TeardownFn } from '../../../types'
import { ApiAdapter } from '../../api/baseApiAdapter'
import { taskKeys } from '../../queries'
import { AppState } from '../../store-types'
import { createUpdateTaskOnQueryCache } from '../tasks/update'

export const removeFollowerMutation = async (
	state: AppState,
	taskId: string,
	followerId: string
) => {
	const { apiAdapter, queryClient } = state
	createRemoveFollowerFromApi(apiAdapter)(taskId, followerId)
	createRemoveFollowerFromQueryCache(queryClient)(taskId, followerId)
}

export const createRemoveFollowerFromApi =
	(apiAdapter: ApiAdapter) => async (taskId: string, followerId: string) => {
		apiAdapter.tasks.removeFollower(taskId, followerId)
	}

export const createRemoveFollowerFromQueryCache =
	(queryClient: QueryClient) =>
	async (taskId: string, followerId: string) => {
		const task = queryClient.getQueryData<Task>(taskKeys.detail(taskId))
		if (!task) {
			return identity as TeardownFn
		}

		const oldTask = queryClient.getQueryData<Task>(taskKeys.detail(taskId))
		const parentTask = oldTask?.parentId
			? queryClient.getQueryData<Task>(taskKeys.detail(oldTask.parentId))
			: null

		let newFollowers = task.followers
		let isVirtual = false
		if (parentTask && parentTask.followers) {
			isVirtual = parentTask.followers.some(
				(follower) => follower.id === followerId
			)
		}

		if (isVirtual) {
			newFollowers = newFollowers.map((follower) => ({
				...follower,
				isVirtual: true,
			}))
		} else {
			newFollowers = newFollowers.filter(
				(follower) => follower.id !== followerId
			)
		}

		const removeFollowerPatch = {
			id: taskId,
			followers: newFollowers,
		}
		const updateTask = createUpdateTaskOnQueryCache(queryClient)
		return updateTask(taskId, removeFollowerPatch)
	}
