import { produce } from 'immer'
import { QueryClient } from 'react-query'

import {
	ApiTaskActivity,
	overwriteActivity,
	parseTaskLogs,
	shouldOverwriteLastActivity,
	TaskActivity,
	WrappedTaskActivityV2,
} from '../../task-activity'
import { parseErrorMessage } from '../../task-activity/parsers/task-activity-parsers-utils'
import { buildMetaForTaskActivity } from '../../task-activity/task-activity-utils'
import { TeardownFn } from '../../types'
import { ApiAdapter, ApiListResult, ApiResult } from '../api'
import { getDataSourceTaskActivityMeta } from './task-activity-data-source'
import { taskActivityKeys } from './taskActivityKeys'

type AddReactionFn<T> = (
	reaction: { emoji: string; userId: string },
	activity: TaskActivity
) => T
type RemoveReactionFn<T> = (
	reaction: { emoji: string; userId: string },
	activity: TaskActivity
) => T

export const createAddTaskActivityMutation =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) =>
	async (
		wrappedActivity: WrappedTaskActivityV2
	): Promise<ApiResult<ApiTaskActivity>> => {
		const addTaskActivityToApi = createAddTaskActivityToApi(apiAdapter)
		createAddTaskActivityToQueryCache(
			apiAdapter,
			queryClient
		)(wrappedActivity)
		return addTaskActivityToApi(wrappedActivity)
	}

export const createAddTaskActivityToApi =
	(apiAdapter: ApiAdapter) =>
	async (
		newActivity: WrappedTaskActivityV2
	): Promise<ApiResult<ApiTaskActivity>> =>
		apiAdapter.tasks.addActivity(newActivity)

export const createAddTaskActivityToQueryCache =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) =>
	async (wrappedActivity: WrappedTaskActivityV2): Promise<TeardownFn> => {
		// Update all lists
		const queryKey = taskActivityKeys.lists(wrappedActivity.taskId)

		await queryClient.cancelQueries(queryKey)

		const activity = wrappedActivity.data
		const dataSource = await getDataSourceTaskActivityMeta(
			{ apiAdapter, queryClient },
			[activity]
		)
		const patchedActivity: TaskActivity = {
			...activity,
			meta: buildMetaForTaskActivity(activity, dataSource),
		}

		const activityWithMeta: WrappedTaskActivityV2 = {
			...wrappedActivity,
			descr: parseTaskLogs(patchedActivity) || parseErrorMessage,
			data: patchedActivity,
		}

		const createBlankPage = () => ({
			count: 0,
			items: [],
			hasMore: false,
			page: 1,
			pageSize: 30,
		})

		const updater = produce((draft) => {
			if (!draft) {
				draft = {}
			}
			if (!draft.pages) {
				draft.pages = [createBlankPage()]
			}
			if (
				!draft.pages[0].items.find(
					(item: WrappedTaskActivityV2) =>
						item.id === activityWithMeta.id
				)
			) {
				const lastTaskActivity = draft.pages[0].items[0]
				if (
					shouldOverwriteLastActivity(
						lastTaskActivity,
						activityWithMeta
					)
				) {
					draft.pages[0].items[0] = overwriteActivity(
						lastTaskActivity,
						activityWithMeta
					)
				} else {
					draft.pages[0].items.unshift(activityWithMeta)
				}
				draft.pages[0].count = draft.pages[0].items.length
			}

			// If the draft starts off as undefined, we need to return it to create the new object.
			return draft
		})

		queryClient.setQueryData(
			taskActivityKeys.list(activityWithMeta.taskId, {
				type: 'all',
				pageSize: 10,
			}),
			updater
		)

		queryClient.setQueryData(
			taskActivityKeys.list(activityWithMeta.taskId, {
				type: activityWithMeta.data.type,
			}),
			updater
		)

		return () => {
			queryClient.invalidateQueries(queryKey)
		}
	}

export const createAddReactionMutation =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) =>
	async (
		reaction: { emoji: string; userId: string },
		activity: TaskActivity
	): Promise<ApiResult<TaskActivity>> => {
		const addReactionToApi = createAddReactionToApi(apiAdapter)
		createAddReactionToQueryCache(queryClient)(reaction, activity)
		return addReactionToApi(reaction, activity)
	}

export const createAddReactionToQueryCache =
	(queryClient: QueryClient) =>
	async (
		reaction: { emoji: string; userId: string },
		activity: TaskActivity
	): Promise<TeardownFn> => {
		const queryKey = taskActivityKeys.lists(activity.taskId)

		queryClient.setQueriesData(
			queryKey,
			produce((draft) => {
				if (!draft || !draft.pages) {
					return
				}
				const activityList: TaskActivity[] = draft.pages.flatMap(
					(page: ApiListResult<TaskActivity>) => page.items
				)
				const targetActivity = activityList.find(
					(a) => a.id === activity.id
				)

				if (targetActivity && reaction.userId) {
					if (!targetActivity.reactions) {
						targetActivity.reactions = {}
					}

					if (!targetActivity.reactions[reaction.emoji]) {
						targetActivity.reactions[reaction.emoji] = {}
					}

					targetActivity.reactions[reaction.emoji][reaction.userId] =
						true
				}
			})
		)

		return () => {
			queryClient.invalidateQueries(queryKey)
		}
	}

export const createAddReactionToApi =
	(apiAdapter: ApiAdapter): AddReactionFn<Promise<ApiResult<TaskActivity>>> =>
	async (reaction, activity) =>
		apiAdapter.tasks.addActivityReaction(
			activity.taskId,
			activity.id,
			reaction.emoji
		)

export const createRemoveReactionMutation =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) =>
	async (
		reaction: { emoji: string; userId: string },
		activity: TaskActivity
	): Promise<ApiResult<TaskActivity>> => {
		const removeReactionFromApi = createRemoveReactionFromApi(apiAdapter)
		createRemoveReactionFromQueryCache(queryClient)(reaction, activity)
		return removeReactionFromApi(reaction, activity)
	}

export const createRemoveReactionFromQueryCache =
	(queryClient: QueryClient) =>
	async (
		reaction: { emoji: string; userId: string },
		activity: TaskActivity
	): Promise<TeardownFn> => {
		const queryKey = taskActivityKeys.lists(activity.taskId)

		queryClient.setQueriesData(
			queryKey,
			produce((draft) => {
				if (!draft || !draft.pages) {
					return
				}
				const activityList: TaskActivity[] = draft.pages.flatMap(
					(page: ApiListResult<TaskActivity>) => page.items
				)
				const targetActivity = activityList.find(
					(a) => a.id === activity.id
				)

				if (
					targetActivity &&
					targetActivity.reactions &&
					reaction.userId
				) {
					const emojiRef = targetActivity.reactions[reaction.emoji]
					delete emojiRef[reaction.userId]

					// If empty object is left over, then remove it
					if (Object.keys(emojiRef).length === 0) {
						delete targetActivity.reactions[reaction.emoji]
					}
				}
			})
		)

		return () => {
			queryClient.invalidateQueries(queryKey)
		}
	}

export const createRemoveReactionFromApi =
	(
		apiAdapter: ApiAdapter
	): RemoveReactionFn<Promise<ApiResult<TaskActivity>>> =>
	async (reaction, activity) =>
		apiAdapter.tasks.removeActivityReaction(
			activity.taskId,
			activity.id,
			reaction.emoji
		)
