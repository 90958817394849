import { CustomTheme } from '.'
import { createBaseTheme } from './default'
import { defaultPalette } from './palettes'

export const midnightTheme: CustomTheme = {
	name: 'midnight',
	palette: {
		...defaultPalette,
		mode: 'dark',
		background: '#060e01',
		onBackground: '#fff',
		surface: '#13181b',
		onSurface: '#fff',
		sidebar: '#13181b',
		onSidebar: '#fff',
	},
}

const createMuiTheme = () => {
	const baseMuiTheme = createBaseTheme({ palette: { mode: 'dark' } })

	return createBaseTheme({
		name: 'midnight',
		palette: {
			mode: 'dark',
			background: {
				default: midnightTheme.palette.background,
				paper: midnightTheme.palette.surface,
				secondary: baseMuiTheme.palette.grey[700],
				sidebar: midnightTheme.palette.sidebar,
			},
		},
		components: {
			MuiAppBar: {
				styleOverrides: {
					colorDefault: { backgroundColor: '#13181b' },
				},
			},
			MuiTabs: {
				defaultProps: {
					indicatorColor: 'primary',
					textColor: 'primary',
				},
			},
		},
	})
}

export default createMuiTheme
