import { alpha } from '@mui/material/styles'

import { CustomTheme } from '.'
import { createBaseTheme } from './default'
import { defaultPalette } from './palettes'

export const minimalistTheme: CustomTheme = {
	name: 'minimalist',
	borderRadius: 6,
	palette: {
		...defaultPalette,
		background: '#fefefe',
		outline: 'rgb(236, 239, 241)',
	},
}

const createMuiTheme = () => {
	const baseMuiTheme = createBaseTheme({ palette: { mode: 'light' } })

	return createBaseTheme({
		name: 'minimalist',
		shape: {
			borderRadius: minimalistTheme.borderRadius,
		},
		palette: {
			background: {
				default: '#fbfbfd',
				//default: '#fefefe',
				secondary: baseMuiTheme.palette.grey[200],
			},
			divider: minimalistTheme.palette.outline,
			translucent: '#fefefe',
		},

		components: {
			MuiButton: {
				styleOverrides: {
					contained: { boxShadow: 'none' },
				},
			},
			MuiFab: {
				styleOverrides: {
					root: { boxShadow: 'none' },
				},
			},
			MuiPaper: {
				styleOverrides: {
					elevation1: {
						//border: `1px solid ${minimalistTheme.palette.outline}`,
						boxShadow: '0 1px 4px 0px rgba(17, 51, 83, 0.02)',
					},
					elevation2: {
						//border: `1px solid ${minimalistTheme.palette.outline}`,
						boxShadow: '0 2px 8px 0px rgba(17, 51, 83, 0.04)',
					},
					elevation3: {
						//border: `1px solid ${minimalistTheme.palette.outline}`,
						boxShadow: '0 3px 12px 0px rgba(17, 51, 83, 0.07)',
					},
					elevation4: {
						//border: `1px solid ${minimalistTheme.palette.outline}`,
						boxShadow: '0 4px 16px 0px rgba(17, 51, 83, 0.10)',
					},
					elevation5: {
						//border: `1px solid ${minimalistTheme.palette.outline}`,
						boxShadow: '0 5px 18px 0px rgba(17, 51, 83, 0.14)',
					},
					elevation6: {
						//border: `1px solid ${minimalistTheme.palette.outline}`,
						boxShadow: '0 6px 24px 0px rgba(17, 51, 83, 0.18)',
					},
				},
			},
			MuiTabs: {
				styleOverrides: {
					indicator: {
						borderRadius: 0,
						height: 1,
					},
				},
			},
			TyTabBar: {
				styleOverrides: {
					root: {
						backgroundColor: minimalistTheme.palette.background,
					},
				},
			},
			TyTabBarItem: {
				styleOverrides: {
					root: {
						border: '1px solid transparent',
						borderBottom: 'none',

						'&:hover:not(.Mui-selected)': {
							backgroundColor: alpha(
								baseMuiTheme.palette.text.primary,
								baseMuiTheme.palette.action.hoverOpacity
							),
							borderColor: minimalistTheme.palette.outline,
						},
					},
					selected: {
						backgroundColor: baseMuiTheme.palette.background.paper,
						borderColor: minimalistTheme.palette.outline,
					},
				},
			},
		},
	})
}

export default createMuiTheme
