import AppLayout from './AppLayout'
import { LoginPage } from './login'

export function App() {
	return (
		<AppLayout>
			<LoginPage />
		</AppLayout>
	)
}

export default App
