import { formatISO, isBefore, parseISO } from 'date-fns'
import moment from 'moment'
import { merge, omit, pipe, prop, reduce, reject, uniqBy } from 'remeda'
import * as yup from 'yup'

import {
	Task,
	TaskFollower,
	Workflow,
	WorkflowData,
	WorkflowDataStep,
	WorkflowIndex,
	WorkflowStep,
	WorkflowStepAction,
} from '../types'
import { OldWorkflowData, WorkflowDataSummary } from './workflow-types'
import { getActiveStepId, getStepById } from './workflow-utils'

// TODO: replace joi schemas with yup schemas (joi doesn't play nice with the mobile app)
const IdSchema = yup.string().trim().min(1)
const WorkflowStepSchema = yup.object({
	id: IdSchema.required(),
	title: yup.string().required(),
	description: yup.string(),
	actions: yup.array(),
})

const mergeFollowers = (
	arr1: TaskFollower[] = [],
	arr2: TaskFollower[] = []
) => {
	const followerIndex = arr1.concat(arr2).reduce(
		(acc, follower) => {
			acc[follower.id] = acc[follower.id] || ({} as TaskFollower)
			acc[follower.id] = {
				...acc[follower.id],
				...follower,
			}
			return acc
		},
		{} as Record<string, TaskFollower>
	)
	return Object.values(followerIndex)
}

// Convert workflow steps to stepData
export const patchWorkflowData = (workflowData?: OldWorkflowData) => {
	if (!workflowData || !workflowData.steps || workflowData.stepData) {
		return workflowData
	}

	const stepData = reduce(
		workflowData.steps,
		(acc, step) => {
			if (step && step.id) {
				acc[step.id] = step
			}
			return acc
		},
		{} as Record<string, WorkflowDataStep>
	)

	return {
		...omit(workflowData, ['steps']),
		stepData,
	}
}

export const addWorkflowToTask = (task: Task, workflow: Workflow) => {
	const prevWorkflowData = task.workflowData

	// If this task already has this workflow, do nothing.
	if (prevWorkflowData && prevWorkflowData.id === workflow.id) {
		return task
	}

	return {
		...task,
		workflowData: {
			id: workflow.id,
			activeStepId: workflow.steps[0].id,
		},
	}
}

export const buildWorkflowData = (
	workflowById: WorkflowIndex = {},
	workflowData: WorkflowDataSummary
) => {
	const childWorkflow = workflowData?.childWorkflow?.id
		? workflowById[workflowData.childWorkflow.id]
		: null
	const parentWorkflow = workflowData?.parentWorkflow?.id
		? workflowById[workflowData.parentWorkflow.id]
		: null
	const selfWorkflow = workflowData?.taskWorkflow?.id
		? workflowById[workflowData.taskWorkflow.id]
		: null

	const overriddenWorkflow =
		parentWorkflow && selfWorkflow ? selfWorkflow : null

	const workflow = parentWorkflow || selfWorkflow
	const activeStepId =
		workflow &&
		getActiveStepId(workflow, workflowData.taskWorkflow || undefined)
	const activeStep =
		workflow && activeStepId ? getStepById(workflow, activeStepId) : null

	return {
		childWorkflow,
		parentWorkflow,
		overriddenWorkflow,
		selfWorkflow,
		activeStep,
		// Currently active workflow, either assigned to self or inherited.
		activeWorkflowData: workflowData,
		activeWorkflow: workflow,
		workflowData,
		workflowId: workflow?.id,

		// Deprecated
		workflow,
	}
}

export const bulkAddWorkflowTask = (tasks: Task[], workflow: Workflow) => {
	// TODO: validate input tasks to make sure they all have similar workflow
	return tasks.map((task) => addWorkflowToTask(task, workflow))
}

export const applyWorkflowActionsToTask = (
	taskChanges: Partial<Task>,
	workflow?: Workflow,
	oldTask?: Partial<Task>
): Partial<Task> => {
	if (
		!workflow ||
		!Array.isArray(workflow.steps) ||
		workflow.steps.length <= 0 ||
		!oldTask
	) {
		return taskChanges
	}

	const activeStepId = taskChanges.workflowData?.activeStepId
		? taskChanges.workflowData.activeStepId
		: workflow.steps[0].id
	if (!activeStepId) {
		return taskChanges
	}

	const activeStep = workflow.steps.find((step) => step.id === activeStepId)
	if (!activeStep || !activeStep.actions) {
		return taskChanges
	}

	// We only want to apply actions when the step changes
	const oldStepId = oldTask?.workflowData?.activeStepId
	if (oldStepId === activeStepId) {
		return taskChanges
	}

	let newChanges = { ...taskChanges }

	const parseUpdateAction = (actionData: WorkflowStepAction['payload']) => {
		const result = {} as Partial<Task>

		if ('assigneeId' in actionData) {
			let assigneeId = actionData.assigneeId

			// This is a special bit of logic that allows the assignee to be
			// set to whatever the owner of the task is at the time.
			if (actionData.assigneeId === 'ownerId' && oldTask.ownerId) {
				assigneeId = oldTask.ownerId
			}
			result.assigneeId = assigneeId

			// If the startDate is in the past, then set the startDate to now.
			if (
				oldTask.startDate &&
				isBefore(parseISO(oldTask.startDate), new Date())
			) {
				result.startDate = formatISO(new Date())
			}

			// NOTE: disabled for now because we don't have a way to tell if the
			// assigneeId from the quickAdd is explicitly set or not.
			// If the taskChanges explicitly set the assigneeId, then use that
			// instead.
			// result.assigneeId = newChanges.assigneeId
			// 	? newChanges.assigneeId
			// 	: assigneeId

			// If the assignee is overridden then move them to followers.
			if (
				oldTask.assigneeId &&
				oldTask.assigneeId !== assigneeId &&
				assigneeId !== null
			) {
				result.followers = mergeFollowers(oldTask.followers, [
					{
						id: oldTask.assigneeId,
						isVirtual: false,
						roles: 'follower',
					},
				])
			}
		}

		if (actionData.duration) {
			result.hoursAllocated = actionData.duration / 3600
		}

		return result
	}

	for (let i = 0; i < activeStep.actions.length; i++) {
		const { payload, type } = activeStep.actions[i]
		switch (type) {
			case 'updateTask': {
				const changesPatch = parseUpdateAction(payload)
				newChanges = merge(newChanges, changesPatch)
				break
			}
		}
	}

	return newChanges
}

// Returns a copy of the task with the edited workflow step
export const changeWorkflowStep = (
	task: Task,
	workflow: Workflow,
	step: WorkflowStep,
	userId: string
) => {
	const prevWorkflowData = task.workflowData || {
		id: workflow?.id,
		activeStepId: workflow?.steps?.[0]?.id,
	}
	const prevStepData = prevWorkflowData?.stepData || {}

	if (!workflow || !workflow.id || !workflow.steps) {
		throw new Error(
			`A valid workflow is required -- ${JSON.stringify(workflow)}`
		)
	}

	try {
		IdSchema.validateSync(workflow.id)
	} catch (err) {
		const message =
			typeof err === 'string'
				? err
				: err instanceof Error
					? err.message
					: 'Unknown error'
		throw new Error(`A valid workflow is required -- ${message}`)
	}

	try {
		WorkflowStepSchema.validateSync(step)
	} catch (err) {
		const message =
			typeof err === 'string'
				? err
				: err instanceof Error
					? err.message
					: 'Unknown error'
		throw new Error(`A valid workflow step is required -- ${message}`)
	}

	// If workflow doesn't match then return error.
	if (prevWorkflowData && prevWorkflowData.id !== workflow.id) {
		throw new Error('Trying to change step with mismatched workflows')
	}

	if (!userId) {
		throw new Error(`A userId is required -- '${userId}' was given`)
	}

	let newStepData = prevWorkflowData?.stepData ? { ...prevStepData } : null

	if (prevWorkflowData?.activeStepId) {
		const activeStep = workflow.steps.find(
			(step) => step.id === prevWorkflowData.activeStepId
		)
		const prevStep =
			prevStepData?.[prevWorkflowData.activeStepId] ||
			({
				...activeStep,
			} as WorkflowStep)
		newStepData = {
			...newStepData,
			[prevStep.id]: {
				...prevStep,
				completed: {
					userId,
					date: moment().format(),
				},
			},
		}
	}

	return {
		workflowData: patchWorkflowData({
			...prevWorkflowData,
			id: workflow.id,
			activeStepId: step.id,
			stepData: newStepData,
		}),
	}
}

export const bulkChangeWorkflowStep = (
	tasks: Task[],
	workflow: Workflow,
	step: WorkflowStep,
	userId: string
) => {
	// TODO: validate input tasks to make sure they all have similar workflow
	return tasks.map((task) => changeWorkflowStep(task, workflow, step, userId))
}

export const mergeWorkflow = (
	oldWorkflow: Workflow,
	newWorkflow: Partial<Workflow>
) => ({
	...oldWorkflow,
	...newWorkflow,
})

export const mergeWorkflowData = (
	taskChanges: Partial<Task>,
	workflow?: Workflow,
	oldTask?: Task
) => {
	if (!workflow || !oldTask) {
		return taskChanges
	}

	const newChanges = { ...taskChanges }

	newChanges.workflowData = merge(
		oldTask.workflowData,
		taskChanges.workflowData
	)

	return newChanges
}

export const rejectInvalidSteps = (workflow: Workflow) => ({
	...workflow,
	steps: pipe(
		workflow.steps,
		reject((step) => !step.title || step.title.trim() === ''),
		uniqBy(prop('id'))
	),
})

export const updateWorkflowRequirements = (
	stepId: string,
	requirements: WorkflowDataStep['requirements'],
	workflowData: WorkflowData
) => {
	const stepData = workflowData?.stepData || {}
	const oldStep = stepData[stepId] || {}
	const newStep = { ...oldStep, requirements }

	return {
		...workflowData,
		stepData: { ...stepData, [stepId]: newStep },
	}
}
