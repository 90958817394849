import { produce } from 'immer'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import {  prop, uniqBy } from 'ramda'

import {
	TaskActivityMove,
	taskActivityTypes as types,
	WrappedTaskActivityV2,
	WrappedTaskActivityV2WithType,
} from './task-activity-schemas'

const updateableTypes = [
	types.CREATE,
	types.DESCR,
	types.DUE_DATE,
	types.DURATION,
	types.MOVE,
	types.PRIORITY,
	types.SCHEDULE,
	types.STATUS,
	types.TITLE,
	types.UPDATE,
]

export const isRecent = (dateCreated: Date) =>
	moment(dateCreated).isAfter(moment().subtract(5, 'minutes'))

export const isSameUserAndType = (
	oldActivity: WrappedTaskActivityV2,
	newActivity: WrappedTaskActivityV2
) =>
	oldActivity.data.type === newActivity.data.type &&
	oldActivity.userId === newActivity.userId

export const overwriteActivity = (
	oldActivity: WrappedTaskActivityV2,
	newActivity: WrappedTaskActivityV2
): WrappedTaskActivityV2 => {
	// Only overwrite if types are different
	if (!isSameUserAndType(oldActivity, newActivity)) {
		return newActivity
	}
	switch (newActivity.data.type) {
		case types.MOVE:
			const old =
				oldActivity as WrappedTaskActivityV2WithType<TaskActivityMove>
			return produce(
				newActivity as WrappedTaskActivityV2WithType<TaskActivityMove>,
				(draft) => {
					draft.data.oldParentId = old.data.oldParentId
					draft.data.oldParentTitle = old.data.oldParentTitle
					draft.data.meta.oldTask = old.data.meta.oldTask
				}
			)
		default:
			return newActivity
	}
}

/**
 * Check if the last activity should be overwritten
 *
 * 🦉 We want to replace the latest activity, instead of add a new one -- when
 * it is fairly new, has the same type, triggered by the same user, and is in
 * the list of types that we care about replacing. This prevents too many,
 * argueably unnecessary, activity events showing for the same action. Keeping
 * the information-overhead lower for users.
 */
export const shouldOverwriteLastActivity = (
	lastActivity: WrappedTaskActivityV2,
	newActivity: WrappedTaskActivityV2
): boolean => {
	if (!lastActivity) return false

	return (
		isSameUserAndType(lastActivity, newActivity) &&
		isRecent(lastActivity.dateCreated) &&
		includes(updateableTypes, newActivity.data.type)
	)
}

const dateSort = (activity: WrappedTaskActivityV2) => {
	if (!activity?.dateCreated) {
		return 0
	}
	return moment(activity.dateCreated).valueOf()
}

/**
 * Add an activity entity to a list of activity entities.
 *
 * Pass it the previous list of activity and a payload that is the new
 * activity. The result will be a new list ordered by date created, descending.
 */
export const addActivityToList = (
	activityList: WrappedTaskActivityV2[] = [],
	activity: WrappedTaskActivityV2
): WrappedTaskActivityV2[] => {
	const orderedList = orderBy(activityList, dateSort, 'desc')

	const latestActivity = orderedList[0]

	if (shouldOverwriteLastActivity(latestActivity, activity)) {
		// Replace the last activity instead of adding a new entry
		return uniqBy(prop('id'), [
			overwriteActivity(latestActivity, activity),
			...orderedList.slice(1),
		])
	} else {
		// Add activity
		return uniqBy(
			prop('id'),
			orderBy([activity, ...orderedList], dateSort, 'desc')
		)
	}
}
