/*
 * Task factory functions for mutating state
 */

import produce from 'immer'
import { QueryClient } from 'react-query'

import { Task, TeardownFn, Workflow } from '../../../types'
import { applyWorkflowActionsToTask, getWorkflowData } from '../../../workflows'
import { ApiAdapter, ApiListResult } from '../../api/baseApiAdapter'
import { TaskPosition } from '../../createActions'
import { taskKeys } from '../../queries/tasks'
import { workflowKeys } from '../../queries/workflows'
import { AppState } from '../../store-types'
import { getParentsByParent } from '../../utils'
import { deepMergeTask } from '../../utils/deepMergeTask'
import { createBaseQueryCacheMutation } from '../baseCreate'

export type MoveTaskFn<T> = (taskId: string, destination: TaskPosition) => T

export const moveTaskMutation = async (
	state: AppState,
	taskId: string,
	destination: TaskPosition
) => {
	const { apiAdapter, queryClient } = state
	const moveTaskOnApi = createMoveTaskOnApi(apiAdapter)
	createMoveTaskOnQueryCache(queryClient)(taskId, destination)

	const result = await moveTaskOnApi(taskId, destination)

	result?.srcParent &&
		queryClient.setQueryData(
			taskKeys.detail(result.srcParent.id),
			result.srcParent
		)
	result?.destParent &&
		queryClient.setQueryData(
			taskKeys.detail(result.destParent.id),
			result.destParent
		)
	result?.task &&
		queryClient.setQueryData(taskKeys.detail(result.task.id), result.task)
}

export const moveBulkTasksMutation = async (
	state: AppState,
	taskIds: string[],
	destination: TaskPosition
) => {
	const { apiAdapter, queryClient } = state
	const moveBulkTasksOnApi = createMoveBulkTasksOnApi(apiAdapter)
	const moveTaskOnQueryCache = createMoveTaskOnQueryCache(queryClient)

	taskIds.forEach((taskId) => {
		moveTaskOnQueryCache(taskId, destination)
	})

	return moveBulkTasksOnApi(taskIds, destination)
}

export const createMoveTaskOnApi =
	(apiAdapter: ApiAdapter) => (taskId: string, destination: TaskPosition) =>
		apiAdapter.tasks.move(taskId, {
			parentId: destination ? destination.parentId : null,
			position: destination
				? destination.childSortOrder || destination.index
				: 0,
		})

export const createMoveBulkTasksOnApi =
	(apiAdapter: ApiAdapter) =>
	(taskIds: string[], destination: TaskPosition) =>
		apiAdapter.tasks.moveBulk(taskIds, {
			parentId: destination ? destination.parentId : null,
			position: destination
				? destination.childSortOrder || destination.index
				: 0,
		})

export const createMoveTaskOnQueryCache =
	(queryClient: QueryClient): MoveTaskFn<Promise<TeardownFn>> =>
	async (taskId, destination) => {
		const standardMutation = createBaseQueryCacheMutation(queryClient)
		const taskQueryKey = taskKeys.detail(taskId)
		const task = queryClient.getQueryData<Task>(taskQueryKey)

		const destinationParent = destination?.parentId
			? queryClient.getQueryData<Task>(
					taskKeys.detail(destination.parentId)
				)
			: null

		const taskChanges = destinationParent
			? {
					parentId: destinationParent.id,
					parents: destinationParent.parents.concat({
						id: destinationParent.id,
						title: destinationParent.title,
					}),
				}
			: {
					parentId: null,
					parents: [],
				}

		// Update current task parentId
		standardMutation<Task>(
			taskKeys.detail(taskId),
			produce((draft) => {
				if (!draft) {
					return draft
				}
				return deepMergeTask(draft, taskChanges)
			})
		)

		// Remove task id from source parent
		if (task?.parentId) {
			standardMutation<Task>(
				taskKeys.detail(task.parentId),
				(prevTask) => {
					if (!prevTask) {
						return undefined
					}
					const prevChildSortOrder = prevTask.childSortOrder || []
					const changes: Partial<Task> = {
						childSortOrder: prevChildSortOrder.filter(
							(id) => id !== taskId
						),
					}
					return deepMergeTask(prevTask, changes)
				}
			)
		}

		// Add task id to destination parent
		if (destination?.parentId) {
			standardMutation<Task>(
				taskKeys.detail(destination.parentId),
				(prevTask) => {
					let changes: Partial<Task> = {}
					if (!prevTask) {
						return undefined
					}
					if (destination.childSortOrder) {
						changes = {
							childSortOrder: destination.childSortOrder,
						}
					} else if (
						destination.index != null &&
						destination.index > -1
					) {
						const index = destination.index
						const childSortOrder = produce(
							prevTask.childSortOrder || [],
							(draft) => {
								if (!draft) {
									return
								}
								draft.splice(index, 0, taskId)
							}
						)
						changes = { childSortOrder }
					}
					return deepMergeTask(prevTask, changes)
				}
			)

			const parent = queryClient.getQueryData<Task>(
				taskKeys.detail(destination.parentId)
			)

			await queryClient.cancelQueries(taskQueryKey, { exact: true })

			// Check if there is cached data to update
			if (task) {
				const changes: Partial<Task> = {
					parentId: destination.parentId,
				}

				// Update parents field if we have a parent in the cache
				changes.parents = getParentsByParent(parent)

				// Update any workflow actions
				const workflowData = getWorkflowData(task, parent)
				if (workflowData.parentWorkflow) {
					const workflowIndex = queryClient.getQueryData<
						Record<string, Workflow>
					>(workflowKeys.list())
					const workflow = workflowIndex
						? workflowIndex[workflowData.parentWorkflow?.id || '']
						: null

					const withWorkflowActions = applyWorkflowActionsToTask(
						changes,
						workflow || undefined,
						task
					)
					queryClient.setQueryData(
						taskQueryKey,
						deepMergeTask(task, withWorkflowActions)
					)
				} else {
					queryClient.setQueryData(
						taskQueryKey,
						deepMergeTask(task, changes)
					)
				}
			}
		}

		const queryKey = taskKeys.lists()
		await queryClient.cancelQueries(queryKey, { exact: true })

		// Optimistically update to the new value on all lists.
		queryClient.setQueriesData<ApiListResult<Task> | Task[] | undefined>(
			queryKey,
			(prevResult) => {
				const mapTask = (task: Task) => {
					if (task?.id === taskId) {
						return deepMergeTask(task, taskChanges)
					}
					return task
				}

				if (Array.isArray(prevResult)) {
					return prevResult.map(mapTask)
				} else if (prevResult?.items) {
					return {
						...prevResult,
						items: prevResult.items.map(mapTask),
					}
				} else {
					return prevResult
				}
			}
		)

		// Return a rollback function.
		return () => {
			// TODO: add rollback logic for all the lists
			queryClient.setQueryData(taskQueryKey, task)
		}
	}
