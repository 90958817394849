import produce from 'immer'
import { MutationObserver, QueryClient } from 'react-query'

import { StoreContext } from '../../../types'
import { ApiListResult } from '../../api'
import { createMutationErrorRollback } from '../../mutations/mutations-utils'
import { chatKeys } from '../chatKeys'
import { createAddChatRoomMutationFn } from '../chatQueries'
import { ChatRoom } from '../chatTypes'

export const createAddChatRoomOptimisticHandler =
	(queryClient: QueryClient) => async (chatRoom: ChatRoom) => {
		const roomDetailKey = chatKeys.roomDetail(chatRoom.id)
		const roomListKey = chatKeys.roomList()
		const previousResult =
			queryClient.getQueryData<ApiListResult<ChatRoom>>(roomListKey)
		await queryClient.cancelQueries(roomListKey)

		queryClient.setQueryData<ChatRoom>(roomDetailKey, chatRoom)
		queryClient.setQueryData<ApiListResult<ChatRoom>>(
			roomListKey,
			produce((draft) => {
				if (!draft || !draft.items) {
					return
				}
				const existingRoom = draft.items.find(
					(room) => room.id === chatRoom.id
				)
				if (!existingRoom) {
					draft.items.unshift(chatRoom)
				}
			})
		)
		return { previousResult: previousResult, queryKey: roomListKey }
	}

export const createAddChatRoomMutationObserver = ({
	apiAdapter,
	queryClient,
}: Pick<StoreContext, 'apiAdapter' | 'queryClient'>) =>
	new MutationObserver(queryClient, {
		mutationFn: createAddChatRoomMutationFn(apiAdapter),
		// onMutate: createAddChatRoomOptimisticHandler(queryClient),
		onError: createMutationErrorRollback(queryClient),
	})
