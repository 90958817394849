/*
 * Workflow factory functions for mutating state
 */

import { QueryClient } from 'react-query'
import { merge } from 'remeda'

import { PlayerOptions } from '../../../types'
import { ApiAdapter } from '../../api/baseApiAdapter'
import { playerKeys } from '../../queries'
import { AppState } from '../../store-types'
import { createBaseQueryCacheMutation } from '../baseCreate'

type UpdateOptionsFn = (
	state: AppState,
	changes: Partial<PlayerOptions>
) => void

export const updatePlayerOptionsMutation: UpdateOptionsFn = (
	state,
	changes
) => {
	const { apiAdapter, queryClient } = state
	createUpdatePlayerOptionsOnApi(apiAdapter)(changes)
	createUpdatePlayerOptionsOnQueryCache(queryClient)(changes)
}

export const createUpdatePlayerOptionsOnApi =
	(apiAdapter: ApiAdapter) => (changes: Partial<PlayerOptions>) => {
		apiAdapter.users.updateOptions('me', changes)
	}

export const createUpdatePlayerOptionsOnQueryCache =
	(queryClient: QueryClient) => (changes: Partial<PlayerOptions>) => {
		const standardMutation = createBaseQueryCacheMutation(queryClient)
		return standardMutation<Partial<PlayerOptions>>(
			playerKeys.options(),
			(prevOptions) =>
				prevOptions ? merge(prevOptions, changes) : changes
		)
	}
