import { deepMerge } from '@tyto/utils'

import { Task } from '../../types'

export const deepMergeTask = <T extends Task = Task>(
	target: T,
	source: Partial<T>
): T => {
	const result = deepMerge(target, source)
	if ('parents' in source && source.parents) {
		// Redeclare parents field because deepMerge concats arrays
		result.parents = source.parents
	}

	if ('childSortOrder' in source && source.childSortOrder) {
		// Redeclare childSortOrder field because deepMerge concats arrays
		result.childSortOrder = source.childSortOrder
	}
	return result
}
