/*
 * Workflow factory functions for mutating state
 */

import { assoc, mergeDeepLeft } from 'ramda'
import { QueryClient } from 'react-query'

import { Workflow, WorkflowIndex } from '../../../types'
import { ApiAdapter } from '../../api'
import { workflowKeys } from '../../queries'
import { AppState } from '../../store-types'
import { createBaseQueryCacheMutation } from '../baseCreate'

export const updateWorkflowMutation = (
	state: AppState,
	workflowId: string,
	changes: Partial<Workflow>
) => {
	const { apiAdapter, queryClient } = state
	createUpdateWorkflowOnApi(apiAdapter)(workflowId, changes)
	createUpdateWorkflowOnQueryCache(queryClient)(workflowId, changes)
}

export const createUpdateWorkflowOnApi =
	(apiAdapter: ApiAdapter) =>
	(workflowId: string, changes: Partial<Workflow>) => {
		apiAdapter.workflows.update(workflowId, changes)
	}

export const createUpdateWorkflowOnQueryCache =
	(queryClient: QueryClient) =>
	(workflowId: string, changes: Partial<Workflow>) => {
		const standardMutation = createBaseQueryCacheMutation(queryClient)
		return standardMutation<WorkflowIndex>(
			workflowKeys.list(),
			(prevIndex) => {
				// We can't guarentee a full workflow object with only partial
				// changes.
				// TODO: lazy load the workflows and then make the change, there
				// is a possibility that the cache is empty because it expired.
				// This hypothesis needs to be verified with the React Query
				// docs, perhaps the lazy loading will happen automatically if
				// the cache is being listened to and the update will wait until
				// the request has finished.
				if (!prevIndex || !prevIndex[workflowId]) {
					return undefined
				}

				return assoc(
					workflowId,
					mergeDeepLeft(changes, prevIndex[workflowId]) as Workflow,
					prevIndex
				)
			}
		)
	}
