import { CustomTheme } from '.'
import { createWallpaperTheme } from './default'
import { defaultPalette } from './palettes'
import woodDarkBackground from './wallpapers/background-wood-dark.jpg'

export const woodDarkTheme: CustomTheme = {
	name: 'woodDark',
	appBackground: { type: 'image', url: woodDarkBackground },
	palette: {
		...defaultPalette,
		mode: 'dark',
		background: 'rgb(7, 9, 11)',
		onBackground: '#fff',
		surface: 'rgba(7, 9, 11, 0.7)',
		onSurface: '#fff',
		sidebar: 'rgba(25, 25, 25, 0.7)',
		onSidebar: '#fff',
	},
}

const createMuiTheme = () => {
	return createWallpaperTheme(
		{
			name: 'woodDark',
			appBackgroundImage: `url("${woodDarkBackground}")`,
			palette: {
				mode: 'dark',
				background: {
					default: woodDarkTheme.palette.background,
					paper: woodDarkTheme.palette.surface,
					contextPanel: 'rgba(25, 25, 25, 0.4)',
					sidebar: woodDarkTheme.palette.sidebar,
				},
			},
			components: {
				MuiPaper: {
					styleOverrides: {
						root: {
							backdropFilter: 'blur(8px)',
						},
						elevation1: { boxShadow: 'none' },
						elevation2: { boxShadow: 'none' },
						elevation3: { boxShadow: 'none' },
						elevation4: { boxShadow: 'none' },
						elevation5: { boxShadow: 'none' },
						elevation6: { boxShadow: 'none' },
					},
				},
			},
		},
		{}
	)
}

export default createMuiTheme
