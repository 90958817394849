import { MouseEventHandler, useState } from 'react'

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
	TextField,
} from '@mui/material'

import { ConfirmOptions } from './confirmTypes'

export interface ConfirmDialogProps {
	open: boolean
	options: ConfirmOptions
	onCancel: MouseEventHandler<HTMLButtonElement>
	onClose: DialogProps['onClose']
	onConfirm: MouseEventHandler<HTMLButtonElement>
}

export function ConfirmationDialog({
	open,
	options,
	onCancel,
	onClose,
	onConfirm,
}: ConfirmDialogProps) {
	const [confirmationKeywordValue, setConfirmationKeywordValue] = useState('')

	const {
		title,
		description,
		content,
		confirmationText,
		cancellationText,
		dialogProps,
		dialogActionsProps,
		confirmationButtonProps,
		cancellationButtonProps,
		titleProps,
		contentProps,
		allowClose,
		confirmationKeyword,
		confirmationKeywordTextFieldProps,
		hideCancelButton,
		buttonOrder,
	} = options

	const confirmationButtonDisabled =
		confirmationKeyword && confirmationKeywordValue !== confirmationKeyword

	const confirmationContent = (
		<>
			{confirmationKeyword && (
				<TextField
					onChange={(e) =>
						setConfirmationKeywordValue(e.target.value)
					}
					value={confirmationKeywordValue}
					fullWidth
					{...confirmationKeywordTextFieldProps}
				/>
			)}
		</>
	)

	const dialogActions = buttonOrder?.map((buttonType) => {
		if (buttonType === 'cancel') {
			return (
				!hideCancelButton && (
					<Button
						key="cancel"
						{...cancellationButtonProps}
						onClick={onCancel}
					>
						{cancellationText}
					</Button>
				)
			)
		}

		if (buttonType === 'confirm') {
			return (
				<Button
					key="confirm"
					color="primary"
					disabled={confirmationButtonDisabled}
					{...confirmationButtonProps}
					onClick={onConfirm}
				>
					{confirmationText}
				</Button>
			)
		}

		throw new Error(
			`Supported button types are only "confirm" and "cancel", got: ${buttonType}`
		)
	})

	return (
		<Dialog
			fullWidth
			{...dialogProps}
			open={open}
			onClose={allowClose ? onClose : null}
		>
			{title && <DialogTitle {...titleProps}>{title}</DialogTitle>}
			{content ? (
				<DialogContent {...contentProps}>
					{content}
					{confirmationContent}
				</DialogContent>
			) : description ? (
				<DialogContent {...contentProps}>
					<DialogContentText>{description}</DialogContentText>
					{confirmationContent}
				</DialogContent>
			) : (
				confirmationKeyword && (
					<DialogContent {...contentProps}>
						{confirmationContent}
					</DialogContent>
				)
			)}
			<DialogActions {...dialogActionsProps}>
				{dialogActions}
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmationDialog
