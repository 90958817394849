import { StrictMode } from 'react'
import axios, { isAxiosError } from 'axios'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import {
	createAxiosApiAdapter,
	isStoreError,
	unwrapStoreException,
	useStore,
} from '@tyto/dna/store'
import { env } from '@tyto/utils'

import { clearToken } from './app/login'
import Root from './app/Root'

const cacheTime = 1000 * 60 * 60 * 24
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			cacheTime,
			staleTime: 1000 * 5,
			// Only track changes in fields that are used from destructuring
			// In v4, this will be the default behavior
			notifyOnChangeProps: 'tracked',
		},
	},
})

const apiInstance = axios.create({ baseURL: `${env('API_URL')}/v1` })
apiInstance.interceptors.request.use((request) => {
	request.headers['Authorization'] = `Bearer ${
		useStore.getState().player.authToken
	}`
	request.headers['X-Tyto-Source'] = 'web'

	if (env('APP_ENV')) {
		request.headers['X-Tyto-Environment'] = env('APP_ENV')
	}
	return request
})

const apiAdapter = createAxiosApiAdapter(apiInstance)
apiInstance.interceptors.response.use(undefined, (err) => {
	if (isStoreError(err)) {
		const error = unwrapStoreException(err)
		if (error.message === 'canceled') {
			return Promise.resolve()
		} else if (isAxiosError(error)) {
			if (error.response?.status === 401) {
				useStore.getState().player.logout()
				clearToken()
			}
		}
		console.error('store error', error)
	} else {
		console.error(err)
	}
	return Promise.reject(err)
})

useStore.setState({ apiAdapter, queryClient })

const container = document.getElementById('root')

if (!container) {
	throw new Error('No root element found')
}

const root = createRoot(container)
root.render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<Root />
			</BrowserRouter>
		</QueryClientProvider>
	</StrictMode>
)
