import { RawDraftContentBlock, RawDraftEntity } from 'draft-js'
import map from 'lodash/map'

import { User, WorkflowDataStep } from '../../../types'
import { TaskActivityWorkflow } from '../task-activity-schemas'

interface RawDraftContentBlockChecklistBlock extends RawDraftContentBlock {
	data: {
		checked: boolean
	}
	type: 'checkable'
}
type RequirementsDiff = Record<
	string,
	Omit<WorkflowDataStep, 'requirements'> & {
		requirements: {
			blocks: Record<string, RawDraftContentBlockChecklistBlock>
			entityMap: Record<string, RawDraftEntity>
		}
	}
>

interface RequirementsChange {
	newSteps: Record<string, WorkflowDataStep>
	new: RequirementsDiff
	old: RequirementsDiff
}

interface PartitionRequirementsChangeResult {
	checked: Record<string, string[]>
	unchecked: Record<string, string[]>
}

const getUserName = (user: Pick<User, 'nickname' | 'name'>) =>
	user ? user.nickname || user.name : ''

const li = (html: string) => `<li>${html}</li>`

export const partitionRequirementsChange = (
	requirementsChange: RequirementsChange
): PartitionRequirementsChangeResult => {
	if (!requirementsChange) {
		return { checked: {}, unchecked: {} }
	}

	const checked: Record<string, string[]> = {}
	const unchecked: Record<string, string[]> = {}

	Object.entries(requirementsChange.new).forEach(([stepId, step]) => {
		Object.entries(step.requirements.blocks).forEach(([blockId, block]) => {
			const isChecked = block.data.checked
			const text =
				requirementsChange.newSteps[stepId].requirements?.blocks[
					blockId
				].text

			if (isChecked) {
				checked[stepId] = checked[stepId] || []
				checked[stepId].push(text)
			} else {
				unchecked[stepId] = unchecked[stepId] || []
				unchecked[stepId].push(text)
			}
		})
	})

	return { checked, unchecked }
}

export const formatRequirementsChangeToHtml = (
	user: Pick<User, 'nickname' | 'name'>,
	activity: TaskActivityWorkflow
) => {
	const requirementsChange = activity?.requirementsChange
	const { checked, unchecked } =
		partitionRequirementsChange(requirementsChange)

	const createRequirements = (requirements) =>
		map(requirements, (requirement) =>
			requirement ? li(requirement) : ''
		).join('')
	const createSteps = (steps) =>
		map(
			steps,
			(step) =>
				`<strong>${step.title}</strong><ul>${createRequirements(
					step.requirements
				)}</ul>`
		).join('')

	// Fallback to generic message if changes aren't clear
	if (!requirementsChange || Object.keys(requirementsChange).length === 0) {
		return `${getUserName(user)} updated step requirements.`
	}

	let result = `${getUserName(user)} `

	const createSingleMessage = (state, requirements) => {
		const stepId = Object.keys(requirements)[0]
		const step = requirements[stepId]
		// TODO: get step title from somewhere, it's not passed in.
		const title = activity.requirementsChange?.newSteps[stepId].title

		return `${state} the following workflow step requirements for <strong>${title}</strong>:<br/><ul>${createRequirements(step)}</ul>`
	}
	const createBulkMessage = (state, requirements) => {
		return `${state} the following workflow step requirements:<br/><br/>${createSteps(
			requirements
		)}`
	}

	if (Object.keys(checked).length === 1) {
		result += createSingleMessage('ticked', checked)
	} else if (Object.keys(unchecked).length === 1) {
		result += createSingleMessage('<strong>unticked</strong>', unchecked)
	} else {
		if (Object.keys(checked).length > 0) {
			result += createBulkMessage('ticked', checked)
		}
		if (Object.keys(unchecked).length > 0) {
			if (Object.keys(checked).length > 0) {
				result += ' and '
			}
			result += createBulkMessage('<strong>unticked</strong>', unchecked)
		}
	}

	return result
}
