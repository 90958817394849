/*
 * Workflow factory functions for mutating state
 */

import { omit } from 'ramda'
import { QueryClient } from 'react-query'

import { WorkflowIndex } from '../../../types'
import { ApiAdapter } from '../../api/baseApiAdapter'
import { workflowKeys } from '../../queries'
import { AppState } from '../../store-types'
import { createBaseQueryCacheMutation } from '../baseCreate'

export const removeWorkflowMutation = async (
	state: AppState,
	workflowId: string
) => {
	const { apiAdapter, queryClient } = state
	createRemoveWorkflowFromApi(apiAdapter)(workflowId)
	createRemoveWorkflowFromQueryCache(queryClient)(workflowId)
}

export const createRemoveWorkflowFromApi =
	(apiAdapter: ApiAdapter) => async (workflowId: string) => {
		apiAdapter.workflows.remove(workflowId)
	}

export const createRemoveWorkflowFromQueryCache =
	(queryClient: QueryClient) => async (workflowId: string) => {
		const standardMutation = createBaseQueryCacheMutation(queryClient)
		return standardMutation<WorkflowIndex>(
			workflowKeys.list(),
			omit([workflowId])
		)
	}
