import { QueryClient } from 'react-query'
import { identity } from 'remeda'

import { Task, TaskFollower, TeardownFn } from '../../../types'
import { ApiAdapter } from '../../api'
import { taskKeys } from '../../queries'
import { AppState } from '../../store-types'
import { createUpdateTaskOnQueryCache } from '../tasks'

export const addFollowerMutation = async (
	state: AppState,
	taskId: string,
	follower: TaskFollower
) => {
	const { apiAdapter, queryClient } = state
	createAddFollowerFromApi(apiAdapter)(taskId, follower)
	createAddFollowerFromQueryCache(queryClient)(taskId, follower)
}

export const createAddFollowerFromApi =
	(apiAdapter: ApiAdapter) =>
	async (taskId: string, follower: TaskFollower) => {
		//apiAdapter.tasks.addFollower(taskId, follower)
	}

export const createAddFollowerFromQueryCache =
	(queryClient: QueryClient) =>
	async (taskId: string, follower: TaskFollower) => {
		const task = queryClient.getQueryData<Task>(taskKeys.detail(taskId))
		if (!task) {
			return identity as TeardownFn
		}

		const addFollowerPatch = {
			id: taskId,
			followers: [...task.followers, follower],
		}
		const updateTask = createUpdateTaskOnQueryCache(queryClient)
		return updateTask(taskId, addFollowerPatch)
	}
