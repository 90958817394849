import { produce } from 'immer'
import type { QueryClient } from 'react-query'

import { getUuid } from '@tyto/helpers/get-uuid'
import { TaskReminder } from '@tyto/types'

import type { ApiAdapter } from '../api'
import { createBaseQueryCacheMutation } from '../mutations/baseCreate'
import { taskKeys } from '../queries/tasks/taskKeys'
import type { AppState } from '../store-types'

export const addTaskReminderMutation = (
	state: AppState,
	taskId: string,
	date: Date
) => {
	const { apiAdapter, queryClient, player } = state
	if (player?.id) {
		createAddTaskReminderToQueryCache(queryClient)(player.id, taskId, date)
	} else {
		// TODO: add error return type Result<Ok, Err>
	}
	return createAddTaskReminderToApi(apiAdapter)(taskId, date)
}

const createAddTaskReminderToApi =
	(apiAdapter: ApiAdapter) => (taskId: string, date: Date) =>
		apiAdapter.tasks.setReminder(taskId, date)

const createAddTaskReminderToQueryCache =
	(queryClient: QueryClient) =>
	(userId: string, taskId: string, date: Date) => {
		const standardMutation = createBaseQueryCacheMutation(queryClient)

		const newReminder: TaskReminder = {
			id: getUuid(),
			lastUpdated: new Date(),
			taskId,
			userId,
			type: 'reminder',
			note: '',
			date,
		}

		standardMutation(
			taskKeys.remindersList(taskId),
			(prevData: TaskReminder[] | undefined) => {
				return produce(prevData, (draft) => {
					if (!draft || !Array.isArray(draft)) {
						return [newReminder]
					}
					draft.push(newReminder)
					return draft
				})
			}
		)
	}

export const removeTaskReminderMutation = (state: AppState, taskId: string) => {
	const { apiAdapter, queryClient } = state
	createRemoveTaskReminderToQueryCache(queryClient)(taskId)
	return createRemoveTaskReminderToApi(apiAdapter)(taskId)
}

const createRemoveTaskReminderToApi =
	(apiAdapter: ApiAdapter) => (taskId: string) =>
		apiAdapter.tasks.removeReminder(taskId)

const createRemoveTaskReminderToQueryCache =
	(queryClient: QueryClient) => (taskId: string) => {
		const standardMutation = createBaseQueryCacheMutation(queryClient)

		standardMutation(
			taskKeys.remindersList(taskId),
			(prevData: TaskReminder[] | undefined) => {
				return produce(prevData, (draft) => {
					if (!draft || !Array.isArray(prevData)) {
						return
					}

					const index = draft.findIndex(
						(item) => item.taskId === taskId
					)
					draft.splice(index, 1)
				})
			}
		)
	}
