import Color from 'color'

import { createBaseTheme } from './default'

// #bad8ea`
// #eef4fa

const brettWasHere = () => {
	const baseTheme = createBaseTheme()

	//const themeColor = Color('rebeccapurple')
	const themeColor = Color('#bad8ea')
	const backgroundDefault = themeColor.lighten(0.1) // Color('#fff')
	const backgroundColumn = themeColor.lighten(0.12).desaturate(0.2)
	const backgroundSidebar = themeColor

	const fontFamily = `Poppins, ${baseTheme.typography.fontFamily}`

	//const lightMuiTheme = createMuiTheme(lightTheme)

	return createBaseTheme({
		name: 'brettWasHere',
		typography: {
			fontFamily,
			h1: { fontWeight: 500 },
			h2: { fontWeight: 500 },
			h3: { fontWeight: 700 },
			h4: { fontWeight: 700 },
			h5: { fontWeight: 700 },
			h6: { fontWeight: 700 },
		},

		palette: {
			background: {
				default: backgroundDefault.hex(),
				boardColumn: backgroundColumn.hex(),
				sidebar: backgroundSidebar.hex(),
			},
		},
	})
}

export default brettWasHere
