import React from 'react'

import { Box, CssBaseline, ThemeProvider } from '@mui/material'

import { usePlayerSelectedTheme } from '@tyto/themes'

export interface AppLayoutProps {
	children: React.ReactNode
}

export const AppLayout = ({ children }: AppLayoutProps) => {
	const { muiTheme: theme } = usePlayerSelectedTheme()

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				}}
			>
				<CssBaseline />
				{children}
			</Box>
		</ThemeProvider>
	)
}

export default AppLayout
