import { formatISO } from 'date-fns'
import {
	InfiniteQueryObserver,
	InfiniteQueryObserverOptions,
	InfiniteQueryObserverResult,
	notifyManager,
	QueryClient,
	QueryKey,
	QueryObserver,
	QueryObserverOptions,
	QueryObserverResult,
} from 'react-query'
import { Observable, Observer } from 'rxjs'

import { ApiAdapter } from '../api'
import { asyncStorage } from '../async-storage'

export type QueryOptions = (
	apiAdapter: ApiAdapter,
	queryClient: QueryClient
) => // TODO: try make this work without using any
(...args: unknown[]) => QueryObserverOptions

// Using QueryObserver to stay agnostic:
// https://github.com/tannerlinsley/react-query/blob/361935a12cec6f36d0bd6ba12e84136c405047c5/src/react/useBaseQuery.ts#L69-L79
export const createQueryObservable = <
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryClient: QueryClient,
	queryOptions: QueryObserverOptions<
		TQueryFnData,
		TError,
		TData,
		TQueryData,
		TQueryKey
	>
): Observable<QueryObserverResult<TData, TError>> =>
	new Observable((observer) => {
		const defaultedOptions =
			queryClient.defaultQueryObserverOptions(queryOptions)

		// Create query observer
		const queryObserver = new QueryObserver(queryClient, defaultedOptions)

		// Update options
		if (queryObserver.hasListeners()) {
			queryObserver.setOptions(defaultedOptions)
		}

		observer.next(queryObserver.getCurrentResult())

		return queryObserver.subscribe(
			notifyManager.batchCalls(
				(result: QueryObserverResult<TData, TError>) =>
					observer.next(result)
			)
		)
	})

export const createInfiniteQueryObservable = <T>(
	queryClient: QueryClient,
	queryOptions: InfiniteQueryObserverOptions<T>
): Observable<InfiniteQueryObserverResult<T>> =>
	new Observable((observer: Observer<InfiniteQueryObserverResult<T>>) => {
		const defaultedOptions =
			queryClient.defaultQueryObserverOptions(queryOptions)

		// Create query observer
		const queryObserver = new InfiniteQueryObserver(
			queryClient,
			defaultedOptions
		)

		// Update options
		if (queryObserver.hasListeners()) {
			queryObserver.setOptions(defaultedOptions)
		}

		observer.next(queryObserver.getCurrentResult())

		return queryObserver.subscribe(
			notifyManager.batchCalls((result: InfiniteQueryObserverResult<T>) =>
				observer.next(result)
			)
		)
	})

const LAST_UPDATED_KEY = 'lastUpdated'

export const getQueryLastUpdated = async (queryKey: QueryKey) => {
	return asyncStorage.getItem(
		LAST_UPDATED_KEY + ':' + JSON.stringify(queryKey)
	)
}

export const setQueryLastUpdated = async (
	queryKey: QueryKey,
	value: string = formatISO(new Date())
) => {
	asyncStorage.setItem(
		LAST_UPDATED_KEY + ':' + JSON.stringify(queryKey),
		value
	)
}

export const removeQueryLastUpdated = async (queryKey: QueryKey) => {
	asyncStorage.removeItem(LAST_UPDATED_KEY + ':' + JSON.stringify(queryKey))
}
