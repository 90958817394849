import { Svg } from 'react-native-svg'

import type { Theme } from '@mui/material/styles'

import babyBlue, { babyBlueTheme } from './babyBlue'
import blueSteel, { blueSteelTheme } from './blueSteel'
import braveNewWorld, { braveNewWorldTheme } from './braveNewWorld'
import brettWasHere from './brettWasHere'
import city, { cityTheme } from './city'
import darkMatter, { darkMatterTheme } from './darkMatter'
import { createBaseTheme } from './default'
import flatWhite, { flatWhiteTheme } from './flatWhite'
import midnight, { midnightTheme } from './midnight'
import minimalist, { minimalistTheme } from './minimalist'
import playful from './playful'
import rubyTuesday, { rubyTuesdayTheme } from './rubyTuesday'
import snow, { snowTheme } from './snow'
import whimsy, { whimsyTheme } from './whimsy'
import woodDark, { woodDarkTheme } from './woodDark'

export type TytoTheme = Theme

// TODO: derive from array of colors so they can be easily extended
export type CustomPalette = {
	mode: 'light' | 'dark'

	// Standard colors
	primary: string
	onPrimary: string
	secondary: string
	onSecondary: string
	neutral: string
	onNeutral: string
	error: string
	onError: string
	background: string
	onBackground: string
	surface: string
	onSurface: string
	outline: string
	shadow: string
	scrim: string

	// Tyto feature colors
	brand: string
	onBrand: string
	busy: string
	onBusy: string
	bulk: string
	onBulk: string
	archived: string
	onArchived: string
	deleted: string
	onDeleted: string
	done: string
	onDone: string
	duration: string
	onDuration: string
	overtime: string
	onOvertime: string
	starred: string
	onStarred: string
	userFocus: string
	onUserFocus: string
	workflow: string
	onWorkflow: string

	// Misc colors
	link: string
	onLink: string
	sidebar: string
	onSidebar: string
	tabBar?: string
	onTabBar?: string

	// exact colors
	black: string
	white: string
	scrorpion: string

	// Tones and shades
	textAlpha1: number
	textAlpha2: number
	textAlpha3: number
}

export type CustomTheme = {
	name: string
	appBackground?: CustomThemeBackground
	borderRadius?: number
	palette: CustomPalette
}

export type CustomThemeBackground =
	| AppBackgroundGradient
	| AppBackgroundImage
	| AppBackgroundSvg
export type AppBackgroundGradient = {
	type: 'gradient'
	colors: string[]
	angle?: number
	locations?: number[]
}
export type AppBackgroundImage = {
	type: 'image'
	url: string
}
export type AppBackgroundSvg = {
	type: 'svg'
	svg: typeof Svg | string
}

export const isAppBackgroundGradient = (
	background: CustomThemeBackground
): background is AppBackgroundGradient => background.type === 'gradient'
export const isAppBackgroundImage = (
	background: CustomThemeBackground
): background is AppBackgroundImage => background.type === 'image'
export const isAppBackgroundSvg = (
	background: CustomThemeBackground
): background is AppBackgroundSvg => background.type === 'svg'

export type ThemeId =
	| 'flatWhite'
	| 'minimalist'
	| 'rubyTuesday'
	| 'whimsy'
	//| 'playful'
	| 'babyBlue'
	| 'blueSteel'
	| 'braveNewWorld'
	//| 'brettWasHere'
	| 'darkMatter'
	| 'snow'
	| 'woodDark'
	| 'city'
	| 'midnight'

export type ThemeData = {
	id: ThemeId
	name: string
	theme: CustomTheme
	// @ts-deprecated
	muiTheme: Theme
}

export const babyBlueMuiTheme = babyBlue()
export const blueSteelMuiTheme = blueSteel()
export const braveNewWorldMuiTheme = braveNewWorld()
export const brettWasHereMuiTheme = brettWasHere()
export const darkMatterMuiTheme = darkMatter()
export const flatWhiteMuiTheme = flatWhite()
export const midnightMuiTheme = midnight()
export const minimalistMuiTheme = minimalist()
export const playfulMuiTheme = playful()
export const cityMuiTheme = city()
export const rubyTuesdayMuiTheme = rubyTuesday()
export const snowMuiTheme = snow()
export const whimsyMuiTheme = whimsy()
export const woodDarkMuiTheme = woodDark()

export const defaultTheme = createBaseTheme()
export const currentTheme = flatWhiteMuiTheme
export const backgroundTheme = flatWhiteMuiTheme
export const inverseTheme = darkMatterMuiTheme

export const themesIndex: Record<ThemeId, ThemeData> = {
	darkMatter: {
		id: 'darkMatter',
		muiTheme: darkMatterMuiTheme,
		name: 'Dark matter',
		theme: darkMatterTheme,
	},
	flatWhite: {
		id: 'flatWhite',
		muiTheme: flatWhiteMuiTheme,
		name: 'Flat white',
		theme: flatWhiteTheme,
	},
	minimalist: {
		id: 'minimalist',
		muiTheme: minimalistMuiTheme,
		name: 'Minimalist',
		theme: minimalistTheme,
	},
	midnight: {
		id: 'midnight',
		muiTheme: midnightMuiTheme,
		name: 'Midnight',
		theme: midnightTheme,
	},
	babyBlue: {
		id: 'babyBlue',
		muiTheme: babyBlueMuiTheme,
		name: 'Baby Blue',
		theme: babyBlueTheme,
	},
	blueSteel: {
		id: 'blueSteel',
		muiTheme: blueSteelMuiTheme,
		name: 'Blue Steel',
		theme: blueSteelTheme,
	},
	braveNewWorld: {
		id: 'braveNewWorld',
		muiTheme: braveNewWorldMuiTheme,
		name: 'Brave New World',
		theme: braveNewWorldTheme,
	},
	city: {
		id: 'city',
		muiTheme: cityMuiTheme,
		name: 'City',
		theme: cityTheme,
	},
	snow: {
		id: 'snow',
		muiTheme: snowMuiTheme,
		name: 'Snow',
		theme: snowTheme,
	},
	rubyTuesday: {
		id: 'rubyTuesday',
		muiTheme: rubyTuesdayMuiTheme,
		name: 'Ruby Tuesday',
		theme: rubyTuesdayTheme,
	},
	whimsy: {
		id: 'whimsy',
		muiTheme: whimsyMuiTheme,
		name: 'Whimsy',
		theme: whimsyTheme,
	},
	woodDark: {
		id: 'woodDark',
		muiTheme: woodDarkMuiTheme,
		name: 'Wood Dark',
		theme: woodDarkTheme,
	},
} as const

export const themesOrder: Array<keyof typeof themesIndex> = [
	'flatWhite',
	'rubyTuesday',
	//'playful',
	'babyBlue',
	'blueSteel',
	//'brettWasHere',
	'darkMatter',
	'braveNewWorld',
	'whimsy',
	'snow',
	'woodDark',
	'city',
	'midnight',
	'minimalist',
]
