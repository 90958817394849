import produce from 'immer'
import memoizeOne from 'memoize-one'
import moment, { Moment } from 'moment'
import {
	concat,
	filter,
	indexBy,
	map,
	mergeDeepWith,
	partition,
	prop,
	propOr,
	reduce,
	sortBy,
	uniq,
	without,
} from 'ramda'

import { TIME_FORMAT, userTaskSections } from '../constants'
import { SectionsResult } from '../store/api/baseApiAdapter'
import {
	ObjectIndex,
	Section,
	SectionId,
	SectionIndex,
	SectionSortType,
	Task,
} from '../types'
import { sortTasks, sortWithOrder } from './sort'

export const sectionIds: ObjectIndex<SectionId> = {
	NEXT_FEW_DAYS: 'nextFewDays',
	FUTURE: 'future',
	INACTIVE: 'inactive',
	PINNED: 'pinned',
	LAST_WEEK: 'lastWeek',
	NEEDS_ATTENTION: 'needsAttention',
	NOT_FOR_ME: 'notForMe',
	NOT_SCHEDULED: 'notScheduled',
	OVERDUE: 'overdue',
	PRIORITY: 'priority',
	THIS_WEEK: 'thisWeek',
	TODAY: 'today',
	UNREAD: 'unread',
	MISSED: 'upNext',
	//REPEAT: 'repeat',
}

export const createEmptySection = (sectionId: SectionId): Section => ({
	id: sectionId,
	dayMap: {},
	manualSortOrder: [],
	meta: { laterToday: [] },
	name: '',
	sortType: 'newest',
	subtasks: [],
	tasks: [],
	tasksTotalHours: 0,
	title: '',
})

export const createEmptySectionIndex = () =>
	reduce<SectionId, SectionIndex>(
		(acc, sectionId) => {
			acc[sectionId] = createEmptySection(sectionId)
			return acc
		},
		{} as SectionIndex,
		Object.values(sectionIds) as SectionId[]
	)

// This is the order that we want to display sections to the users.
export const sectionDisplayOrder = [
	sectionIds['TODAY'],
	sectionIds['PINNED'],
	sectionIds['INACTIVE'],
	sectionIds['NEXT_FEW_DAYS'],
	//sectionIds['LAST_WEEK'],
	sectionIds['MISSED'],
	//sectionIds['REPEAT'],
	//sectionIds['THIS_WEEK'],
	sectionIds['OVERDUE'],
	sectionIds['PRIORITY'],
	sectionIds['UNREAD'],
	sectionIds['NEEDS_ATTENTION'],
	sectionIds['FUTURE'],
	sectionIds['NOT_SCHEDULED'],
]

const createSectionMapFromTasks = (
	tasks: Task[],
	playerId: string,
	userId: string
): ObjectIndex<Task[]> => {
	const dateCache = getSectionIdsDateCache()
	return reduce<Task, ObjectIndex<Task[]>>(
		(acc, task) => {
			const sectionId = getSectionId(task, userId, playerId, dateCache)
			acc[sectionId] = acc[sectionId] || []
			acc[sectionId].push(task)
			return acc
		},
		{},
		tasks
	)
}

/* const rejectSubtasks = (
	tasks: Task[] = [],
	processedParents: ObjectIndex<boolean> = {}
) => {
	const taskMap = indexBy(prop('id'), tasks)
	return filter((task) => {
		const parent = task.parentId ? taskMap[task.parentId] : null

		// If a task's parent appears in a previous section, do not show it in
		// it's own section -- it will be listed as a subtask of that parent.
		// This assumes that we're iterating through sections using the section
		// display order.
		if (task.parentId && processedParents[task.parentId]) return false

		if (parent) {
			// Save this parent in a map for filtering out subtasks.
			processedParents[parent.id] = true

			// Put tasks deeper than the first level into the list.
			const grandParent = parent.parentId
				? taskMap[parent.parentId]
				: null
			return Boolean(grandParent)
		} else {
			// Put orphans into the list.
			return true
		}
	}, tasks)
} */

const partitionSubtasks = (
	tasks: Task[] = [],
	processedParents: ObjectIndex<boolean> = {}
) => {
	const taskMap = indexBy(prop('id'), tasks)
	return partition((task) => {
		const parent = task.parentId ? taskMap[task.parentId] : null

		// If a task's parent appears in a previous section, do not show it in
		// it's own section -- it will be listed as a subtask of that parent.
		// This assumes that we're iterating through sections using the section
		// display order.
		if (task.parentId && processedParents[task.parentId]) return false

		if (parent) {
			// Save this parent in a map for filtering out subtasks.
			processedParents[parent.id] = true

			// Put tasks deeper than the first level into the list.
			const grandParent = parent.parentId
				? taskMap[parent.parentId]
				: null
			return Boolean(grandParent)
		} else {
			// Put orphans into the list.
			return true
		}
	}, tasks)
}

const keyTasksByDay = (
	tasks: Pick<Task, 'id' | 'startDate'>[]
): ObjectIndex<string[]> =>
	reduce<Pick<Task, 'id' | 'startDate'>, ObjectIndex<string[]>>(
		(acc, task) => {
			if (task.startDate) {
				acc[task.startDate] = acc[task.startDate] || []
				acc[task.startDate].push(task.id)
			}
			return acc
		},
		{},
		tasks
	)

const matchLaterToday = (task: Task): boolean =>
	Boolean(
		task.startTime && moment(task.startTime, TIME_FORMAT).isAfter(moment())
	)

export const buildSectionData = (
	sections: SectionsResult,
	tasks: Task[],
	playerId: string,
	userId: string
): SectionsResult & { byId: SectionIndex; taskCount: number } => {
	const processedParents = {}
	const sectionMap = createSectionMapFromTasks(tasks, playerId, userId)

	const data = reduce<
		SectionId,
		SectionsResult & { byId: SectionIndex; taskCount: number }
	>(
		(acc, sectionId) => {
			const section = { ...sections.byId[sectionId] } as Section

			// Filter out subtasks because they will be added to each task from
			// within the task component.
			const [tasks, subtasks] = partitionSubtasks(
				sectionMap[sectionId],
				processedParents
			)

			const sortedTasks =
				section.sortType === 'manual'
					? sortWithOrder(tasks, section.manualSortOrder)
					: sortTasks(tasks, section.sortType)

			section.dayMap = keyTasksByDay(sortedTasks)
			section.meta = { laterToday: [] }
			section.subtasks = map(prop('id'), subtasks)
			section.tasks = map(prop('id'), sortedTasks)
			section.tasksTotalHours = Math.round(
				reduce<Pick<Task, 'hoursAllocated'>, number>(
					(acc, task) => acc + (task.hoursAllocated || 0),
					0,
					sortedTasks
				)
			)

			if (sectionId === userTaskSections.TODAY) {
				const laterTodayTasks = sortBy<Task>(
					propOr('', 'startTime'),
					filter(matchLaterToday, sortedTasks)
				)

				section.meta = {
					laterToday: map(prop('id'), laterTodayTasks),
				}
				section.tasks = map(prop('id'), sortedTasks)
			}

			acc.byId[sectionId] = section

			return acc
		},
		{
			displayOrder: sections?.displayOrder,
			byId: createEmptySectionIndex(),
			taskCount: 0,
		},
		sections?.displayOrder
	)

	if (Array.isArray(tasks)) {
		data.taskCount = tasks.length
	}

	return data
}

export const getSectionIdsDateCache = (
	today: string | number | Date | Moment = Date.now()
) => {
	const now = today ? moment(today) : moment()
	return {
		now,
		/*lastWeek: moment()
			.subtract(8, 'days')
			.endOf('day'),*/
		nextWeek: now.clone().add(1, 'week').startOf('day'),
	}
}

export const getSectionId = memoizeOne(
	(
		task: Task,
		userId: string,
		playerId: string,
		{ now, /* lastWeek, */ nextWeek } = getSectionIdsDateCache()
	): SectionId => {
		const isSomeDayStartDate = task.startDate === 'someday'
		const dueDate = moment(task.dueDate)
		const startDate = moment(task.startDate)

		const isAssignedTo =
			userId && task.assigneeId && task.assigneeId === userId
		const isInactive =
			task.statusCode === 'done' ||
			task.statusCode === 'deleted' ||
			task.statusCode === 'archived'
		const isArchived = task.statusCode === 'archived'
		const isInPast = startDate.isBefore(now, 'day')
		// const isLastWeek = isInPast && startDate.isAfter(lastWeek)
		const isNeedingAttention =
			userId === playerId
				? false
				: isInPast && !isAssignedTo && task.ownerId === playerId

		const isNotForMe = !isAssignedTo && !isNeedingAttention
		const isOverdue = dueDate.isBefore(nextWeek, 'day')
		const isUrgentAndImportant =
			task.urgency >= 7 || (task.importance >= 7 && isInPast)

		// This is the logical bucketing order that we want to assign tasks to.
		if (isNotForMe) return sectionIds['NOT_FOR_ME']
		if (isInactive && !isArchived) return sectionIds['INACTIVE']
		if (isNeedingAttention) return sectionIds['NEEDS_ATTENTION']
		if (startDate.isSame(now, 'day') && !isInactive)
			return sectionIds['TODAY']
		// if ( isLastWeek && !isInactive ) return sectionIds['LAST_WEEK']
		// if ( task.repeatType ) {
		// 	const taskRepeatDate = dateHelper.getTaskRepeatDate( task ).local()
		// 	if ( taskRepeatDate.isSame( now, 'day' ) && taskRepeatDate.isBefore( now ) ) {
		// 		return sectionIds['REPEAT']
		// 	}
		// }
		//if ( startDate.isSame( now, 'isoWeek' ) && !isInactive ) return sectionIds['THIS_WEEK']
		if (
			(startDate.isAfter(now.clone().add(5, 'day'), 'day') ||
				isSomeDayStartDate) &&
			!isInactive
		)
			return sectionIds['FUTURE']
		if (startDate.isAfter(now, 'day') && !isInactive)
			return sectionIds['NEXT_FEW_DAYS']
		if (task.isPinned && !isInactive) return sectionIds['PINNED']
		if (isInPast && !isInactive) return sectionIds['MISSED']
		if (isOverdue && !isInactive) return sectionIds['OVERDUE']
		if (isUrgentAndImportant && !isInactive) return sectionIds['PRIORITY']
		if (task.isUnread && isInPast && !isInactive)
			return sectionIds['UNREAD']
		if (!startDate.isValid() && !isInactive)
			return sectionIds['NOT_SCHEDULED']

		return sectionIds['NOT_FOR_ME']
	}
)

export const addTaskToSection = (section: Section, task: Task) =>
	mergeDeepWith((a, b) => uniq(concat(a, b)), section, {
		tasks: [task.id],
		manualSortOrder: [task.id],
		dayMap: task.startDate ? { [task.startDate]: [task.id] } : {},
	})

export const removeTaskFromSection = (
	section: Section,
	taskId: string
): Section => ({
	...section,
	tasks: section.tasks.filter((id) => id !== taskId),
	manualSortOrder: section.manualSortOrder.filter((id) => id !== taskId),
	dayMap: produce(section.dayMap, (draft) => {
		Object.keys(draft).forEach((key) => {
			if (draft[key].includes(taskId)) {
				draft[key] = draft[key].filter((id) => id !== taskId)
			}
		})
	}),
})

export const buildSectionStructure = (
	id: SectionId,
	title: string,
	sortType: SectionSortType
): Section => ({
	id,
	title,
	sortType,
	dayMap: {},
	manualSortOrder: [],
	meta: { laterToday: [] },
	subtasks: [],
	tasks: [],
	tasksTotalHours: 0,
	name: title,
})

// Build the sections data structure to be used in the app.
export const sectionData = {
	[sectionIds['NEXT_FEW_DAYS']]: buildSectionStructure(
		sectionIds['NEXT_FEW_DAYS'],
		'Next Few Days',
		'newest'
	),
	[sectionIds['FUTURE']]: buildSectionStructure(
		sectionIds['FUTURE'],
		'Future',
		'newest'
	),
	[sectionIds['INACTIVE']]: buildSectionStructure(
		sectionIds['INACTIVE'],
		'Completed',
		'oldest'
	),
	[sectionIds['LAST_WEEK']]: buildSectionStructure(
		sectionIds['LAST_WEEK'],
		'Missed in the last week',
		'manual'
	),
	[sectionIds['MISSED']]: buildSectionStructure(
		sectionIds['MISSED'],
		'Missed',
		'newest'
	),
	[sectionIds['NEEDS_ATTENTION']]: buildSectionStructure(
		sectionIds['NEEDS_ATTENTION'],
		'Tasks that need following up',
		'oldest'
	),
	[sectionIds['NOT_FOR_ME']]: buildSectionStructure(
		sectionIds['NOT_FOR_ME'],
		'Not for me',
		'priority'
	),
	[sectionIds['NOT_SCHEDULED']]: buildSectionStructure(
		sectionIds['NOT_SCHEDULED'],
		'Not Scheduled',
		'priority'
	),
	[sectionIds['OVERDUE']]: buildSectionStructure(
		sectionIds['OVERDUE'],
		'Overdue or becoming due',
		'manual'
	),
	[sectionIds['PINNED']]: buildSectionStructure(
		sectionIds['PINNED'],
		'Pinned',
		'priority'
	),
	[sectionIds['PRIORITY']]: buildSectionStructure(
		sectionIds['PRIORITY'],
		'Urgent or important',
		'priority'
	),
	[sectionIds['THIS_WEEK']]: buildSectionStructure(
		sectionIds['THIS_WEEK'],
		'This week',
		'newest'
	),
	[sectionIds['TODAY']]: buildSectionStructure(
		sectionIds['TODAY'],
		'Today',
		'priority'
	),
	[sectionIds['UNREAD']]: buildSectionStructure(
		sectionIds['UNREAD'],
		'Unread',
		'priority'
	),
	/* [sectionIds.REPEAT]: buildSectionStructure(
		sectionIds.REPEAT,
		'Repeating',
		'priority'
	), */
}
