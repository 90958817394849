import Color from 'color'
import Rand from 'rand-seed'
import { filter, flatMap, pipe } from 'remeda'

export const MATERIAL_COLOR_PALETTE: string[] = [
	'#ef5350',
	'#f44336',
	'#e53935',
	'#d32f2f',
	'#c62828',
	'#b71c1c',
	'#ff5252',
	'#ff1744',
	'#d50000',

	'#ec407a',
	'#e91e63',
	'#d81b60',
	'#c2185b',
	'#ad1457',
	'#880e4f',
	'#ff4081',
	'#f50057',
	'#c51162',

	'#ba68c8',
	'#ab47bc',
	'#9c27b0',
	'#8e24aa',
	'#7b1fa2',
	'#6a1b9a',
	'#4a148c',
	'#e040fb',
	'#d500f9',
	'#aa00ff',

	'#9575cd',
	'#7e57c2',
	'#673ab7',
	'#5e35b1',
	'#512da8',
	'#4527a0',
	'#311b92',
	'#7c4dff',
	'#651fff',
	'#6200ea',

	'#7986cb',
	'#5c6bc0',
	'#3f51b5',
	'#3949ab',
	'#303f9f',
	'#283593',
	'#1a237e',
	'#536dfe',
	'#3d5afe',
	'#304ffe',

	'#1e88e5',
	'#1976d2',
	'#1565c0',
	'#0d47a1',
	'#448aff',
	'#2979ff',
	'#2962ff',

	'#0288d1',
	'#0277bd',
	'#01579b',
	'#0091ea',

	'#0097a7',
	'#00838f',
	'#006064',

	'#009688',
	'#00897b',
	'#00796b',
	'#00695c',
	'#004d40',

	'#43a047',
	'#388e3c',
	'#2e7d32',
	'#1b5e20',

	'#558b2f',
	'#33691e',

	'#827717',

	'#e65100',

	'#f4511e',
	'#e64a19',
	'#d84315',
	'#bf360c',
	'#ff3d00',
	'#dd2c00',

	'#a1887f',
	'#8d6e63',
	'#795548',
	'#6d4c41',
	'#5d4037',
	'#4e342e',
	'#3e2723',

	'#757575',
	'#616161',
	'#424242',
	'#212121',

	'#78909c',
	'#607d8b',
	'#546e7a',
	'#455a64',
	'#37474f',
	'#263238',
]

export const SOFT_COLOR_PALETTE: string[] = [
	'#F7A2A1',
	'#EDA9B1',
	'#E3B0C0',
	'#D9B8D0',
	'#CEBFE0',
	'#C4C6EF',
	'#BACDFF',

	'#4AC49E',
	'#4BB7A7',
	'#4CAAB0',
	'#4E9DB9',
	'#4F90C1',
	'#5083CA',
	'#5176D3',

	'#ECE076',
	'#ECD176',
	'#ECC176',
	'#EDB276',
	'#EDA376',
	'#ED9376',
	'#ED8476',

	'#ECAF76',
	'#E7A377',
	'#E39778',
	'#DE8C7A',
	'#D9807B',
	'#D5747C',
	'#D0687D',
]

const allPalettes = [MATERIAL_COLOR_PALETTE, SOFT_COLOR_PALETTE]

const MERGED_COLOR_PALETTES = pipe(
	allPalettes,
	flatMap((palette) => palette),
	filter((color) => Color(color).contrast(Color('#fff')) > 3)
)

export const getSeededColor = (
	seed: string,
	palette = MERGED_COLOR_PALETTES
) => {
	const rand = new Rand(seed)
	const randomIndex = Math.round(rand.next() * (palette.length - 1))
	const randomColor = Color(palette[randomIndex])
	return {
		color: palette[randomIndex],
		whiteContrast: randomColor.contrast(Color('#fff')),
		blackContrast: randomColor.contrast(Color('#000')),
	}
}
