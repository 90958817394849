/*
 * Workflow factory functions for mutating state
 */

import { QueryClient } from 'react-query'

import { Workflow, WorkflowIndex } from '../../../types'
import { ApiAdapter } from '../../api/baseApiAdapter'
import { workflowKeys } from '../../queries'
import { AppState } from '../../store-types'
import { createBaseQueryCacheMutation } from '../baseCreate'

export const addWorkflowMutation = async (
	state: AppState,
	newWorkflow: Workflow
) => {
	const { apiAdapter, queryClient } = state
	const result = createAddWorkflowToApi(apiAdapter)(newWorkflow)
	createAddWorkflowToQueryCache(queryClient)(newWorkflow)
	return result
}

export const createAddWorkflowToApi =
	(apiAdapter: ApiAdapter) => async (newWorkflow: Workflow) => {
		return apiAdapter.workflows.add(newWorkflow) as Promise<Workflow>
	}

export const createAddWorkflowToQueryCache =
	(queryClient: QueryClient) => async (newWorkflow: Workflow) => {
		const standardMutation = createBaseQueryCacheMutation(queryClient)
		return standardMutation<WorkflowIndex>(
			workflowKeys.list(),
			(prevIndex) => ({ ...prevIndex, [newWorkflow.id]: newWorkflow })
		)
	}
