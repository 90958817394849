import bytesToString from '../../helpers/bytesToString'
import {
	FileData,
	TaskActivity,
	TaskActivityFiles,
	TaskActivityFilesSchema,
} from '../task-activity-schemas'

export const parseTaskFilesToString = (activity: TaskActivityFiles): string => {
	const meta = activity.meta

	if (activity.removedFile) {
		return `${meta.user.nickname} deleted <strong>${
			activity.removedFile.filename
		}</strong> (${bytesToString(activity.removedFile.totalBytes)})`
	}

	// For adding files, always use the files array.
	const fileLine = (file: FileData) =>
		`<strong>${file.filename}</strong> (${bytesToString(file.totalBytes)})`

	return `${meta.user.nickname} uploaded:<br/>${activity.files
		.map(fileLine)
		.join('<br/>')}`
}

export const validateTaskFiles = (taskActivity: TaskActivity) =>
	TaskActivityFilesSchema.safeParse(taskActivity)
