import { isBefore } from 'date-fns'
import { RawDraftContentState } from 'draft-js'
import { produce } from 'immer'
import { indexOf, mergeDeepRight } from 'ramda'
import { InfiniteData, QueryClient, QueryObserverResult } from 'react-query'
import { isDeepEqual, omit, pick, uniq } from 'remeda'
import { EMPTY, merge as rxjsMerge, Subscription } from 'rxjs'
import {
	combineLatestWith,
	filter as rxjsFilter,
	startWith,
	switchMap,
	tap,
} from 'rxjs/operators'
import { Socket } from 'socket.io-client'

import { emailToHash } from '@tyto/helpers/gravatar'

import { events } from '../../constants'
import { isInactive } from '../../helpers/taskStatus'
import { createSocketObservable } from '../../socketUtils'
import {
	parseTaskLogs,
	TaskActivity,
	TaskActivityComment,
	WrappedTaskActivityV2,
} from '../../task-activity'
import {
	createCommentActivity,
	createTaskActivityMove,
	createWrappedActivity,
} from '../../task-activity/create-activity'
import {
	createNewTaskActivity,
	createTaskActivity,
} from '../../task-activity/createTaskActivity'
import {
	ReactionButton,
	Slice,
	Task,
	TaskActivityType,
	User,
} from '../../types'
import { ApiListResult, ApiTaskListResult, TaskActivityParams } from '../api'
import { createAddTaskToQueryCache } from '../mutations'
import {
	addTaskSubject,
	createSubtaskObservable,
	moveTaskSubject,
	updateTaskSubject,
} from '../observables'
import { taskKeys, userKeys, workflowKeys } from '../queries'
import { MutatedAppState } from '../store-types'
import {
	createAddReactionMutation,
	createAddTaskActivityMutation,
	createAddTaskActivityToQueryCache,
	createRemoveReactionMutation,
	taskActivityKeys,
} from '../task-activity'
import {
	FileRequestArgs,
	removeTaskFileMutation,
	UpdateFileArgs,
	updateTaskFileMutation,
} from '../taskFile/taskFileMutation'
import { createZustandObservable } from '../utils/createZustandObservable'
import { mapExistingTasks } from '../utils/mapExistingTasks'
import { filterAndSortSubtasksToIds } from '../utils/subtasks'
import {
	addTaskReminderMutation,
	removeTaskReminderMutation,
} from './task-edit-mutations'

const createTaskActivitySocket = (socket: Socket) =>
	createSocketObservable<WrappedTaskActivityV2>(socket, events.TASK_ACTIVITY)

export interface TaskEditSlice extends Slice {
	taskId: string | null
	activity: {
		filter: TaskActivityType | 'all'
		addActivity: (
			taskId: string,
			comment: TaskActivityComment['comment'],
			draftModel: RawDraftContentState,
			replyTo?: TaskActivityComment['replyTo']
		) => void
		addReaction: (reaction: ReactionButton, activity: TaskActivity) => void
		removeReaction: (
			reaction: ReactionButton,
			activity: TaskActivity
		) => void
		setFilter: (filter: TaskActivityType | 'all') => void
	}
	subtasks: {
		data: Task['id'][]
		isLoading: boolean
	}
	setTaskId: (taskId: string) => void
	// Subtasks
	addSubtask: (task: Partial<Task> & Pick<Task, 'id' | 'title'>) => void
	removeSubtask: (taskId: string) => void
	// Files
	updateFile: (args: UpdateFileArgs) => void
	removeFile: (args: FileRequestArgs) => void
	// Reminders
	addReminder: (taskId: string, date: Date) => void
	removeReminder: (taskId: string) => void
}

const addSubtaskToQueryCache = (
	queryClient: QueryClient,
	parentId: string | null,
	taskId: string
) => {
	if (!parentId) {
		return
	}
	queryClient.setQueryData<ApiTaskListResult | undefined>(
		taskKeys.list({ parentId }),
		produce((draft) => {
			if (!draft || draft.items.includes(taskId)) {
				return
			}
			draft.items.push(taskId)
			draft.count = draft.items.length
		})
	)
}

const removeSubtaskFromQueryCache = (
	queryClient: QueryClient,
	parentId: string | null,
	taskId: string
) => {
	if (!parentId) {
		return
	}
	queryClient.setQueryData(
		taskKeys.list({ parentId }),
		produce((draft) => {
			if (!draft) {
				return
			}
			const index = draft.items.indexOf(taskId)
			if (index > -1) {
				draft.items.splice(index, 1)
				draft.count = draft.items.length
			}
		})
	)
}

export const createTaskEditSlice: MutatedAppState<TaskEditSlice> = (
	set,
	get,
	api
) => ({
	taskId: null,
	activity: {
		filter: 'all',
		addActivity: (taskId, comment, draftModel, replyId) => {
			if (!comment || !taskId) return

			const playerId = get().player.id
			if (!playerId) return

			const { apiAdapter, queryClient } = get()
			const addTaskActivityMutation = createAddTaskActivityMutation(
				apiAdapter,
				queryClient
			)

			const activity = createCommentActivity(
				taskId,
				playerId,
				comment,
				draftModel,
				replyId
			)

			// Add to local state
			addTaskActivityMutation(activity)
		},
		addReaction: (reaction, activity) => {
			const { apiAdapter, queryClient, player } = get()

			if (!player.id) {
				return
			}

			const addReactionMutation = createAddReactionMutation(
				apiAdapter,
				queryClient
			)

			addReactionMutation({ ...reaction, userId: player.id }, activity)
		},
		removeReaction: (reaction, activity) => {
			const { apiAdapter, queryClient, player } = get()

			if (!player.id) {
				return
			}

			const removeReactionMutation = createRemoveReactionMutation(
				apiAdapter,
				queryClient
			)

			removeReactionMutation({ ...reaction, userId: player.id }, activity)
		},
		setFilter: (filter) => {
			set((draft) => {
				draft.taskEdit.activity.filter = filter
			})
		},
	},
	subtasks: {
		data: [],
		isLoading: false,
	},
	init: () => {
		const { apiAdapter, queryClient, player, socket } = get()

		// Setup sockets and other side effects
		const addTaskActivityToQueryCache = createAddTaskActivityToQueryCache(
			apiAdapter,
			queryClient
		)
		const addTaskToQueryCache = createAddTaskToQueryCache(queryClient)

		// Actions triggered by a user interaction
		const updateTaskSubscription = updateTaskSubject
			.pipe(rxjsFilter(({ taskId }) => taskId === get().taskEdit.taskId))
			.subscribe(async ({ changes, oldTask }) => {
				if (!oldTask) {
					return
				}

				const { queryClient } = get()
				const playerId = get().player.id
				const extra = {}

				if (playerId) {
					extra.user = queryClient.getQueryData(
						userKeys.detail(playerId)
					)
				}

				// TODO: use a different mergeDeep function with better typing
				// so that we don't have to cast to Task
				const newTask = mergeDeepRight(oldTask, changes) as Task

				if (newTask.workflowData?.id) {
					const workflowsById = queryClient.getQueryData(
						workflowKeys.list()
					)
					extra.workflow = workflowsById[newTask.workflowData.id]
				}

				const activity = createTaskActivity(
					get().player.id || '',
					oldTask,
					newTask,
					extra
				)
				if (activity) {
					addTaskActivityToQueryCache(activity)
				}

				set((draft) => {
					const currentTaskId = draft.taskEdit.taskId

					// Handle remove task from subtasks
					if (
						currentTaskId === changes.parentId &&
						isInactive(changes.statusCode)
					) {
						const index = indexOf(
							changes.id,
							draft.taskEdit.subtasks.data
						)
						if (index > -1) {
							draft.taskEdit.subtasks.data.splice(index, 1)
						}
					}
				})
			})

		moveTaskSubject.subscribe(({ destination, source, taskId }) => {
			set((draft) => {
				const currentTaskId = draft.taskEdit.taskId

				const task = queryClient.getQueryData<Task>(
					taskKeys.detail(taskId)
				)

				if (task && source.parentId) {
					// Handle add task to subtasks
					if (currentTaskId === destination.parentId) {
						if (destination.parentId !== source.parentId) {
							addSubtaskToQueryCache(
								queryClient,
								currentTaskId,
								task.id
							)
						}
					}
					// Handle remove task from subtasks
					else if (currentTaskId === source.parentId) {
						removeSubtaskFromQueryCache(
							queryClient,
							currentTaskId,
							task.id
						)
					}

					// Add task activity for move
					const playerId = get().player.id
					if (!playerId) {
						throw new Error('Expected playerId to by truthy')
					}
					const user = queryClient.getQueryData<User>(
						userKeys.detail(playerId)
					)
					if (!user) {
						throw new Error('Expected user to by truthy')
					}
					const srcParent = queryClient.getQueryData<Task>(
						taskKeys.detail(source.parentId)
					)
					if (!srcParent) {
						throw new Error('Expected srcParent to by truthy')
					}

					const activity = createTaskActivityMove(
						{
							...pick(user, ['id', 'name', 'nickname']),
							gravatar: emailToHash(user.email),
						},
						{
							id: task.id,
							parentId: source.parentId,
							parents: [
								...srcParent.parents,
								{ id: srcParent.id, title: srcParent.title },
							],
						},
						pick(task, ['id', 'parentId', 'parents'])
					)
					const wrappedActivity = createWrappedActivity(
						playerId,
						task.id,
						parseTaskLogs(activity),
						activity
					)
					addTaskActivityToQueryCache(wrappedActivity)
				}
			})
		})

		// Actions triggered by the API
		// TODO: Listen for task activity sockets for current task

		let taskActivitySocketSubscription: Subscription
		let taskAddSocketSubscription: Subscription
		let taskMoveSocketSubscription: Subscription
		let taskUpdateSocketSubscription: Subscription
		if (socket) {
			const taskActivity = createTaskActivitySocket(socket)
			taskActivitySocketSubscription = taskActivity.subscribe(
				(activity) => {
					const queryKey = taskActivityKeys.lists(activity.taskId)
					const queries =
						queryClient.getQueriesData<
							InfiniteData<ApiListResult<WrappedTaskActivityV2>>
						>(queryKey)

					queries.forEach(([key, data]) => {
						// Filter out queries that don't match activity type
						const params = key[5] as TaskActivityParams
						if (
							params?.type &&
							params.type !== 'all' &&
							params.type !== activity.data.type
						) {
							return
						}

						try {
							const lastActivity = data.pages[0].items[0]
							const omitFields = omit<
								WrappedTaskActivityV2,
								keyof WrappedTaskActivityV2
							>(['id', 'dateCreated'])
							// If the activity from the socket matches the last activity in
							// memory, then we want to abort and not trigger an update.
							if (
								isDeepEqual(
									omitFields(activity),
									omitFields(lastActivity)
								)
							) {
								return
							}
						} catch (err) {
							console.log('data', data)
							throw err
						}

						queryClient.invalidateQueries(queryKey)
					})
				}
			)
		}

		// Store changes
		const addTaskSubscription = addTaskSubject.subscribe(
			({ position, task }) => {
				const parentId = get().taskEdit.taskId
				if (task.parentId === parentId) {
					addTaskToQueryCache(task, position)
					set((draft) => {
						draft.taskEdit.subtasks.data.push(task.id)
					})
					// Run at end of event loop, so that the query cache has
					// time to update before the update subtasks runs
					setTimeout(() => {
						addSubtaskToQueryCache(queryClient, parentId, task.id)
					})
				}

				if (player.id) {
					const activity = createNewTaskActivity(
						'web',
						player.id,
						task.id
					)
					addTaskActivityToQueryCache(activity)
				}
			}
		)

		const taskId$ = createZustandObservable(
			api,
			(state) => state.taskEdit.taskId
		)
		const activityFilter$ = createZustandObservable(
			api,
			(state) => state.taskEdit.activity.filter
		)

		const updateSubtasks = (
			results:
				| [
						QueryObserverResult<Task>,
						QueryObserverResult<ApiListResult<string>>,
				  ]
				| null
		) => {
			const taskResults = results?.[0]
			const subtasksResults = results?.[1]
			if (!taskResults?.data || !subtasksResults?.data?.items) {
				set((draft) => {
					draft.taskEdit.subtasks.data = []
					draft.taskEdit.subtasks.isLoading = false
				})
				return
			}

			const childSortOrder = taskResults.data?.childSortOrder || []
			const subtasks = mapExistingTasks(
				queryClient,
				subtasksResults.data.items
			)
			const subtaskIds = filterAndSortSubtasksToIds(
				childSortOrder,
				subtasks
			)

			set((draft) => {
				draft.taskEdit.subtasks.data = uniq(subtaskIds)
				draft.taskEdit.subtasks.isLoading = false
			})
		}

		// Listen for taskId change
		// When change happens, teardown subscriptions and create new ones:
		// - subscribe to activity changes
		// - subscribe to subtask changes
		// - subscribe to file changes
		const taskIdSubscription = taskId$
			.pipe(
				combineLatestWith(
					activityFilter$.pipe(
						startWith({ state: 'all' as TaskActivityType | 'all' })
					)
				),
				tap(() => {
					set((draft) => {
						draft.taskEdit.subtasks.isLoading = true
					})
				}),
				switchMap(([{ state: taskId }]) => {
					if (!taskId) {
						// Clear out state when taskId is set to null
						updateSubtasks(null)
						return EMPTY
					}
					return rxjsMerge(
						createSubtaskObservable(
							apiAdapter,
							queryClient,
							taskId
						).pipe(tap(updateSubtasks))
						// TODO: fetch files
					)
				})
			)
			.subscribe()

		return () => {
			taskActivitySocketSubscription &&
				taskActivitySocketSubscription.unsubscribe()
			taskAddSocketSubscription && taskAddSocketSubscription.unsubscribe()
			taskMoveSocketSubscription &&
				taskMoveSocketSubscription.unsubscribe()
			taskUpdateSocketSubscription &&
				taskUpdateSocketSubscription.unsubscribe()

			addTaskSubscription.unsubscribe()
			taskIdSubscription.unsubscribe()
			updateTaskSubscription.unsubscribe()
		}
	},
	setTaskId: (taskId: string) => {
		set((draft) => {
			draft.taskEdit.taskId = taskId
		})
	},
	addSubtask: (task) => {
		console.log('addSubtask', task)
	},
	removeSubtask: (taskId) => {
		console.log('removeSubtask', taskId)
	},
	addReminder: (taskId, date) => {
		if (isBefore(date, new Date())) {
			get().notifications.next({
				type: 'snackbar',
				message: 'Reminder date must be in the future',
			})
			return
		}
		addTaskReminderMutation(get(), taskId, date)
	},
	removeReminder: (taskId) => {
		removeTaskReminderMutation(get(), taskId)
	},
	updateFile: (args) => {
		const { apiAdapter, queryClient, player } = get()

		if (!player.id) {
			return
		}

		const update = updateTaskFileMutation(apiAdapter, queryClient)

		update(args)
	},
	removeFile: (args) => {
		const { apiAdapter, queryClient, player } = get()

		if (!player.id) {
			return
		}

		const remove = removeTaskFileMutation(apiAdapter, queryClient)
		remove(args)
	},
})
