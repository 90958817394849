import { useQuery } from 'react-query'

import { Container, Grid, Typography } from '@mui/material'

import { useStore } from '@tyto/dna/store'

import ActiveUsersMeta from './ActiveUsersMeta'
import ActiveUsersTable from './ActiveUsersTable'

/* eslint-disable-next-line */
export interface ActiveUsersPageProps {}

export interface LatestUser {
	browser: string
	city: string
	dateCreated: string
	email: string
	lastActivityDate: string
	name: string
	organisationName: string
	score: number
}

const useLatestUsers = () => {
	return useQuery<LatestUser[]>(['users', 'list'], async () => {
		const apiInstance = useStore.getState().apiAdapter.apiInstance
		return apiInstance.get('/ops/latestUsers').then((res) => res.data)
	})
}

export function ActiveUsersPage(props: ActiveUsersPageProps) {
	const { data: users = [], isLoading } = useLatestUsers()

	return (
		<Container
			maxWidth={'xl'}
			sx={{
				display: 'flex',
				flex: '1 1 0',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			<Typography variant={'h5'} sx={{ flex: '0 0 0', mt: 2 }}>
				{users.length} Active Users
			</Typography>
			<Grid
				container
				direction={'column'}
				wrap={'nowrap'}
				sx={{ flex: '1 1 0' }}
			>
				<Grid item>
					<ActiveUsersMeta users={users} />
				</Grid>
				<Grid item xs zeroMinWidth>
					{isLoading ? (
						<div>Loading...</div>
					) : users ? (
						<ActiveUsersTable users={users} />
					) : (
						<div>No users found</div>
					)}
				</Grid>
			</Grid>
		</Container>
	)
}

export default ActiveUsersPage
