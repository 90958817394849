import { useEffect } from 'react'

import { useStore } from '@tyto/dna/store'

import AuthorizedApp from './AuthorizedApp'
import { getToken } from './login'
import UnauthorizedApp from './UnauthorizedApp'

export const Root = () => {
	const authToken = useStore((state) => state.player.authToken)

	useEffect(() => {
		const token = getToken()
		if (token && !authToken) {
			useStore.getState().player.provideToken(token)
		}
	}, [authToken])

	return authToken ? <AuthorizedApp /> : <UnauthorizedApp />
}

export default Root
