import { indexBy, prop } from 'ramda'
import { QueryFunctionContext } from 'react-query'

import { Workflow, WorkflowIndex } from '../../types'
import { ApiAdapter, WorkflowListParams } from '../api'

interface ListParams {
	page?: number
	pageSize?: number
}

export const workflowKeys = {
	all: ['workflows'] as const,
	lists: () => [...workflowKeys.all, 'list'] as const,
	list: (params: ListParams = {}) =>
		[...workflowKeys.lists(), params] as const,
	details: () => [...workflowKeys.all, 'detail'] as const,
	detail: (workflowId: string) =>
		[...workflowKeys.details(), workflowId] as const,
}

export const createWorkflow =
	(apiAdapter: ApiAdapter, workflow: Workflow) =>
	async (): Promise<Workflow | null> =>
		apiAdapter.workflows.add(workflow)

export const deleteWorkflow =
	(apiAdapter: ApiAdapter, workflowId: string) =>
	async (): Promise<Workflow | null> =>
		apiAdapter.workflows.remove(workflowId)

export const fetchWorkflows =
	(apiAdapter: ApiAdapter, params?: WorkflowListParams) =>
	async ({ signal }: QueryFunctionContext): Promise<WorkflowIndex> => {
		const { items } = await apiAdapter.workflows.getList(params, { signal })

		// TODO: this could be better...
		try {
			// TODO: Not passing typescript rules
			//const workflows = workflowListSchema.validateSync(items)
			return indexBy(prop('id'), items)
		} catch (err) {
			// Do nothing
		}

		return {}
	}

export const updateWorkflow =
	(apiAdapter: ApiAdapter, workflowId: string, changes: Partial<Workflow>) =>
	async (): Promise<Workflow | null> =>
		apiAdapter.workflows.update(workflowId, changes)
