import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { Box, Paper, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

import { useStore } from '@tyto/dna/store'

const AwsS3ThumbnailGenerator = () => {
	const apiAdapter = useStore((state) => state.apiAdapter)
	// TODO: connect pagination to data grid
	const { data: { data: files = [] } = {} } = useQuery(
		['invalidFileThumbnails', 'list'],
		async () =>
			apiAdapter.apiInstance
				.get('/ops/invalidFileThumbnails')
				.then((res) => res.data)
	)

	const columns: GridColDef[] = useMemo(
		() => [
			{
				field: 'filename',
				headerName: 'Filename',
				width: 240,
			},
			{
				field: 'contentType',
				headerName: 'Content Type',
				width: 160,
			},
			{
				field: 'type',
				headerName: 'Type',
				width: 40,
			},
		],
		[]
	)

	return (
		<Box>
			<Typography>Files missing thumbnails</Typography>
			<Paper sx={{ display: 'flex', flex: '1 1 0', overflow: 'auto' }}>
				<DataGrid rows={files} columns={columns} />
			</Paper>
		</Box>
	)
}

export default AwsS3ThumbnailGenerator
