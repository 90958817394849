import { alpha } from '@mui/material/styles'

import { CustomTheme } from '.'
import { createWallpaperTheme } from './default'
import { defaultPalette } from './palettes'
import { createPaletteColor, createThemeGlow } from './theme-utils'
import cityBackground from './wallpapers/background-city.jpg'

/* Glow shadows
	boxShadow: inset 0 0 0.5em 0 var(--color-city-lights), 0 0 0.5em 0 var(--color-city-lights)
	textShadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px var(--color-city-lights), 0 0 35px var(--color-city-lights), 0 0 40px var(--color-city-lights), 0 0 50px var(--color-city-lights), 0 0 75px var(--color-city-lights)
*/

export const cityTheme: CustomTheme = {
	name: 'city',
	appBackground: { type: 'image', url: cityBackground },
	palette: {
		...defaultPalette,
		primary: '#406c97',
		background: '#75a5bb',
		surface: 'rgb(255, 255, 255)',
		sidebar: 'rgba(0, 0, 0, 0.1)',
		onSidebar: '#fff',
	},
}

const createMuiTheme = () => {
	return createWallpaperTheme(
		{
			name: 'city',
			appBackgroundImage: `url("${cityBackground}")`,
			palette: {
				mode: 'light',
				primary: createPaletteColor(cityTheme.palette.primary),
				// translucent: {
				// 	light: lighten(sidebarColor, 0.2),
				// 	main: sidebarColor,
				// 	dark: darken(sidebarColor, 0.2),
				// 	contrastText: '#fff',
				// },
				background: {
					default: cityTheme.palette.background,
					paper: cityTheme.palette.surface,
					sidebar: cityTheme.palette.sidebar,
				},
			},
			shadows: createThemeGlow('#555'),
			components: {
				// MuiButton: {
				// 	styleOverrides: {
				// 		root: {
				// 			[`&.MuiButton-containedBrand`]: {
				// 				// background: 'transparent',
				// 				// border: `3px solid #f72368`,
				// 				boxShadow: `inset 0 0 0.75em 0 #f72368, 0 0 0.75em 0 #f72368`,

				// 				// '&::before': {
				// 				// 	backgroundColor: '#f72368',
				// 				// 	content: '""',
				// 				// 	position: 'absolute',
				// 				// 	top: '100%',
				// 				// 	left: 0,
				// 				// 	pointerEvents: 'none',
				// 				// 	width: '100%',
				// 				// 	height: '100%',

				// 				// 	transform:
				// 				// 		'perspective(3em) rotateX(50deg) scale(1, 0.25)',
				// 				// 	filter: 'blur(1em)',
				// 				// 	opacity: 0.7,
				// 				// },
				// 			},
				// 		},
				// 	},
				// },
				MuiDialog: {
					styleOverrides: {
						paper: {
							//backgroundColor: 'rgba(255, 255, 255, 0.9)',
						},
					},
				},
				MuiPaper: {
					styleOverrides: {
						root: {
							//backdropFilter: 'blur(12px)',
						},
					},
				},
				TyTabBarItem: {
					styleOverrides: {
						selected: {
							backgroundColor: alpha(
								cityTheme.palette.sidebar,
								0.3
							),
						},
					},
				},
			},
		},
		{
			palette: {
				mode: 'dark',
				background: {
					paper: 'rgba(0, 0, 0, 0.25)',
				},
			},
		}
	)
}

export default createMuiTheme
