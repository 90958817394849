import {
	addDays,
	addMinutes,
	addMonths,
	format,
	getDaysInMonth,
	startOfDay,
	startOfISOWeek,
	startOfMonth,
	startOfYear,
} from 'date-fns'
import { RRule } from 'rrule'

import { RepeatMenuOption, RepeatMode, RruleOption } from './repeatTypes'

const dayRegex = /^\d{1,2}$/i
const monthRegex =
	/^\b(jan|feb|mar|apr|may|jun|jul|aug|sep|nov|dec)[a-zA-Z]*\b/i
const repeatDayRegex = /^every day at ?(.*)$/i
const repeatWeekdayRegex = /^every weekday at ?(.*)$/i
const repeatWeekRegex = /^every week on ?([^ ]*) ?(?:at? ?(.*?))?$/i
const repeatWeekMultipleRegex = /^every week on ?(.*) ?(?:at? ?(.*?))?$/i
const repeatMonthRegex =
	/^every month on(?: ?t?h?e?)? ?(.*?) ?(?: at ?(.*?))?$/i
const repeatYearRegex = /^every year on ?(.*?) ?(?: at ?(.*?))?$/i
const timeModeRegex = /^(.+?) at ?(.*)$/i

const initialRepeatSuggestions: RepeatMenuOption[] = [
	{
		type: 'rrule',
		label: 'Every day...',
		rrule: { freq: RRule.DAILY },
	},
	{
		type: 'rrule',
		label: 'Every weekday...',
		rrule: {
			freq: RRule.WEEKLY,
			byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
		},
	},
	{
		type: 'rrule',
		label: 'Every week...',
		rrule: { freq: RRule.WEEKLY },
	},
	{
		type: 'rrule',
		label: 'Every month...',
		rrule: { freq: RRule.MONTHLY },
	},
	{
		type: 'rrule',
		label: 'Every year...',
		rrule: { freq: RRule.YEARLY },
	},
]

const getDaySuggestions = (query: string): RruleOption[] => {
	const isTimeMode = timeModeRegex.test(query)
	const start = startOfISOWeek(new Date())

	if (isTimeMode) {
		return getTimeSuggestions(query)
	} else {
		return Array.from({ length: 7 }).map((_, i) => {
			const date = addDays(start, i)
			return {
				type: 'rrule' as const,
				label: format(date, 'EEEE'),
				rrule: {
					freq: RRule.WEEKLY,
					byweekday: [i],
				},
			}
		})
	}
}

const getMonthSuggestions = (query: string): RruleOption[] => {
	const isTimeMode = timeModeRegex.test(query)
	const match = query.match(repeatMonthRegex)
	const monthQuery = match ? match[1] : ''
	const start = startOfISOWeek(new Date())

	if (isTimeMode) {
		return getTimeSuggestions(query)
	} else {
		return Array.from({ length: getDaysInMonth(start) })
			.map((_, i) => {
				const date = addDays(startOfMonth(start), i)
				return {
					type: 'rrule' as const,
					label: format(date, 'do'),
					rrule: {
						freq: RRule.MONTHLY,
						bymonthday: [i + 1],
					},
					nextMode: 'day' as const,
				}
			})
			.filter((item) =>
				query ? new RegExp(monthQuery, 'i').test(item.label) : true
			)
	}
}

const getTimeSuggestions = (query: string): RruleOption[] => {
	const match = query.match(timeModeRegex)
	const timeQuery = match ? match[2] : ''
	const start = startOfDay(new Date())
	const increment = 15
	const times: RruleOption[] = Array.from({
		length: (24 * 60) / increment,
	})
		.map((_, i) => {
			const date = addMinutes(start, i * increment)
			return {
				type: 'rrule' as const,
				label: format(date, 'h:mmaaa'),
				rrule: {
					byhour: [date.getHours()],
					byminute: [date.getMinutes()],
					bysecond: [0],
				},
			}
		})
		.filter((item, i) =>
			timeQuery
				? new RegExp(timeQuery, 'i').test(item.label)
				: // Only extrapolate 14 hours
				  i >= 32 && i < 96
		)

	return times
}

const getWeekdaySuggestions = (query: string) => {
	const isTimeMode = timeModeRegex.test(query)
	const start = startOfISOWeek(new Date())

	if (isTimeMode) {
		return getTimeSuggestions(query)
	} else {
		return Array.from({ length: 7 }).map((_, i) => {
			const date = addDays(start, i)
			return {
				type: 'rrule' as const,
				label: format(date, 'EEEE'),
				rrule: {
					freq: RRule.WEEKLY,
					byweekday: [i],
				},
				nextMode: 'day' as const,
			}
		})
	}
}

const getWeekSuggestions = (query: string) => {
	const isTimeMode = timeModeRegex.test(query)
	const start = startOfISOWeek(new Date())

	if (isTimeMode) {
		return getTimeSuggestions(query)
	} else {
		return Array.from({ length: 7 }).map((_, i) => {
			const date = addDays(start, i)
			return {
				type: 'rrule' as const,
				label: format(date, 'EEEE'),
				rrule: {
					freq: RRule.WEEKLY,
					byweekday: [i],
				},
				nextMode: 'day' as const,
			}
		})
	}
}

const getYearSuggestions = (query: string): RruleOption[] => {
	const isTimeMode = timeModeRegex.test(query)
	const match = query.match(repeatYearRegex)
	const monthQuery = match ? match[1] : ''
	const start = startOfISOWeek(new Date())

	if (isTimeMode) {
		return getTimeSuggestions(query)
	} else {
		return Array.from({ length: 12 })
			.map((_, i) => {
				const date = addMonths(startOfYear(start), i)
				return {
					type: 'rrule' as const,
					label: format(date, 'MMMM'),
					rrule: {
						freq: RRule.YEARLY,
						bymonth: [i + 1],
					},
					nextMode: 'month' as const,
				}
			})
			.filter((item) =>
				query ? new RegExp(monthQuery, 'i').test(item.label) : true
			)
	}
}

export const getSuggestions = (
	mode: RepeatMode,
	query = ''
): RepeatMenuOption[] => {
	switch (mode) {
		case 'day':
			return getTimeSuggestions(query)
		case 'weekday':
			return getTimeSuggestions(query)
		case 'week':
			return getTimeSuggestions(query)
		case 'month':
			return getMonthSuggestions(query)
		case 'year':
			return getYearSuggestions(query)
	}

	return initialRepeatSuggestions
}
