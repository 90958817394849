import { isAfter, parseISO } from 'date-fns'
import { produce } from 'immer'
import { InfiniteData, MutationObserver, QueryClient } from 'react-query'

import {
	Attachment,
	FileOrMobileFile,
	ReactionIndex,
	StoreContext,
} from '../../../types'
import { ApiListResult } from '../../api'
import { createMutationErrorRollback } from '../../mutations/mutations-utils'
import { convertFileToAttachment } from '../../utils/files'
import { chatKeys } from '../chatKeys'
import {
	createAddMessageReactionMutationFn,
	createRemoveMessageReactionMutationFn,
	createSendMessageFilesMutationFn,
	createSendMessageMutationFn,
	createUpdateLastReadDateMutationFn,
} from '../chatQueries'
import { ChatMessage, ChatRoom } from '../chatTypes'
import { addToReactionIndex, removeFromReactionIndex } from '../chatUtils'

const defaultParams = { pageSize: 30 }

export const createAddMessageFileOptimisticHandler =
	(queryClient: QueryClient) =>
	async (chatMessageId: string, file: Attachment) => {
		const roomListKey = chatKeys.roomList()
		const roomsListResult =
			queryClient.getQueryData<ApiListResult<ChatRoom>>(roomListKey)
		const rooms = roomsListResult?.items || []
		const message = rooms.reduce((acc, room: ChatRoom) => {
			const chatListKey = chatKeys.roomMessagesList(
				room.id,
				defaultParams
			)
			const data =
				queryClient.getQueryData<
					InfiniteData<ApiListResult<ChatMessage>>
				>(chatListKey)
			const messages = data?.pages.flatMap((page) => page.items) || []
			const msg = messages.find((message) => message.id === chatMessageId)
			if (msg) {
				return msg
			} else {
				return acc
			}
		}, {} as ChatMessage)

		if (!message) {
			return { previousResult: null, queryKey: [] }
		}
		return createSendMessageOptimisticHandler(queryClient)({
			...message,
			files: [...(message.files || []), file],
		})
	}

export const createArchiveRoomOptimisticHandler =
	(queryClient: QueryClient) => async (chatRoomId: string) => {
		const roomListKey = chatKeys.roomList()
		await queryClient.cancelQueries(roomListKey)

		queryClient.setQueryData(
			roomListKey,
			produce((draft) => {
				if (!draft || !draft.items) {
					return
				}
				draft.items = draft.items.filter(
					(item: ChatRoom) => item.id !== chatRoomId
				)
			})
		)
		return { previousResult: null, queryKey: [] }
	}

export const createAddMessageReactionOptimisticHandler =
	(queryClient: QueryClient) =>
	async ({
		message,
		reaction,
	}: {
		message: ChatMessage
		reaction: string
	}) => {
		const queryKey = chatKeys.roomMessagesList(message.chatRoomId, {
			pageSize: 30,
		})
		await queryClient.cancelQueries(queryKey)

		const previousResult = queryClient.getQueryData(queryKey)

		queryClient.setQueryData(
			queryKey,
			produce((draft) => {
				if (!draft || !draft.pages) {
					return
				}
				draft.pages.forEach((page: ApiListResult<ChatMessage>) => {
					page.items.forEach((item: ChatMessage) => {
						if (item.id === message.id) {
							item.reactions = addToReactionIndex(
								item.reactions || {},
								reaction,
								message.userId
							)
						}
					})
				})
			})
		)

		return { previousResult, queryKey }
	}

export const createRemoveMessageReactionOptimisticHandler =
	(queryClient: QueryClient) =>
	async ({
		message,
		reaction,
	}: {
		message: ChatMessage
		reaction: string
	}) => {
		const queryKey = chatKeys.roomMessagesList(message.chatRoomId, {
			pageSize: 30,
		})
		await queryClient.cancelQueries(queryKey)

		const previousResult = queryClient.getQueryData(queryKey)

		queryClient.setQueryData(
			queryKey,
			produce((draft) => {
				if (!draft || !draft.pages) {
					return
				}
				draft.pages.forEach((page: ApiListResult<ChatMessage>) => {
					page.items.forEach((item: ChatMessage) => {
						if (item.id === message.id && item.reactions) {
							item.reactions = removeFromReactionIndex(
								item.reactions,
								reaction,
								message.userId
							)
						}
					})
				})
			})
		)

		return { previousResult, queryKey }
	}

export const createAddMessageReactionMutationObserver = ({
	apiAdapter,
	queryClient,
}: Pick<StoreContext, 'apiAdapter' | 'queryClient'>) =>
	new MutationObserver(queryClient, {
		mutationFn: createAddMessageReactionMutationFn(apiAdapter),
		onMutate: createAddMessageReactionOptimisticHandler(queryClient),
		onError: createMutationErrorRollback(queryClient),
	})

export const createRemoveMessageReactionMutationObserver = ({
	apiAdapter,
	queryClient,
}: Pick<StoreContext, 'apiAdapter' | 'queryClient'>) =>
	new MutationObserver(queryClient, {
		mutationFn: createRemoveMessageReactionMutationFn(apiAdapter),
		onMutate: createRemoveMessageReactionOptimisticHandler(queryClient),
		onError: createMutationErrorRollback(queryClient),
	})

export const createSendMessageOptimisticHandler =
	(queryClient: QueryClient) => async (message: ChatMessage) => {
		const chatListKey = chatKeys.roomMessagesList(
			message.chatRoomId,
			defaultParams
		)
		await queryClient.cancelQueries(chatListKey)
		const previousResultList = queryClient.getQueryData(chatListKey)

		queryClient.setQueryData(
			chatListKey,
			produce((draft) => {
				if (!draft) {
					draft = {}
				}
				if (!draft.pages) {
					draft.pages = [
						{ items: [], hasMore: false, page: 0, pageSize: 30 },
					]
				}
				draft.pages[0].items.unshift(message)
			})
		)

		// Update the last message in the room list
		const chatRoomListKey = chatKeys.roomList()
		await queryClient.cancelQueries(chatRoomListKey)
		// TODO: we need a way to rollback this value as well
		//const previousResultRoomList = queryClient.getQueryData(chatRoomListKey)

		queryClient.setQueryData(
			chatRoomListKey,
			produce((draft) => {
				if (!draft || !draft.items) {
					return
				}
				const rooms: ChatRoom[] = draft.items
				const room = rooms.find(
					(room) => room.id === message.chatRoomId
				)
				if (room) {
					room.lastMessage = message
				}
			})
		)

		// Return a context object with the snapshotted value
		return { previousResult: previousResultList, queryKey: chatListKey }
	}

export const createSendMessageMutationObserver = ({
	apiAdapter,
	queryClient,
}: Pick<StoreContext, 'apiAdapter' | 'queryClient'>) =>
	new MutationObserver(queryClient, {
		mutationFn: createSendMessageMutationFn(apiAdapter),
		onMutate: createSendMessageOptimisticHandler(queryClient),
		onError: createMutationErrorRollback(queryClient),
	})

export const createSendMessageFilesOptimisticHandler =
	(queryClient: QueryClient) =>
	async ({
		files,
		message,
	}: {
		files: FileOrMobileFile[]
		message: ChatMessage
	}) => {
		const attachments = files.map(convertFileToAttachment)
		return createSendMessageOptimisticHandler(queryClient)({
			...message,
			files: attachments as Attachment[],
		})
	}

export const createSendMessageFilesSuccessHandler =
	(queryClient: QueryClient) =>
	async (
		data: Attachment[],
		{
			message,
		}: {
			files: FileOrMobileFile[]
			message: ChatMessage
		}
	) =>
		createSendMessageOptimisticHandler(queryClient)({
			...message,
			files: data,
		})

export const createSendMessageFilesMutationObserver = ({
	apiAdapter,
	queryClient,
}: Pick<StoreContext, 'apiAdapter' | 'queryClient'>) =>
	new MutationObserver(queryClient, {
		mutationFn: createSendMessageFilesMutationFn(apiAdapter),
		onMutate: createSendMessageFilesOptimisticHandler(queryClient),
		onError: createMutationErrorRollback(queryClient),
		onSuccess: createSendMessageFilesSuccessHandler(queryClient),
	})

export const createUpdateLastReadDateOptimisticHandler =
	(queryClient: QueryClient) =>
	async ({
		chatRoomId,
		lastReadDate,
	}: {
		chatRoomId: string
		lastReadDate: string
	}) => {
		const chatRoomListKey = chatKeys.roomList()
		await queryClient.cancelQueries(chatRoomListKey)
		const previousRoomListResult = queryClient.getQueryData(chatRoomListKey)

		queryClient.setQueryData(
			chatRoomListKey,
			produce((draft) => {
				if (!draft || !draft.items) {
					return
				}
				const rooms: ChatRoom[] = draft.items
				const room = rooms.find((room) => room.id === chatRoomId)
				if (room) {
					room.lastReadDate = lastReadDate
				}
			})
		)

		const chatRoomDetailKey = chatKeys.roomDetail(chatRoomId)
		await queryClient.cancelQueries(chatRoomDetailKey)
		const previousRoomDetailResult =
			queryClient.getQueryData(chatRoomDetailKey)

		queryClient.setQueryData(
			chatRoomDetailKey,
			produce((draft) => {
				if (!draft) {
					return
				}
				draft.lastReadDate = lastReadDate
				draft.unreadMessages = draft.unreadMessages.filter(
					(message: ChatMessage) =>
						isAfter(
							parseISO(message.dateCreated),
							parseISO(lastReadDate)
						)
				)
			})
		)

		const unreadMessagesKey = chatKeys.allMessagesList({
			isUnread: true,
			pageSize: 0,
		})
		await queryClient.cancelQueries(unreadMessagesKey)
		const previousUnreadMessagesResult =
			queryClient.getQueryData(unreadMessagesKey)

		queryClient.setQueryData(
			unreadMessagesKey,
			produce((draft) => {
				if (!draft) {
					return
				}
				draft.items = draft.items.filter(
					(message: ChatMessage) =>
						message.chatRoomId !== chatRoomId &&
						message.dateCreated <= lastReadDate
				)
			})
		)

		// Return a context object with the snapshotted value
		return {
			previousResult: previousRoomListResult,
			queryKey: chatRoomListKey,
		}
	}

export const createUpdateLastReadDateMutationObserver = ({
	apiAdapter,
	queryClient,
}: Pick<StoreContext, 'apiAdapter' | 'queryClient'>) =>
	new MutationObserver(queryClient, {
		mutationFn: createUpdateLastReadDateMutationFn(apiAdapter),
		onMutate: createUpdateLastReadDateOptimisticHandler(queryClient),
		onError: createMutationErrorRollback(queryClient),
	})

export const createChatMessageReactionsOptimisticHandler =
	(queryClient: QueryClient) =>
	async ({
		messageId,
		reactions,
		roomId,
	}: {
		messageId: string
		roomId: string
		reactions: ReactionIndex
	}) => {
		const queryKey = chatKeys.roomMessagesList(roomId, {
			pageSize: 30,
		})
		await queryClient.cancelQueries(queryKey)

		const previousResult = queryClient.getQueryData(queryKey)

		queryClient.setQueryData(
			queryKey,
			produce((draft) => {
				if (!draft || !draft.pages) {
					return
				}
				draft.pages.forEach((page: ApiListResult<ChatMessage>) => {
					page.items.forEach((item: ChatMessage) => {
						if (item.id === messageId) {
							item.reactions = reactions
						}
					})
				})
			})
		)

		return { previousResult, queryKey }
	}

export * from './createChatRoom'
export * from './updateChatRoom'
