import { Box, BoxProps } from '@mui/material'

import { getSeededColor } from '@tyto/web-ui/random-colors'

interface OrganisationColorBlockProps extends BoxProps {
	name: string
}

export function OrganisationColorBlock({
	name,
	sx,
	...props
}: OrganisationColorBlockProps) {
	const { color } = getSeededColor(name)

	return (
		<Box
			sx={{
				backgroundColor: color,
				borderRadius: '2px',
				height: (theme) => theme.spacing(1.75),
				width: (theme) => theme.spacing(1.75),
				...sx,
			}}
			{...props}
		/>
	)
}

export default OrganisationColorBlock
