import { useCallback, useMemo, useState } from 'react'
import { format, parseISO } from 'date-fns'

import {
	Email as EmailIcon,
	ThumbDown as ThumbDownIcon,
	ThumbUp as ThumbUpIcon,
} from '@mui/icons-material'
import {
	Avatar,
	Box,
	CircularProgress,
	IconButton,
	Paper,
	Stack,
	Typography,
} from '@mui/material'
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid'

import { emailToHash } from '@tyto/helpers/gravatar'
import { createError, getGravatarUrl } from '@tyto/utils'
import { useConfirm } from '@tyto/web-ui/confirm'

import {
	PendingSignup,
	useApproveSignup,
	useRejectSignup,
} from './pendingSignupsData'

export interface PendingSignupsTableProps {
	signups: PendingSignup[]
}

export function PendingSignupsTable({ signups }: PendingSignupsTableProps) {
	const approveSignup = useApproveSignup()
	const rejectSignup = useRejectSignup()
	const confirm = useConfirm()
	const [sortModel, setSortModel] = useState<GridSortModel>([
		{ field: 'dateCreated', sort: 'desc' },
	])

	const handleApproveClick = useCallback(
		(email: string) => () => {
			approveSignup.mutate(email, {
				onSuccess: () => {
					confirm({
						title: 'Success',
						description: `${email} has been approved`,
						hideCancelButton: true,
					})
				},
				onError: (err) => {
					confirm({
						title: 'Failed',
						description: (
							<>
								<Typography>
									Something went wrong approving {email}.
								</Typography>
								<Typography>
									Error: {createError(err).message}
								</Typography>
							</>
						),
						hideCancelButton: true,
					})
				},
			})
		},
		[approveSignup]
	)

	const handleRejectClick = useCallback(
		(email: string) => async () => {
			confirm({
				title: 'Are you sure?',
				description: `This will reject the signup for ${email}. This action is permanent.`,
				confirmationText: 'Yes',
			}).then(() => {
				rejectSignup.mutate(email, {
					onError: (err) => {
						confirm({
							title: 'Failed',
							description: (
								<>
									<Typography>
										Something went wrong rejecting {email}.
									</Typography>
									<Typography>
										Error: {createError(err).message}
									</Typography>
								</>
							),
							hideCancelButton: true,
						})
					},
				})
			})
		},
		[rejectSignup]
	)

	const columns: GridColDef[] = useMemo(
		() => [
			{
				field: 'email',
				headerName: '',
				width: 40,
				renderCell: (params) => (
					<Avatar
						src={getGravatarUrl(emailToHash(params.row.email))}
						sx={{ width: 32, height: 32 }}
					/>
				),
			},
			{ field: 'name', headerName: 'Name', width: 180 },
			{ field: 'teamName', headerName: 'Team Name', width: 180 },
			{
				field: 'teamSize',
				headerName: 'Team Size',
				type: 'number',
				width: 90,
			},
			{ field: 'ipData.countryName', headerName: 'Country', width: 160 },
			{
				field: 'dateCreated',
				headerName: 'Date Created',
				width: 180,
				valueFormatter: (params) => {
					if (!params.value) {
						return ''
					}
					return format(parseISO(params.value || ''), 'PPpp')
				},
			},
			{
				field: 'action',
				headerName: 'Action',
				width: 160,
				renderCell: (params) => (
					<Stack direction={'row'}>
						<IconButton
							color={'success'}
							disabled={approveSignup.isLoading}
							onClick={handleApproveClick(params.row.email)}
						>
							{approveSignup.isLoading ? (
								<CircularProgress size={24} />
							) : (
								<ThumbUpIcon />
							)}
						</IconButton>
						<IconButton
							color={'error'}
							disabled={rejectSignup.isLoading}
							onClick={handleRejectClick(params.row.email)}
						>
							{rejectSignup.isLoading ? (
								<CircularProgress size={24} />
							) : (
								<ThumbDownIcon />
							)}
						</IconButton>
					</Stack>
				),
			},
			{
				field: 'directEmail',
				headerName: '',
				width: 40,
				renderCell: (params) => (
					<IconButton
						href={`mailto:${params.row.email}`}
						target={'_blank'}
					>
						<EmailIcon />
					</IconButton>
				),
			},
		],
		[handleApproveClick, handleRejectClick]
	)

	return (
		<Box sx={{ display: 'flex', flex: '1 1 0', py: 2 }}>
			<Paper sx={{ display: 'flex', flex: '1 1 0', overflow: 'auto' }}>
				<DataGrid
					getRowId={(signup) => signup.email}
					rows={signups}
					columns={columns}
					sortModel={sortModel}
					onSortModelChange={(model) => setSortModel(model)}
				/>
			</Paper>
		</Box>
	)
}

export default PendingSignupsTable
