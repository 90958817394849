import { QueryClient } from 'react-query'

import { Task } from '../../types'
import { taskKeys } from '../queries'

export const mapExistingTasks = (
	queryClient: QueryClient,
	taskIds: string[]
) => {
	const taskIndex = taskIds.reduce(
		(acc, taskId) => {
			acc[taskId] = true
			return acc
		},
		{} as Record<string, boolean>
	)
	// This has better performance than querying each task individually
	const tasks = queryClient.getQueriesData<Task>(taskKeys.details())
	return tasks.reduce((acc, [, task]) => {
		if (task && taskIndex[task.id]) {
			acc.push(task)
		}
		return acc
	}, [] as Task[])
}
