import { defaultTo, difference, uniq, without } from 'ramda'
import { prop } from 'remeda'

import { regexMatchAll } from '../../helpers/string'
import { Task } from '../../types'

const findTags = (str: string) => {
	const regex = /(?:^|\s|>|&nbsp;)(?:#)([^\s#<>'"]+)/gm
	return regexMatchAll(regex, str).map((matches) => matches[1])
}

const defaultToEmptyString = defaultTo('')

export const extractTags = (
	task: Partial<Pick<Task, 'title' | 'descr'>> | undefined
) => {
	if (!task || !('title' in task || 'descr' in task)) {
		return { tagString: undefined, tags: undefined }
	}
	const title = defaultToEmptyString(task.title).replace(/&nbsp;/g, ' ')
	const descr = defaultToEmptyString(task.descr).replace(/&nbsp;/g, ' ')

	// Remove hash character from the front
	const titleTags = findTags(title)
	const descrTags = findTags(descr)

	return {
		tagString: [...titleTags, ...descrTags].join(' '),
		tags: [
			...titleTags.map((t) => ({ descr: t, source: 'task.title' })),
			...descrTags.map((t) => ({ descr: t, source: 'task.descr' })),
		],
	}
}

const getTagArrayFromPartialTask = (partialTask: Partial<Task> | undefined) => {
	const { tags } = extractTags(partialTask)
	if (!tags) {
		return undefined
	}
	return tags.map(prop('descr'))
}

export const getAddedTags = (
	oldTask: Partial<Task> | undefined,
	newTask: Partial<Task>
) => {
	const oldTags = (oldTask?.tags as string[]) || []
	const newTags = getTagArrayFromPartialTask(newTask)

	if (!newTags) {
		return []
	}

	return difference(newTags, oldTags)
}

export const getRemovedTags = (
	oldTask: Partial<Task> | undefined,
	newTask: Partial<Task>
) => {
	const oldTags = (oldTask?.tags as string[]) || []
	const newTags = getTagArrayFromPartialTask(newTask)

	if (!newTags) {
		return []
	}

	return difference(oldTags, newTags)
}

export const getTagChanges = (
	oldTask: Partial<Task> | undefined,
	newTask: Partial<Task>
) => {
	const oldTags = (oldTask?.tags as string[]) || []
	const added = getAddedTags(oldTask, newTask)
	const removed = getRemovedTags(oldTask, newTask)

	const nextValue = uniq(without(removed, oldTags.concat(added)))

	return { added, removed, nextValue }
}
