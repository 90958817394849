import { alpha, createTheme, emphasize } from '@mui/material/styles'

import { CustomTheme } from '.'
import darkMatter from './darkMatter'
import { createWallpaperTheme } from './default'
import { defaultPalette } from './palettes'
import { createThemeShadows, mixColors } from './theme-utils'
import rubyTuesdayBackground from './wallpapers/background-ruby-tuesday.svg'

export const rubyTuesdayTheme: CustomTheme = {
	name: 'rubyTuesday',
	appBackground: { type: 'svg', svg: rubyTuesdayBackground },
	palette: {
		...defaultPalette,
		mode: 'dark',
		background: '#860642',
		onBackground: '#fff',
		surface: '#fff',
		onSurface: '#1a1a1a',
		shadow: mixColors('#000', '#860642', 0.5).toString(),
		sidebar: 'rgba(25, 27, 41, 0.95)',
		onSidebar: '#fff',
	},
}

const createMuiTheme = () => {
	const systemMuiTheme = createTheme()
	const darkMatterMuiTheme = darkMatter()

	const fontFamily = `Poppins, ${systemMuiTheme.typography.fontFamily}`

	return createWallpaperTheme(
		{
			name: 'rubyTuesday',
			appBackgroundImage: `url("${rubyTuesdayBackground}")`,
			typography: {
				fontFamily,
				h1: { fontWeight: 500 },
				h2: { fontWeight: 500 },
				h3: { fontWeight: 700 },
				h4: { fontWeight: 700 },
				h5: { fontWeight: 700 },
				h6: { fontWeight: 700 },
			},
			palette: {
				mode: 'light',
				background: {
					default: rubyTuesdayTheme.palette.background,
					contextPanel: emphasize(
						alpha(
							darkMatterMuiTheme.palette.background.paper,
							0.075
						),
						1
					),
					sidebar: rubyTuesdayTheme.palette.sidebar,
				},
			},
			shadows: createThemeShadows(rubyTuesdayTheme.palette.shadow),
			components: {
				TyTabBar: {
					styleOverrides: {
						root: {
							backgroundColor: rubyTuesdayTheme.palette.sidebar,
						},
					},
				},
				TyTabBarItem: {
					styleOverrides: {
						selected: {
							backgroundColor:
								rubyTuesdayTheme.palette.background,
						},
					},
				},
			},
		},
		{
			palette: {
				mode: 'dark',
				background: {
					paper: 'rgba(0, 0, 0, 0.13)',
				},
			},
		}
	)
}

export default createMuiTheme
