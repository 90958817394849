import { QueryClient, QueryObserverOptions } from 'react-query'
import { Persistor } from 'react-query/persistQueryClient-experimental'

import { env } from '@tyto/utils/environment'

import {
	playerKeys,
	projectKeys,
	removeQueryLastUpdated,
	taskKeys,
	userKeys,
	workflowKeys,
} from './queries'

const ONE_DAY = 1000 * 60 * 60 * 24
const ONE_HOUR = 1000 * 60 * 60
const ONE_MINUTE = 1000 * 60
const ONE_WORK_DAY = 1000 * 60 * 60 * 8
const TWENTY_MINUTES = 1000 * 60 * 20
const TEN_MINUTES = 1000 * 60 * 10
const FIVE_MINUTES = 1000 * 60 * 5
const TWENTY_SECONDS = 1000 * 20

let asyncStoragePersistor: Persistor | null = null
export const clearStoragePersistor = () => {
	if (asyncStoragePersistor) {
		asyncStoragePersistor.removeClient()
	}
}
export const getStoragePersistor = () => asyncStoragePersistor
export const setStoragePersistor = (persistor: Persistor) => {
	asyncStoragePersistor = persistor
}

const localStorage =
	typeof global.window !== 'undefined' ? global.window.localStorage : null

const getLocalStorageValue = (key: string, fallback: QueryObserverOptions) =>
	(localStorage && JSON.parse(localStorage.getItem(key) || 'null')) ||
	fallback

// Globally default to 20 seconds. This is a good base time to
// prevent duplication of queries when called within short amounts
// of time.
const defaultOptions = getLocalStorageValue(
	'ty.debug.queryClient.defaultOptions',
	{ cacheTime: TEN_MINUTES, staleTime: TWENTY_SECONDS }
)

const taskListOptions = getLocalStorageValue(
	'ty.debug.queryClient.taskListOptions',
	{ cacheTime: ONE_DAY, staleTime: TEN_MINUTES }
)
const taskDetailOptions = getLocalStorageValue(
	'ty.debug.queryClient.taskDetailOptions',
	{ cacheTime: ONE_DAY, staleTime: TWENTY_MINUTES }
)

const inboxTaskOptions = getLocalStorageValue(
	'ty.debug.queryClient.inboxTaskOptions',
	{ cacheTime: ONE_DAY, staleTime: ONE_WORK_DAY }
)

const playerOptions = getLocalStorageValue(
	'ty.debug.queryClient.playerOptions',
	{ cacheTime: ONE_DAY, staleTime: ONE_HOUR }
)

const userOptions = getLocalStorageValue('ty.debug.queryClient.userOptions', {
	cacheTime: ONE_DAY,
	staleTime: ONE_WORK_DAY,
})

const workflowOptions = getLocalStorageValue(
	'ty.debug.queryClient.workflowOptions',
	{ cacheTime: ONE_DAY, staleTime: ONE_WORK_DAY }
)

export const createQueryClient = ({
	cacheTime = ONE_DAY,
	staleTime = TWENTY_SECONDS,
} = {}) => {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				...defaultOptions,
				cacheTime,
				staleTime,
				// Only track changes in fields that are used from destructuring
				// In v4, this will be the default behavior
				notifyOnChangeProps: 'tracked',
			},
		},
	})

	// Set defaults for individual queries
	queryClient.setQueryDefaults(taskKeys.lists(), taskListOptions)
	queryClient.setQueryDefaults(taskKeys.details(), taskDetailOptions)
	queryClient.setQueryDefaults(taskKeys.detail('inbox'), inboxTaskOptions)
	queryClient.setQueryDefaults(projectKeys.lists(), taskListOptions)
	queryClient.setQueryDefaults(projectKeys.details(), taskListOptions)
	queryClient.setQueryDefaults(playerKeys.options(), playerOptions)
	queryClient.setQueryDefaults(userKeys.all, userOptions)
	queryClient.setQueryDefaults(workflowKeys.all, workflowOptions)

	if (env('APP_ENV') !== 'mobile') {
		setTimeout(() => {
			// Lists need to be reset on refresh until a better solution is found.
			// This is because the list doesn't return lastUpdated data for tasks
			// that have been removed.
			try {
				const queries = queryClient
					.getQueryCache()
					.findAll(taskKeys.lists())
				const queryKeys = queries.map((query) => query.queryKey)
				queryKeys.forEach((queryKey) => {
					removeQueryLastUpdated(queryKey)
				})
			} catch (err) {
				throw new Error('Failed to reset queryKey lastUpdated values', {
					cause: err,
				})
			}
			// This ensures all queries are invalidated when the user refreshes the page
			// queryClient.invalidateQueries()
		}, 500)
	}

	return queryClient
}
