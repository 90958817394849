import { Container, Typography } from '@mui/material'

import AwsS3ThumbnailGenerator from '../components/AwsS3ThumbnailGenerator'

/* eslint-disable-next-line */
export interface DebugPageProps {}

export function DebugPage(props: DebugPageProps) {
	return (
		<Container maxWidth={'xl'} sx={{ mt: 3 }}>
			<Typography variant={'h5'}>Debug page</Typography>

			<AwsS3ThumbnailGenerator />
		</Container>
	)
}

export default DebugPage
