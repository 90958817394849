/*
 * Task factory functions for mutating state
 */

import { mergeDeepLeft } from 'ramda'
import { QueryClient } from 'react-query'

import { User } from '../../../types'
import { ApiListResult } from '../../api'
import { userKeys } from '../../queries'
import { AppState } from '../../store-types'
import { createBaseQueryCacheMutation } from '../baseCreate'

export const updateUserMutation = (
	state: AppState,
	userId: string,
	changes: Partial<User>
) => {
	const { queryClient } = state
	createUpdateUserOnQueryCache(queryClient)(userId, changes)
}

const updateUserListsOnQueryCache = async (
	queryClient: QueryClient,
	userId: string,
	changes: Partial<User>
) => {
	const queryKey = userKeys.lists()
	await queryClient.cancelQueries(queryKey, { exact: true })

	// Optimistically update to the new value on all lists.
	queryClient.setQueriesData<ApiListResult<User>>(queryKey, (prevResult) => {
		if (!prevResult) {
			return { count: 0, items: [], hasMore: false, page: 0, pageSize: 0 }
		}
		return {
			...prevResult,
			items: prevResult.items
				.map((user) => {
					if (user.id === userId) {
						return mergeDeepLeft(changes, user) as User
					}
					return user
				})
				.filter((user) => user.active),
		}
	})

	// Return a rollback function.
	return () => {
		queryClient.invalidateQueries(queryKey)
	}
}

export const createUpdateUserOnQueryCache =
	(queryClient: QueryClient) => (userId: string, changes: Partial<User>) => {
		const standardMutation = createBaseQueryCacheMutation(queryClient)

		// Also update the user list
		updateUserListsOnQueryCache(queryClient, userId, changes)

		return standardMutation<User>(userKeys.detail(userId), (prevUser) => {
			if (!prevUser) {
				return undefined
			}
			return mergeDeepLeft(changes, prevUser) as User
		})
	}
