import produce from 'immer'
import { MutationObserver, QueryClient } from 'react-query'

import { StoreContext } from '../../../types'
import { ApiListResult } from '../../api'
import { createMutationErrorRollback } from '../../mutations/mutations-utils'
import { chatKeys } from '../chatKeys'
import { createUpdateChatRoomMutationFn } from '../chatQueries'
import { ChatRoom } from '../chatTypes'

export const createUpdateChatRoomOptimisticHandler =
	(queryClient: QueryClient) =>
	async ({
		roomId,
		changes,
	}: {
		roomId: string
		changes: Partial<ChatRoom>
	}) => {
		const roomDetailKey = chatKeys.roomDetail(roomId)
		const roomListKey = chatKeys.roomList()
		await queryClient.cancelQueries(roomListKey)

		const previousResult =
			queryClient.getQueryData<ApiListResult<ChatRoom>>(roomListKey)

		queryClient.setQueryData<ChatRoom>(
			roomDetailKey,
			produce((draft) => {
				if (!draft) {
					return
				}
				if (changes.name) {
					draft.name = changes.name
				}
				if (changes.users) {
					draft.users = changes.users
				}
			})
		)
		queryClient.setQueryData<ApiListResult<ChatRoom>>(
			roomListKey,
			produce((draft) => {
				if (!draft || !draft.items) {
					return
				}
				const existingRoom = draft.items.find(
					(room) => room.id === roomId
				)
				if (!existingRoom) {
					return
				}
				if (changes.name) {
					existingRoom.name = changes.name
				}
				if (changes.users) {
					existingRoom.users = changes.users
				}
			})
		)
		return { previousResult: previousResult, queryKey: roomListKey }
	}

export const createUpdateChatRoomMutationObserver = ({
	apiAdapter,
	queryClient,
}: Pick<StoreContext, 'apiAdapter' | 'queryClient'>) =>
	new MutationObserver(queryClient, {
		mutationFn: createUpdateChatRoomMutationFn(apiAdapter),
		onMutate: createUpdateChatRoomOptimisticHandler(queryClient),
		onError: createMutationErrorRollback(queryClient),
	})
