import { filter, identity, isTruthy } from 'remeda'

type TaskLogIdMap = {
	tasks: Set<unknown>
	users: Set<unknown>
	workflows: Set<unknown>
}

const serializeIds = (
	idMap: TaskLogIdMap
): { tasks: string[]; users: string[]; workflows: string[] } => ({
	tasks: Array.from(idMap.tasks || []),
	users: Array.from(idMap.users || []),
	workflows: Array.from(idMap.workflows || []),
})

// Fields that we want to check for differences.
export const taskLogDataFields = [
	'assigneeId',
	'descr',
	'dueDate',
	'dueTime',
	'hoursAllocated',
	'followers',
	'importance',
	'ownerId',
	'parentId',
	'startDate',
	'startTime',
	'statusCode',
	'title',
	'urgency',
	'workflowData',
] as const

export const getIdsForTaskLogs = (data: Record<string, unknown>[]) => {
	const ids: TaskLogIdMap = {
		tasks: new Set<string>(),
		users: new Set<string>(),
		workflows: new Set<string>(),
	}

	const extractIds = (item: Record<string, unknown>) =>
		Object.keys(item).forEach((key) => {
			const value = item[key]
			switch (key) {
				case 'oldParentId':
				case 'newParentId':
					value && ids.tasks.add(value)
					return

				case 'assigneeId':
				case 'followerId':
				case 'removeFollowerId':
				case 'ownerId':
				case 'userId':
					value && ids.users.add(value)
					return

				case 'workflowId':
					value && ids.workflows.add(value)
					return

				case 'taskLogs':
					if (Array.isArray(value)) {
						const items = value
							.filter(identity)
							.map((item) => item) as Record<string, unknown>[]
						items.forEach(extractIds)
					}
					return
			}
		})

	filter(data, isTruthy).forEach(extractIds)

	return serializeIds(ids)
}
