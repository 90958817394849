import { QueryClient, useMutation, useQuery } from 'react-query'

import { useStore } from '@tyto/dna/store'

export interface PendingSignup {
	adminEmail: string
	clientIp: string
	dateCreated: string
	email: string
	ipData: {
		ip: string
		countryName: string
		countryCode: string
		regionName: string
		city: string
		zipCode: string
		latitude: number
		longitude: number
	}
	name: string
	teamName: string
	teamSize: number
}

const queryKey = ['ops', 'pendingSignups']

const removeFromPendingSignup = async (
	queryClient: QueryClient,
	email: string
) => {
	await queryClient.cancelQueries(queryKey)

	const previousSignups = queryClient.getQueryData<PendingSignup[]>(queryKey)

	if (previousSignups) {
		const newSignups = previousSignups.filter(
			(signup) => signup.email !== email
		)
		queryClient.setQueryData<PendingSignup[]>(queryKey, newSignups)
	}
	return previousSignups
}

export const usePendingSignups = () => {
	return useQuery<PendingSignup[]>(queryKey, async () => {
		const apiInstance = useStore.getState().apiAdapter.apiInstance
		return apiInstance.get('/ops/pendingSignups').then((res) => res.data)
	})
}

export const useApproveSignup = () => {
	return useMutation(
		(email: string) => {
			const apiInstance = useStore.getState().apiAdapter.apiInstance
			return apiInstance
				.post(`/ops/approveSignup`, { email })
				.then((response) => response.data)
		},
		{
			onMutate: async (email) => {
				const queryClient = useStore.getState().queryClient
				const previousSignups = await removeFromPendingSignup(
					queryClient,
					email
				)
				return { previousSignups }
			},
			onError: (
				_err,
				_email,
				context: { previousSignups: PendingSignup[] | undefined }
			) => {
				if (!context.previousSignups) {
					return
				}

				const queryClient = useStore.getState().queryClient
				return queryClient.setQueryData<PendingSignup[]>(
					queryKey,
					context.previousSignups
				)
			},
		}
	)
}

export const useRejectSignup = () => {
	return useMutation(
		(email: string) => {
			const apiInstance = useStore.getState().apiAdapter.apiInstance
			return apiInstance
				.post(`/ops/rejectSignup`, { email })
				.then((response) => response.data)
		},
		{
			onMutate: async (email) => {
				const queryClient = useStore.getState().queryClient
				const previousSignups = await removeFromPendingSignup(
					queryClient,
					email
				)
				return { previousSignups }
			},
			onError: (
				_err,
				_email,
				context: { previousSignups: PendingSignup[] | undefined }
			) => {
				if (!context.previousSignups) {
					return
				}

				const queryClient = useStore.getState().queryClient
				return queryClient.setQueryData<PendingSignup[]>(
					queryKey,
					context.previousSignups
				)
			},
		}
	)
}
