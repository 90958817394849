import { fromEvent, Observable, Observer, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client'

import { env } from '@tyto/utils'

let socket: Socket | null = null

// @ts-deprecated use listenFor instead
export const createSocketObservable = <T>(
	socket: Socket,
	eventName: string
): Observable<T> => {
	return new Observable((observer: Observer<T>) => {
		const handler = (data: T) => observer.next(data)
		socket.on(eventName, handler)
		return () => socket.off(eventName, handler)
	})
}

export const getSocketClient = (
	token: string,
	options: Partial<ManagerOptions & SocketOptions> = {}
) => {
	if (socket && socket.connected) {
		return socket
	}
	socket = io(getSocketUrl(), {
		transports: ['websocket'],
		auth: { token },
		...options,
	})
	return socket
}

export const getSocketUrl = () => env('SOCKET_URL', 'https://api.tyto.me')

export const getSocketObservable = (socket: Socket) => of(socket)

// Stream of connections
export const getSocketConnectEvents = (socket$: Observable<Socket>) =>
	socket$.pipe(
		switchMap((socket) =>
			fromEvent(socket, 'connect').pipe(map(() => socket))
		)
	)

// Listen for event after connection is valid
export const listenFor = (socket$: Observable<Socket>, eventName: string) => {
	const connect$ = getSocketConnectEvents(socket$)
	return connect$.pipe(switchMap((socket) => fromEvent(socket, eventName)))
}
