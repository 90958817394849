import { useMemo } from 'react'
import { SubmitHandler, useForm, UseFormRegisterReturn } from 'react-hook-form'

import { Box, Button, Paper, Stack, TextField, Typography } from '@mui/material'

import { useStore } from '@tyto/dna/store'

// TODO: use dna version
const mapRefToInputRef = ({ ref, ...rest }: UseFormRegisterReturn) => {
	return { inputRef: ref, ...rest }
}

type LoginFormFields = {
	email: string
	password: string
}

const TOKEN_STORAGE_ID = 'ty.auth'

export const clearToken = () => localStorage.removeItem(TOKEN_STORAGE_ID)

export const getToken = () => localStorage.getItem(TOKEN_STORAGE_ID)

export const setToken = (token: string) =>
	localStorage.setItem(TOKEN_STORAGE_ID, token)

export const LoginPage = () => {
	const {
		formState: { errors },
		handleSubmit,
		register,
	} = useForm<LoginFormFields>()

	const handleFormSubmit: SubmitHandler<LoginFormFields> = async (data) => {
		const response = await useStore
			.getState()
			.player.login(data.email, data.password)

		if (response?.token) {
			setToken(response.token)
		}
	}

	const fields = useMemo(
		() => ({
			email: mapRefToInputRef(register('email', { required: true })),
			password: mapRefToInputRef(
				register('password', { required: true })
			),
		}),
		[register]
	)

	return (
		<Box
			sx={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				height: '100%',
			}}
		>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					p: 3,
					width: 64 * 6,
				}}
			>
				<form onSubmit={handleSubmit(handleFormSubmit)}>
					<Stack spacing={2}>
						<Typography variant={'h5'}>Login</Typography>
						<TextField
							id={'email'}
							autoComplete={'email'}
							error={Boolean(errors['email'])}
							helperText={errors['email']?.message}
							label={'Email'}
							type={'email'}
							{...fields['email']}
						/>
						<TextField
							id={'password'}
							autoComplete={'current-password'}
							error={Boolean(errors['password'])}
							helperText={errors['password']?.message}
							label={'Password'}
							type={'password'}
							{...fields['password']}
						/>
						<Button fullWidth size={'large'} type={'submit'}>
							Login
						</Button>
					</Stack>
				</form>
			</Paper>
		</Box>
	)
}

export default LoginPage
