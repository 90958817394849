import { CustomTheme } from '.'
import { createBaseTheme } from './default'
import { defaultPalette } from './palettes'

export const darkMatterTheme: CustomTheme = {
	name: 'darkMatter',
	palette: {
		...defaultPalette,
		mode: 'dark',
		background: '#121212',
		onBackground: '#fff',
		surface: '#121212',
		onSurface: '#fff',
		sidebar: 'rgba(0,0,0,0.1)',
		onSidebar: '#fff',
	},
}

const createMuiTheme = () => {
	const baseMuiTheme = createBaseTheme({ palette: { mode: 'dark' } })

	return createBaseTheme({
		name: 'darkMatter',
		palette: {
			background: {
				sidebar: darkMatterTheme.palette.sidebar,
				secondary: baseMuiTheme.palette.grey[700],
			},
			mode: 'dark',
		},
	})
}

export default createMuiTheme
