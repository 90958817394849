import {
	bindMenu,
	bindTrigger,
	usePopupState,
} from 'material-ui-popup-state/hooks'
import { Link } from 'react-router-dom'

import { Menu as MenuIcon } from '@mui/icons-material'
import {
	AppBar,
	Box,
	Button,
	IconButton,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from '@mui/material'

const pages = [
	{ label: 'Active Users', to: '/' },
	{ label: 'Pending Signups', to: '/pending-signups' },
	{ label: 'Slipping Away', to: '/slipping-away' },
	{ label: 'New Users', to: '/new-users' },
	{ label: 'Debug', to: '/debug' },
]

/* eslint-disable-next-line */
export interface HeaderProps {}

export function Header(props: HeaderProps) {
	const popupState = usePopupState({ variant: 'popover', popupId: 'navMenu' })

	return (
		<AppBar color={'default'} position={'static'}>
			<Toolbar variant={'dense'}>
				<IconButton
					color={'inherit'}
					edge={'start'}
					sx={{ mr: 2 }}
					{...bindTrigger(popupState)}
				>
					<MenuIcon />
				</IconButton>
				<Typography variant={'h6'} sx={{ flexGrow: 1 }}>
					Backstage
				</Typography>

				<Box
					sx={{
						flexGrow: 1,
						display: { xs: 'none', md: 'flex' },
					}}
				>
					{pages.map((page) => (
						<Button
							key={page.label}
							color={'inherit'}
							component={Link}
							to={page.to}
							variant={'text'}
						>
							{page.label}
						</Button>
					))}
				</Box>

				<Menu {...bindMenu(popupState)}>
					{pages.map((page) => (
						<MenuItem
							key={page.label}
							component={Link}
							onClick={popupState.close}
							to={page.to}
						>
							<ListItemText>{page.label}</ListItemText>
						</MenuItem>
					))}
				</Menu>
			</Toolbar>
		</AppBar>
	)
}

export default Header
