import { format, parseISO } from 'date-fns'
import { filter, flatten, join, map, pipe } from 'remeda'

import { durationFormat } from '../../helpers/formatters/duration-format'
import {
	TaskActivity,
	TaskActivityMultiple,
	TaskActivityMultipleSchema,
} from '../task-activity-schemas'

export const parseTaskMultipleToString = (
	activity: TaskActivityMultiple
): string => {
	const meta = activity.meta

	const entries = pipe(
		activity.taskLogs,
		map((activity) => {
			switch (activity.type) {
				case 'task.assign': {
					const assignee = activity['meta'].assignee
					if (!assignee) {
						return 'Assignee'
					}
					return `Assignee: ${assignee.nickname}`
				}
				case 'task.dueDate': {
					const date = parseISO(activity.date)
					if (date) {
						return `Due date: ${format(date, 'PPp')}`
					} else {
						return `Due date: removed`
					}
				}
				case 'task.duration':
					return `Duration: ${durationFormat(activity.newDuration)}`
				case 'task.move':
					return `Moved to: ${activity.newParentTitle}`
				case 'task.owner': {
					const owner = activity['meta'].owner

					return `Owner: ${owner ? owner.nickname : ''}`
				}
				case 'task.priority': {
					const result = []
					if (activity.newUrgency !== null) {
						result.push(`Urgency: ${activity.newUrgency}`)
					}
					if (activity.newImportance !== null) {
						result.push(`Importance: ${activity.newImportance}`)
					}
					return result
				}
				case 'task.schedule': {
					const date = activity.date ? parseISO(activity.date) : null
					if (date) {
						return `Start date: ${format(date, 'PPp')}`
					} else {
						return `Start date: removed`
					}
				}
				case 'task.status':
					return `Status: ${activity.status}`
				case 'task.title':
					return `Title: ${activity.newTaskTitle}`
			}
		}),
		flatten,
		filter(Boolean),
		map((text) => `<li>${text}</li>`),
		join('')
	)

	return `${meta.user.nickname} updated this task:<br/><ul>${entries}</ul>`
}

export const validateTaskMultiple = (taskActivity: TaskActivity) =>
	TaskActivityMultipleSchema.safeParse(taskActivity)
