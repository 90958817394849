import { of } from 'ramda'
import { QueryClient } from 'react-query'
import { combineLatest } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { ApiAdapter } from '../../api'
import { createQueryObservable } from '../../queries'
import {
	createFetchTaskListQueryFn,
	createTaskDetailQuery,
	taskKeys,
} from '../../queries/tasks'

export const createSubtaskObservable = (
	apiAdapter: ApiAdapter,
	queryClient: QueryClient,
	taskId: string
) => {
	const params = { parentId: taskId }
	const taskDetailQuery = createTaskDetailQuery(apiAdapter, queryClient)
	return combineLatest([
		createQueryObservable(queryClient, taskDetailQuery(taskId)),
		createQueryObservable(queryClient, {
			queryKey: taskKeys.list(params),
			queryFn: createFetchTaskListQueryFn(apiAdapter, queryClient),
		}),
	]).pipe(
		// TODO: handle errors
		catchError((error) => of(error))
	)
}
