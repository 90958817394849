import { QueryFunctionContext } from 'react-query'

import { User } from '../../../types'
import { ApiAdapter } from '../../api'
import { userKeys } from './userKeys'

export const fetchUser =
	(apiAdapter: ApiAdapter, userId: string) =>
	async ({ signal }: QueryFunctionContext): Promise<User | null> => {
		if (!userId) {
			return null
		}

		const user = await apiAdapter.users.getDetail(userId, { signal })
		return user

		// TODO: this could be better...
		// try {
		// 	return userDetailSchema.validateSync(user)
		// } catch (err) {
		// 	return null
		// }
	}

export const createUserDetailQuery =
	(apiAdapter: ApiAdapter) => (userId: string) => ({
		queryKey: userKeys.detail(userId),
		queryFn: fetchUser(apiAdapter, userId),
	})
