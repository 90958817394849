import { QueryClient, QueryKey } from 'react-query'

type MutationContext = {
	previousResult: unknown
	queryKey: QueryKey
}

export const createMutationErrorRollback =
	(queryClient: QueryClient) =>
	(err: unknown, _: unknown, context: MutationContext | undefined) => {
		if (context) {
			// If the mutation fails, use the context returned from onMutate to roll back
			queryClient.setQueryData(context.queryKey, context.previousResult)
		} else {
			throw err
		}
	}

const defaultEquals = <T extends { id: string }>(a: T, b: T) => a.id === b.id
export const removeItemFromListInQueryCache = <T>(
	queryClient: QueryClient,
	queryKey: QueryKey,
	item: T,
	equals = defaultEquals
) =>
	queryClient.setQueryData(queryKey, (prev: unknown) => {
		if (!prev || !Array.isArray(prev)) {
			return
		}

		return prev.filter((i) => !equals(i, item))
	})
