/*
 * Task factory functions for mutating state
 */

import { formatISO } from 'date-fns'
import { RawDraftContentState } from 'draft-js'
import { QueryClient } from 'react-query'
import { reject } from 'remeda'

import { Task } from '../../../types'
import { ApiAdapter } from '../../api/baseApiAdapter'
import { taskKeys } from '../../queries'
import { AppState } from '../../store-types'
import { createUpdateTaskOnQueryCache } from './update'

type CommentData = {
	draftModel: RawDraftContentState
	html: string
}

export const markAsDoneMutation = async (
	state: AppState,
	taskId: string,
	commentData: CommentData
) => {
	const { apiAdapter, queryClient } = state
	createMarkAsDoneOnQueryCache(queryClient)(taskId)
	return createMarkAsDoneOnApi(apiAdapter)(taskId, commentData)
}

export const createMarkAsDoneOnApi =
	(apiAdapter: ApiAdapter) =>
	async (taskId: string, commentData: CommentData) =>
		apiAdapter.tasks.markAsDone(taskId, commentData)

export const createMarkAsDoneOnQueryCache =
	(queryClient: QueryClient) => async (taskId: string) => {
		const updateQueryCacheMutation =
			createUpdateTaskOnQueryCache(queryClient)

		updateQueryCacheMutation(taskId, {
			doneDate: formatISO(Date.now()),
			statusCode: 'done',
		})

		// Remove the inactive task from the parent childSortOrder
		const currentTask = queryClient.getQueryData<Task>(
			taskKeys.detail(taskId)
		)
		if (currentTask && currentTask.parentId) {
			const parentTask = queryClient.getQueryData<Task>(
				taskKeys.detail(currentTask.parentId)
			)

			if (parentTask) {
				const newChildSortOrder = reject(
					parentTask.childSortOrder || [],
					(id) => id === taskId
				)
				updateQueryCacheMutation(currentTask.parentId, {
					childSortOrder: newChildSortOrder,
				})
			}
		}
	}
