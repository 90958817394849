import {
	addMonths,
	format,
	formatISO,
	isWeekend,
	nextMonday,
	startOfDay,
} from 'date-fns'
import { uniq } from 'remeda'

import { scoreRoles, statusCodes } from '../../constants'
import { isDateInPast } from '../../helpers/dates'
import { isInactive } from '../../helpers/taskStatus'
import { calculateScore } from '../../score/score'
import { Task, TaskTagUpdate, User, WorkflowIndex } from '../../types'
import { applyWorkflowActionsToTask } from '../../workflows'
import { getTagChanges } from '../tags'

interface PreTaskUpdate {
	assignee?: Partial<User>
	task: Partial<Task>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isPlainObject = (val: any): boolean => {
	if (val === undefined || val === null || val.then) return false
	return Object.prototype.toString.call(val) === '[object Object]'
}

const makePreTaskUpdate =
	(workflowById: WorkflowIndex, oldAssignee: User | null | undefined) =>
	(oldTask: Task, updateData: Partial<Task>): PreTaskUpdate => {
		let newUpdateData = { ...updateData }
		// If there are workflow actions, apply them optimistically.
		// NOTE: This mutates the update data and the API will be doing the same
		// action on it's side. But it's mutation will result in no extra change
		// because it should resolve to the same value.
		if (
			newUpdateData.workflowData != null &&
			newUpdateData.workflowData.id
		) {
			const workflow = workflowById[newUpdateData.workflowData.id]
			newUpdateData = applyWorkflowActionsToTask(
				newUpdateData,
				workflow,
				oldTask
			)
		}

		// Set the `startDate` to today if the assignee changed and the current
		// `startDate` is in the past. This needs to run after the worflow
		// actions have been applied because they could change the assignee or
		// startDate.
		const didAssigneeChange =
			'assigneeId' in newUpdateData &&
			newUpdateData?.assigneeId !== oldTask?.assigneeId
		if (
			'assigneeId' in newUpdateData &&
			newUpdateData.assigneeId &&
			didAssigneeChange &&
			(!newUpdateData?.startDate ||
				(oldTask?.startDate && isDateInPast(oldTask.startDate)))
		) {
			newUpdateData.startDate = format(
				startOfDay(Date.now()),
				'yyyy-MM-dd'
			)
		}

		// If assignee changed, and task is started. Then stop the task.
		if (didAssigneeChange && oldTask?.currentTimer.status === 'started') {
			// TODO: this should be a shared mutation
			newUpdateData.currentTimer = {
				date: formatISO(Date.now()),
				status: 'stopped',
			}
		}

		// Add a someday tag if the startDate is changed to someday and remove
		// it if the date is changed to not someday
		if ('startDate' in newUpdateData && Array.isArray(oldTask?.tags)) {
			if (newUpdateData.startDate === 'someday') {
				newUpdateData.tags = uniq([...oldTask.tags, 'someday'])
			} else if (oldTask.tags.includes('someday')) {
				newUpdateData.tags = oldTask.tags.filter(
					(tag) => tag !== 'someday'
				)
			}
		}

		// Update startDate if it's set to someday
		if (newUpdateData.startDate === 'someday') {
			let someday = addMonths(Date.now(), 2)
			if (isWeekend(someday)) {
				someday = nextMonday(someday)
			}
			newUpdateData.startDate = formatISO(someday)
		}

		// If status is set to inactive, then we want to stop the assignee's timer.
		let newAssignee
		// TODO: score should be updated in response to a task update
		// subscription. It currently doesn't update the owner and returning
		// assignee and owner changes after a pre-task update is quite awkward.
		// So this should move out of here and assignee and owner score
		// calculation should be centralised for use by the store and the API.
		if (isInactive(newUpdateData.statusCode) && oldAssignee) {
			// Optimistically update the score if task is marked as done
			if (
				newUpdateData.statusCode === statusCodes.DONE &&
				oldAssignee &&
				oldTask.assigneeId
			) {
				const scoreData = calculateScore(
					{ ...oldTask, ...newUpdateData },
					{ id: oldTask.assigneeId },
					scoreRoles.DOER
				)

				const newScore = oldAssignee.currentScore + scoreData?.score
				if (typeof newScore === 'number' && !isNaN(newScore)) {
					newAssignee = {
						currentScore: newScore,
					}
				}
			}

			// Stop task if it's currently running
			if (
				oldAssignee.currentTaskId === newUpdateData.id &&
				oldAssignee.currentTaskStartDate != null
			) {
				newAssignee = newAssignee || {}
				newAssignee = {
					...newAssignee,
					currentTaskId: null,
					currentTaskStartDate: null,
				}

				// Update the task timer also.
				newUpdateData = {
					currentTimer: {
						date: formatISO(Date.now()),
						status: 'stopped',
					},
					...newUpdateData,
				}
			}

			if (newAssignee) {
				newAssignee = { id: oldAssignee.id, ...newAssignee }
			}
		}
		// If the task is set as not done, then remove the score for that task.
		else if (
			newUpdateData.statusCode &&
			newUpdateData.statusCode !== 'done' &&
			oldTask?.statusCode === 'done' &&
			oldAssignee &&
			oldTask.assigneeId
		) {
			const scoreData = calculateScore(
				{ ...oldTask, ...newUpdateData },
				{ id: oldTask.assigneeId },
				scoreRoles.DOER
			)
			const newScore = oldAssignee.currentScore - scoreData?.score
			newAssignee = {
				id: oldAssignee.id,
				currentScore: newScore,
			}
		}

		// Tag updates are different to the data displayed
		if (isPlainObject(newUpdateData.tags)) {
			// Make sure the previous tags value is a valid array.
			const oldTags = Array.isArray(oldTask.tags) ? oldTask.tags : []

			const { add, remove } = newUpdateData.tags as TaskTagUpdate
			let newTags = [...oldTags]

			if (Array.isArray(add)) {
				newTags = uniq(newTags.concat(add))
			}

			if (Array.isArray(remove)) {
				newTags = newTags.filter((tag) => !remove.includes(tag))
			}
			newUpdateData = {
				...newUpdateData,
				tags: newTags,
			}
		}

		// If there are tags in the title or descr, then extract them and merge
		// them into the tags field.
		const tagChanges = getTagChanges(oldTask, newUpdateData)
		if (tagChanges.added.length > 0 || tagChanges.removed.length > 0) {
			// Check for explicitly updated tags
			if (Array.isArray(newUpdateData.tags)) {
				newUpdateData.tags = uniq(
					newUpdateData.tags.concat(tagChanges.nextValue)
				)
			} else {
				newUpdateData.tags = tagChanges.nextValue
			}
		}

		// Add lastUpdated field
		if (Object.keys(newUpdateData).length > 0) {
			newUpdateData.lastUpdated = formatISO(Date.now())
		}

		return {
			assignee: newAssignee,
			task: newUpdateData,
		}
	}

export default makePreTaskUpdate
