import { QueryClient } from 'react-query'

import { ApiAdapter } from '../../api'
import { createFetchTaskListQueryFn } from './fetchTaskList'
import { taskKeys } from './taskKeys'

export const createAssignedTasksQuery =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) => (userId: string) => {
		const params = { assigneeId: userId }

		return {
			queryKey: taskKeys.list(params),
			queryFn: createFetchTaskListQueryFn(apiAdapter, queryClient),
		}
	}
