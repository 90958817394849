import {
	TaskActivity,
	TaskActivityFollower,
	TaskActivityFollowerSchema,
} from '../task-activity-schemas'

export const parseTaskFollowerToString = (
	activity: TaskActivityFollower
): string => {
	const meta = activity.meta

	if (activity.role) {
		const indefinite = activity.role === 'assignee' ? 'an' : 'a'
		return `${meta.user.nickname} added ${meta.follower.nickname} to be ${indefinite} ${activity.role}.`
	} else if (activity.removeFollowerId === activity.userId) {
		return `${meta.user.nickname} removed themselves from the list of followers.`
	} else if (activity.removeFollowerId) {
		return `${meta.user.nickname} removed ${meta.follower.nickname} from the list of followers.`
	}
	throw new Error(
		`Not enough information to build follower task log: ${JSON.stringify(
			activity
		)}`
	)
}

export const validateTaskFollower = (taskActivity: TaskActivity) =>
	TaskActivityFollowerSchema.safeParse(taskActivity)
