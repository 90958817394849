import {
	QueryClient,
	QueryFunctionContext,
	QueryObserverOptions,
} from 'react-query'
import { prop } from 'remeda'
import * as yup from 'yup'

import { Task } from '../../types'
import { ApiAdapter } from '../api'
import updateTaskQueryCacheWithList from '../utils/updateTaskQueryCacheWithList'
import { taskSchema } from './tasks'

interface ListParams {
	page?: number
	pageSize?: number
	// TODO: remove comma support in favour of arrays. This will give better
	// flexibility and better type support.
	// Comma separated list of statusCodes
	statusCodes?: string | 'all'
}

export interface ProjectDetail {
	ancestor: string | null
	descendants: string[]
	error?: yup.ValidationError | unknown
}

export const projectKeys = {
	all: ['projects'] as const,
	lists: () => [...projectKeys.all, 'list'] as const,
	list: (params: ListParams) => [...projectKeys.lists(), params] as const,
	details: () => [...projectKeys.all, 'detail'] as const,
	detail: (projectId: string, params?: { inactiveFrom?: string }) =>
		[...projectKeys.details(), projectId, params] as const,
}

export const projectDetailSchema = yup.object({
	ancestor: taskSchema.nullable(),
	descendants: yup.array(taskSchema),
	error: yup.object({}).optional(),
})

export const fetchProject =
	(
		apiAdapter: ApiAdapter,
		queryClient: QueryClient,
		projectId: string,
		inactiveFrom = 'none'
	) =>
	async ({ signal }: QueryFunctionContext): Promise<ProjectDetail> => {
		// TODO: use `fetchTaskList` from '../queries/tasks.ts'
		const promise = apiAdapter.tasks.getList(
			{
				ancestorId: projectId,
				inactiveFrom,
				includeRoot: true,
				pageSize: 0,
				statusCodes: inactiveFrom === 'none' ? 'active' : 'all',
			},
			{ signal }
		)

		const { items } = await promise

		updateTaskQueryCacheWithList(queryClient, items)

		const filterAncestors = (ancestor: Task, tasks: Task[]) => {
			const ancestorIds = ancestor.parents.map(prop('id'))
			return tasks.filter((task) => !ancestorIds.includes(task.id))
		}

		const ancestor = items.find((task) => task.id === projectId)
		let tasks = items
		if (ancestor) {
			tasks = filterAncestors(ancestor, items)
		}

		return tasks.reduce<ProjectDetail>(
			(acc, task) => {
				if (task) {
					if (projectId === task.id) {
						acc.ancestor = task.id
					} else {
						acc.descendants.push(task.id)
					}
				}
				return acc
			},
			{ ancestor: null, descendants: [] }
		)

		// TODO: this could be better...
		// try {
		// 	return projectDetailSchema.validateSync({ ancestor, descendants })
		// } catch (err) {
		// 	return { ancestor: null, descendants: [], error: err }
		// }
	}

export const createProjectDetailQuery =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) =>
	(projectId: string, inactiveFrom?: string): QueryObserverOptions => ({
		queryKey: projectKeys.detail(projectId, { inactiveFrom }),
		queryFn: fetchProject(apiAdapter, queryClient, projectId, inactiveFrom),
	})
