import {
	addDays,
	addMonths,
	addWeeks,
	differenceInDays,
	format,
	formatRelative,
	getISODay,
	isBefore,
	isSameYear,
	isValid,
	isWeekend,
	nextMonday,
	parseISO,
	startOfDay,
	startOfMonth,
	startOfWeek,
} from 'date-fns'
import { enUS } from 'date-fns/locale'
import moment, { Moment } from 'moment'
import {
	has,
	head,
	includes,
	lensProp,
	map,
	pipe,
	prop,
	reduce,
	reject,
	set,
	sortBy,
	sum,
	toPairs,
} from 'ramda'

import { ObjectIndex, Task } from '../types'
import { nlpParseDate, NlpParseDateOptions } from './nlp-date-parser'

export const MOMENT_DATE_FORMAT = 'ddd, D MMM YYYY'
export const MOMENT_DATETIME_FORMAT = 'ddd, D MMM YYYY h:mm A'

type DayShortName = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'

const isoWeekDayIndex = {
	mon: 1,
	tue: 2,
	wed: 3,
	thu: 4,
	fri: 5,
	sat: 6,
	sun: 7,
} as const

// Aim for a monday about 2 months from now
const getSomeday = () =>
	startOfWeek(addMonths(new Date(), 2), { weekStartsOn: 1 })

const customParsing = (text: string) => {
	const expressions = [
		{ regex: /^(\d+)\s*s/, use: 'seconds' },
		{ regex: /^(\d+)\s*mi/, use: 'minutes' },
		{ regex: /^(\d+)\s*h/, use: 'hours' },
		{ regex: /^(\d+)\s*w/, use: 'weeks' },
		{ regex: /^(\d+)\s*d/, use: 'days' },
		{ regex: /^(\d+)\s*m/, use: 'months' },
		{ regex: /^(\d+)\s*y/, use: 'years' },
	]
	let result: (string | number)[] | null = null

	for (let i = 0; i < expressions.length; i++) {
		const exp = expressions[i]
		const matches = text.match(exp.regex)

		if (matches && matches[1]) {
			result = [parseInt(matches[1]), exp.use]
			break
		}
	}

	return result
}

export type DateAliaseValue =
	| 'today'
	| 'tomorrow'
	| 'nextWeek'
	| 'nextMonth'
	| 'someday'
	| 'never'
export type DateAlias = { id: DateAliaseValue; label: string }

export const dateSelectorAliases: DateAlias[] = [
	{ id: 'today', label: 'Today' },
	{ id: 'tomorrow', label: 'Tomorrow' },
	{ id: 'nextWeek', label: 'Next Week' },
	{ id: 'nextMonth', label: 'Next Month' },
	{ id: 'someday', label: 'Someday' },
	{ id: 'never', label: 'Never' },
]

export const calculateDate = (alias: DateAliaseValue) => {
	switch (alias) {
		case 'today':
			return startOfDay(new Date())
		case 'tomorrow':
			return startOfDay(addDays(new Date(), 1))
		case 'nextWeek':
			return startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 })
		case 'nextMonth':
			return startOfMonth(addMonths(new Date(), 1))
		case 'someday': {
			const someday = addMonths(new Date(), 2)
			return isWeekend(someday) ? nextMonday(someday) : someday
		}
		case 'never':
			return null
		default:
			return null
	}
}

/**
 * Clean up the date a bit by removing 0:00 from the end and the
 * year if the date is in this year.
 **/
export const formatTrimmedDate = (date: Date): string => {
	if (!date || !isValid(date)) {
		console.warn('formatDate - expected a valid date', date)
		return ''
	}

	const now = new Date()
	const newFormat = isSameYear(now, date) ? 'iii, d MMM' : 'iii, d MMM yyyy'

	return format(date, newFormat)
}

export const formatRelativeDate = (date: Date) => {
	const now = new Date()

	const getOther = (date: Date) => {
		if (isBefore(startOfDay(date), startOfDay(now))) {
			const days = differenceInDays(now, date)
			return `${days} 'days ago'`
		}
		return formatTrimmedDate(date as Date)
	}

	const formatRelativeLocale = {
		today: "'Today'",
		tomorrow: "'Tomorrow'",
		yesterday: "'Yesterday'",
		nextWeek: 'EEEE',
		lastWeek: "'Last' EEEE",
		other: getOther(date),
	} as Record<string, string>

	const locale = {
		...enUS,
		formatRelative: (token: string) =>
			formatRelativeLocale[token]
				? formatRelativeLocale[token]
				: formatRelativeLocale['other'],
	}

	return formatRelative(date as Date, now, { locale, weekStartsOn: 1 })
}

export const formatDate = (date: Moment | Date): string => {
	if (moment.isMoment(date)) {
		if (date.isValid()) {
			date = date.toDate()
		} else {
			return ''
		}
	}

	if (!date || !isValid(date)) {
		console.warn('formatDate - expected a valid date', date)
		return ''
	}

	const now = new Date()

	const getOtherFormat = (date: Date) => {
		let format = 'iii, d MMM yyyy'

		if (isBefore(startOfDay(date), startOfDay(now))) {
			const days = differenceInDays(now, date)
			return `${days} 'days ago'`
		}

		if (isSameYear(now, date)) {
			format = format.replace(' yyyy', '')
		}

		return format
	}
	const formatRelativeLocale = {
		today: "'Today'",
		tomorrow: "'Tomorrow'",
		yesterday: "'Yesterday'",
		nextWeek: 'EEEE',
		lastWeek: "'Last' EEEE",
		other: getOtherFormat(date as Date),
	} as Record<string, string>

	const locale = {
		...enUS,
		formatRelative: (token: string) =>
			formatRelativeLocale[token]
				? formatRelativeLocale[token]
				: formatRelativeLocale['other'],
	}

	return formatRelative(date as Date, now, { locale, weekStartsOn: 1 })
}

export const parseDate = (
	text: string,
	options?: NlpParseDateOptions
): Date | null => {
	let date: Date | null = null
	if (text === 'someday') {
		date = getSomeday()
	} else {
		return nlpParseDate(text, options)
	}

	return isValid(date) ? date : null
}

const timeString = (word: string, count: number) =>
	`${count} ${word}${count !== 1 ? 's' : ''}`

/**
 * Creates a formatted string for displaying duration like: `1 hour 20 minutes`.
 * Takes a duration in seconds as an argument.
 */
export const durationStringFromSeconds = (totalSeconds: number): string => {
	const totalMinutes = Math.floor(totalSeconds / 60)
	const totalHours = Math.floor(totalMinutes / 60)

	const days = Math.floor(totalHours / 24)
	const hours = totalHours - days * 24
	const minutes = totalMinutes - totalHours * 60
	const seconds = totalSeconds - totalMinutes * 60

	const result: string[] = []

	if (days) result.push(timeString('day', days))
	if (hours) result.push(timeString('hour', hours))
	if (minutes && !days) result.push(timeString('minute', minutes))
	if (seconds && !minutes && !days && !hours)
		result.push(timeString('second', seconds))

	return result.join(' ')
}

/**
 * Requires that the tasks are in the date range so that it can populate the
 * workload for each day.
 *
 * @param tasks {array}
 * @param fromDate {string}
 * @param toDate {string}
 * @param excludeDays {array}
 * @returns {{date, hours}}
 */
export const findQuietestDay = (
	tasks: Pick<Task, 'startDate' | 'hoursAllocated'>[],
	fromDate: string,
	toDate: string,
	excludeDays: DayShortName[] | null = ['sat', 'sun']
): { date: string; hours: number } => {
	const dateFormat = 'yyyy-MM-dd'
	const parsedToDate = parseISO(toDate)
	const parsedFromDate = parseISO(fromDate)
	const dayRangeCount = differenceInDays(parsedToDate, parsedFromDate)
	const initialWorkload: ObjectIndex<number> = {}
	// toDate is inclusive, so we use <= instead of <.
	for (let i = 0; i <= dayRangeCount; i++) {
		const dateKey = format(addDays(parsedFromDate, i), dateFormat)
		initialWorkload[dateKey] = 0
	}

	// Build workload for date range.
	const workloadMap = reduce<
		Pick<Task, 'startDate' | 'hoursAllocated'>,
		ObjectIndex<number>
	>(
		(acc, task) => {
			// TODO: handle parsing startDate when it's set to 'someday'
			const startDate: string | null = task.startDate
				? format(parseISO(task.startDate), dateFormat)
				: null
			if (startDate !== null && has(startDate, acc)) {
				acc = set(
					lensProp(startDate),
					sum([acc[startDate], task.hoursAllocated]),
					acc
				)
			}
			return acc
		},
		initialWorkload,
		tasks
	)

	const parsedExcludeDays = excludeDays
		? map((day) => isoWeekDayIndex[day], excludeDays)
		: []

	// Get day with least amount of hours.
	const workload = pipe(
		toPairs,
		map(([key, value]) => ({
			date: key,
			hours: value as number,
		})),
		// eslint-disable-next-line
		// @ts-ignore
		reject(({ date }) =>
			includes(getISODay(parseISO(date)), parsedExcludeDays)
		),
		sortBy(prop('hours'))
	)(workloadMap)

	// eslint-disable-next-line
	// @ts-ignore
	return head(workload) || { date: fromDate, hours: 0 }
}

/**
 * Check if the date is in the past.
 *
 * Defaults to comparing days instead of by the millisecond. Will return true if
 * null is given as the date.
 *
 * @param {string} date ISO formatted date string
 */
export const isDateInPast = (date: string) =>
	!date || isBefore(startOfDay(parseISO(date)), startOfDay(Date.now()))
