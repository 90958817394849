import darkMatter from './darkMatter'
import { createBaseTheme } from './default'

const playful = () => {
	const darkMatterMuiTheme = darkMatter()

	return createBaseTheme({
		name: 'playful',
		palette: {
			background: {
				contextPanel: 'transparent',
				sidebar: 'rgba(0,0,0,0.1)',
				secondary: darkMatterMuiTheme.palette.grey[700],
			},
			mode: 'dark',
		},

		components: {
			MuiAppBar: {
				styleOverrides: {
					colorDefault: {
						backgroundColor: '#4B9CF8',
					},
				},
			},
			MuiTabs: {
				defaultProps: {
					indicatorColor: 'primary',
					textColor: 'primary',
				},
			},
		},
	})
}

export default playful
