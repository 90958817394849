import produce from 'immer'
import { equals } from 'remeda'
import { asyncScheduler, from, merge, of } from 'rxjs'
import {
	distinctUntilChanged,
	startWith,
	switchMap,
	throttleTime,
} from 'rxjs/operators'

import { Slice, UserStatus } from '../../types'
import { ApiAdapter } from '../api/baseApiAdapter'
import { userStatusesSubject } from '../observables'
import { userKeys } from '../queries/users/userKeys'
import { MutatedAppState } from '../store-types'
import { getServiceFromState } from '../utils/getServiceFromState'

export type UserStatusSlice = Slice & {
	data: Map<string, UserStatus>
}

const defaultUserStatus = {
	currentTaskId: null,
	currentTaskStartDate: null,
	isBusy: false,
	isOnline: false,
	lastActivityDate: null,
	userId: null,
}
export const createUserStatus = (
	userStatus: Partial<UserStatus> & { userId: string }
): UserStatus => Object.assign({}, defaultUserStatus, userStatus)

const fetchOnlineUserStatuses = (apiAdapter: ApiAdapter) =>
	apiAdapter.apiInstance
		.get<Record<string, UserStatus>>('/users/onlineUserStatuses')
		.then((response) => response.data)

export const createUserStatusSlice: MutatedAppState<UserStatusSlice> = (
	set,
	get,
	api
) => ({
	data: new Map(),
	init: () => {
		const apiAdapter$ = getServiceFromState(api, 'apiAdapter')
		// const queryClient$ = getServiceFromState(api, 'queryClient')
		// const socket$ = getServiceFromState(api, 'socket')

		const fetchOnlineUserStatuses$ = apiAdapter$.pipe(
			switchMap((apiAdapter) => from(fetchOnlineUserStatuses(apiAdapter)))
		)

		const userStatuses$ = merge(
			userStatusesSubject.pipe(
				startWith(null),
				switchMap((initial) =>
					initial === null ? fetchOnlineUserStatuses$ : of(initial)
				)
			),
			userStatusesSubject
		).pipe(
			distinctUntilChanged((prev, curr) => equals(prev, curr)),
			throttleTime(2000, asyncScheduler, {
				leading: true,
				trailing: false,
			})
		)

		const userStatusesSubscription = userStatuses$.subscribe(
			(userStatuses) => {
				set((draft) => {
					draft.userStatus.data = new Map(
						Object.entries(userStatuses)
					)
				})

				const queryClient = get().queryClient

				Object.entries(userStatuses).forEach(([userId, userStatus]) => {
					queryClient.setQueryData(
						userKeys.detail(userId),
						produce((draft) => {
							if (!draft) {
								return
							}

							draft.currentTaskId = userStatus.currentTaskId
							draft.currentTaskStartDate =
								userStatus.currentTaskStartDate
							draft.lastActivityDate = userStatus.lastActivityDate
						})
					)
				})
			}
		)

		return () => {
			userStatusesSubscription.unsubscribe()
		}
	},
})
