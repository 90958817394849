import { groupBy } from 'remeda'

import { Box, Grid, Typography } from '@mui/material'

import OrganisationColorBlock from '../components/OrganisationColorBlock'
import { LatestUser } from './ActiveUsersPage'

export interface ActiveUsersMetaProps {
	users: LatestUser[]
}

type OrganisationFilterItem = {
	name: string
	users: LatestUser[]
}
type CreateColumnsFn = <T = unknown>(list: T[]) => T[][]

const createColumns: CreateColumnsFn = (list) => {
	const columns: any[][] = []
	const columnCount = 4
	const columnSize = Math.ceil(list.length / columnCount)
	for (let i = 0; i < columnCount; i++) {
		columns.push(list.slice(i * columnSize, (i + 1) * columnSize))
	}
	return columns
}

function OrganisationFilter({
	organisation,
}: {
	organisation: OrganisationFilterItem
}) {
	return (
		<Box
			sx={{
				alignItems: 'center',
				display: 'flex',
				flex: '1 0 25%',
				mr: 1,
				px: 1,
				py: 0.5,
			}}
		>
			<OrganisationColorBlock name={organisation.name} sx={{ mr: 1 }} />
			<Typography noWrap variant={'body2'}>
				{organisation.name} ({organisation.users.length})
			</Typography>
		</Box>
	)
}

export function ActiveUsersMeta({ users }: ActiveUsersMetaProps) {
	const groups = groupBy(users, (user) => user.organisationName)
	const organisations: OrganisationFilterItem[] = Object.keys(groups)
		.map((organisationName) => ({
			name: organisationName,
			users: groups[organisationName],
		}))
		.sort((a, b) => b.users.length - a.users.length)
	const columns = createColumns(organisations)
	const mapColumn = (column: OrganisationFilterItem[]) =>
		column.map((organisation) => (
			<OrganisationFilter
				key={organisation.name}
				organisation={organisation}
			/>
		))

	return (
		<Box sx={{ display: 'flex', flex: '1 1 0', py: 2 }}>
			<Grid container>
				<Grid item xs={12} sm={6} md={3}>
					{mapColumn(columns[0])}
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					{mapColumn(columns[1])}
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					{mapColumn(columns[2])}
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					{mapColumn(columns[3])}
				</Grid>
			</Grid>
		</Box>
	)
}

export default ActiveUsersMeta
