import { SHA256 } from 'crypto-js'

import { isValidEmail } from '@tyto/utils'

export const AVATAR_URL = '//www.gravatar.com/avatar/'

/**
 * Converts an email to a hash needed for requesting avatars from gravatar.com
 * https://docs.gravatar.com/general/hash/
 *
 * @param {string} email
 * @return {string}
 */
export const emailToHash = (email: string): string =>
	SHA256(email.trim().toLowerCase()).toString()

/**
 * Build up the full gravatar image url
 *
 * @param {string} input Can be an email or a valid hash of an email.
 * @return {string}
 */
export const getAvatarImageUrl = (input: string): string => {
	const query = ['r=pg', 'd=404']
	let hash = input

	if (isValidEmail(input)) {
		hash = emailToHash(input)
	}

	return `${AVATAR_URL}${hash}?${query.join('&')}`
}
