import { useState } from 'react'
import { format, formatDistanceToNow, parseISO } from 'date-fns'

import { Email as EmailIcon } from '@mui/icons-material'
import { Avatar, Box, IconButton, Paper } from '@mui/material'
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid'

import { emailToHash } from '@tyto/helpers'
import { getGravatarUrl } from '@tyto/utils'

import OrganisationColorBlock from '../components/OrganisationColorBlock'
import { LatestUser } from './ActiveUsersPage'

const columns: GridColDef[] = [
	{
		field: 'email',
		headerName: '',
		width: 40,
		renderCell: (params) => (
			<Avatar
				src={getGravatarUrl(emailToHash(params.row.email))}
				sx={{ width: 32, height: 32 }}
			/>
		),
	},
	{ field: 'name', headerName: 'Name', width: 180 },
	{
		field: 'organisationName',
		headerName: 'Company',
		width: 200,
		renderCell: (params) => (
			<Box sx={{ alignItems: 'center', display: 'flex' }}>
				<OrganisationColorBlock
					name={params.row.organisationName}
					sx={{ mr: 1 }}
				/>
				{params.row.organisationName}
			</Box>
		),
	},
	{ field: 'score', headerName: 'Score', type: 'number', width: 90 },
	{
		field: 'lastActivityDate',
		headerName: 'Last Active',
		width: 160,
		valueFormatter: (params) => {
			if (!params.value) {
				return 'Not active yet'
			}
			return formatDistanceToNow(parseISO(params.value || ''), {
				addSuffix: true,
			})
		},
	},
	{
		field: 'dateCreated',
		headerName: 'Signed Up',
		width: 160,
		valueFormatter: (params) => {
			if (!params.value) {
				return ''
			}
			return format(parseISO(params.value || ''), 'PPpp')
		},
	},
	{ field: 'city', headerName: 'Region', width: 160 },
	{ field: 'browser', headerName: 'Browser', width: 200 },
	{
		field: 'directEmail',
		headerName: '',
		width: 40,
		renderCell: (params) => (
			<IconButton href={`mailto:${params.row.email}`} target={'_blank'}>
				<EmailIcon />
			</IconButton>
		),
	},
]

export interface ActiveUsersTableProps {
	users: LatestUser[]
}

export function ActiveUsersTable({ users }: ActiveUsersTableProps) {
	const [sortModel, setSortModel] = useState<GridSortModel>([
		{ field: 'lastActivityDate', sort: 'desc' },
	])

	return (
		<Box sx={{ display: 'flex', flex: '1 1 0', py: 2 }}>
			<Paper sx={{ display: 'flex', flex: '1 1 0', overflow: 'auto' }}>
				<DataGrid
					getRowId={(user) => user.email}
					rows={users}
					columns={columns}
					sortModel={sortModel}
					onSortModelChange={(model) => setSortModel(model)}
				/>
			</Paper>
		</Box>
	)
}

export default ActiveUsersTable
