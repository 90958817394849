import { QueryClient } from 'react-query'
import { merge } from 'remeda'

import { optionIds } from '../../constants'
import { PlayerOptions, TeardownFn } from '../../types'
import { ApiAdapter } from '../api/baseApiAdapter'
import { createBaseQueryCacheMutation } from '../mutations/baseCreate'
import { playerKeys } from '../queries'
import { AppState } from '../store-types'

export const updateTagsMutation = (
	state: AppState,
	changes: string[]
): void => {
	const { apiAdapter, queryClient } = state
	createUpdateTagsOnApi(apiAdapter)(changes)
	createUpdateTagsOnQueryCache(queryClient)(changes)
}

export const createUpdateTagsOnApi =
	(apiAdapter: ApiAdapter) =>
	(changes: string[]): void => {
		apiAdapter.users.updateOptions('me', {
			[optionIds.RECENT_TAGS]: changes,
		})
	}

export const createUpdateTagsOnQueryCache =
	(queryClient: QueryClient) =>
	(changes: string[]): Promise<TeardownFn> => {
		const standardMutation = createBaseQueryCacheMutation(queryClient)
		const newChanges: Partial<PlayerOptions> = {
			[optionIds.RECENT_TAGS]: changes,
		}
		return standardMutation<Partial<PlayerOptions>>(
			playerKeys.options(),
			(prevOptions) =>
				prevOptions ? merge(prevOptions, newChanges) : newChanges
		)
	}
