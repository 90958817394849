import { Container, Typography } from '@mui/material'

import { usePendingSignups } from './pendingSignupsData'
import PendingSignupsTable from './PendingSignupsTable'

/* eslint-disable-next-line */
interface PendingSignupsPageProps {}

export function PendingSignupsPage(props: PendingSignupsPageProps) {
	const { data: signups = [], isLoading } = usePendingSignups()

	return (
		<Container
			maxWidth={'xl'}
			sx={{
				display: 'flex',
				flex: '1 1 0',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			<Typography variant={'h5'} sx={{ flex: '0 0 0', mt: 2 }}>
				{signups.length} Pending Signups
			</Typography>
			{isLoading ? (
				<div>Loading...</div>
			) : signups ? (
				<PendingSignupsTable signups={signups} />
			) : (
				<div>No signups found</div>
			)}
		</Container>
	)
}

export default PendingSignupsPage
