/*
 * Section factory functions for mutating state
 */

import { mergeDeepRight } from 'ramda'
import { QueryClient } from 'react-query'

import { Section, SectionId, StoreContext } from '../../../types'
import {
	ApiAdapter,
	SectionsResult,
	SectionsResultItem,
} from '../../api/baseApiAdapter'
import { sectionKeys } from '../../queries/sections'
import { createBaseQueryCacheMutation } from '../baseCreate'

type UpdateSectionFn = (
	userId: string,
	sectionId: SectionId,
	changes: Pick<Section, 'manualSortOrder' | 'sortType'>
) => void

export const updateSectionMutation =
	({
		apiAdapter,
		queryClient,
	}: Pick<StoreContext, 'apiAdapter' | 'queryClient'>): UpdateSectionFn =>
	(userId, sectionId, changes) => {
		createUpdateSectionOnApi(apiAdapter)(userId, sectionId, changes)
		createUpdateSectionOnQueryCache(queryClient)(userId, sectionId, changes)
	}

export const createUpdateSectionOnApi =
	(apiAdapter: ApiAdapter): UpdateSectionFn =>
	(userId, sectionId, changes) => {
		apiAdapter.users.updateSection(userId, sectionId, changes)
	}

export const createUpdateSectionOnQueryCache =
	(queryClient: QueryClient): UpdateSectionFn =>
	(userId, sectionId, changes) => {
		const standardMutation = createBaseQueryCacheMutation(queryClient)
		return standardMutation<SectionsResult>(
			sectionKeys.list(userId),
			(prev) => {
				if (!prev || !sectionId) {
					return undefined
				}

				return {
					...prev,
					byId: {
						...prev.byId,
						[sectionId]: mergeDeepRight(
							prev.byId[sectionId],
							changes
						) as SectionsResultItem,
					},
				}
			}
		)
	}
