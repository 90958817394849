import { focusManager, onlineManager } from 'react-query'
import { Subject } from 'rxjs'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { env } from '@tyto/utils'

import { baseApiAdapter } from './api'
import { createChatSlice } from './chat'
import createActions from './createActions'
import createPlayerSlice from './createPlayerSlice'
import { createQueryClient } from './createQueryClient'
import createVideoCallSlice from './createVideoCallSlice'
import { createProjectsSlice } from './projects'
import { createSettingsSlice } from './settings'
import { AppState, StoreError, StoreNotification } from './store-types'
import { createTaskEditSlice } from './task-edit'
import { createUserStatusSlice } from './user-status'
import { createUserViewSlice } from './user-view'

export const VERSION = global.window?.version || '0.0.0'

export const useStore = create<AppState>()(
	subscribeWithSelector(
		immer((...args) => {
			const [set] = args
			const queryClient = createQueryClient()

			// TODO: ideally all observables would be created here so that they
			// can be unsubscribed when the store is destroyed.

			// Add focusManager and onlineManager subscriptions. Normally this
			// is handled by QueryClientProvider. The QueryClient normally
			// subscribes on mount but because this needs to work outside the
			// realm of react, we need to setup the subscriptions manually.
			// TODO: when the store has a life cycle and has a way to be
			// initialised, these subscriptions should go into the initialisation.
			// TODO: there is no way to teardown subscriptions. Needs to be
			// looked at.
			focusManager.subscribe(function () {
				const mutationCache = queryClient.getMutationCache()
				const queryCache = queryClient.getQueryCache()
				if (focusManager.isFocused() && onlineManager.isOnline()) {
					mutationCache.onFocus()
					queryCache.onFocus()
				}
			})
			onlineManager.subscribe(function () {
				const mutationCache = queryClient.getMutationCache()
				const queryCache = queryClient.getQueryCache()
				if (focusManager.isFocused() && onlineManager.isOnline()) {
					mutationCache.onOnline()
					queryCache.onOnline()
				}
			})

			return {
				// TODO: create getters for these because in the app state they
				// trigger state updates when they change internally.
				apiAdapter: baseApiAdapter,
				queryClient,
				socket: null,

				errors: new Subject<StoreError>(),
				notifications: new Subject<StoreNotification>(),

				chat: createChatSlice(...args),
				player: createPlayerSlice(...args),
				projects: createProjectsSlice(...args),
				settings: createSettingsSlice(...args),
				taskEdit: createTaskEditSlice(...args),
				userStatus: createUserStatusSlice(...args),
				userView: createUserViewSlice(...args),
				videoCall: createVideoCallSlice(...args),

				version: VERSION,

				...createActions(...args),
				setVersion: (version: string) => {
					set((draft) => {
						draft.version = version
					})
				},
			}
		})
	)
)

if (env('NODE_ENV') === 'development') {
	import('simple-zustand-devtools')
		.then(({ mountStoreDevtool }) => {
			mountStoreDevtool('dna', useStore)
			return
		})
		.catch((err) => {
			console.log('Failed to load simple-zustand-devtools', err)
		})
}

export default useStore
