import { InfiniteData } from 'react-query'
import { z } from 'zod'

export type PaginatedList<T = unknown> = {
	count: number
	cursor: string
	hasMore: boolean
	items: T[]
	limit: number
}

// Example usage with a specific type, e.g., strings in items
// const stringPaginatedListSchema = PaginatedListSchema(z.string())
export const PaginatedListSchema = <T>(itemSchema: z.ZodType<T>) =>
	z.object({
		count: z.number().nonnegative().default(0),
		cursor: z.string().datetime().default(new Date().toISOString()),
		hasMore: z.boolean().default(false),
		items: z.array(itemSchema),
		limit: z.number().int().nonnegative().default(30),
	})

export const createEmptyPaginatedList = <T = unknown>(): PaginatedList<T> => ({
	count: 0,
	cursor: new Date().toISOString(),
	hasMore: false,
	items: [],
	limit: 30,
})

// Example usage with a specific type, e.g., strings in pages
// const stringInifiniteListSchema = InifiniteListSchema(z.string())
export const InifiniteListSchema = <T>(pageSchema: z.ZodType<T>) =>
	z.object({
		pages: z.array(pageSchema),
		pageParams: z.array(z.unknown()),
	})

export const createEmptyInfiniteList = <T = unknown>(): InfiniteData<
	PaginatedList<T>
> => ({
	pages: [createEmptyPaginatedList()],
	pageParams: [],
})
