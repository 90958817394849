import { useLayoutEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import { useStore } from '@tyto/dna/store'
import { ConfirmProvider } from '@tyto/web-ui/confirm'

import { ActiveUsersPage } from './activeUsers'
import AppLayout from './AppLayout'
import Header from './components/Header'
import { DebugPage } from './debug'
import { PendingSignupsPage } from './pendingSignups'

export function App() {
	useLayoutEffect(() => {
		return useStore.getState().player.init()
	}, [])

	return (
		<ConfirmProvider>
			<AppLayout>
				<Header />
				<Routes>
					<Route path="/" element={<ActiveUsersPage />} />
					<Route
						path="/pending-signups"
						element={<PendingSignupsPage />}
					/>
					{/* <Route
					path="/page-2"
					element={
						<div>
						<Link to="/">
								Click here to go back to root page.
							</Link>
							</div>
						}
				/> */}
					<Route path="debug" element={<DebugPage />} />
				</Routes>
			</AppLayout>
		</ConfirmProvider>
	)
}

export default App
